import React, { useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import MarkerWithInfoBox from './MarkerWithInfoBox';
import styles from './styles';

const GOOGLE_MAPS_JS_API_KEY = process.env.REACT_APP_GMAPS_JS_API_KEY;

const containerStyle = {
  width: '100%',
  minHeight: '35em',
  marginTop: '1.5em',
  overflowAnchor: 'none',
};

const DEFAULT_CENTER = { lat: 19.076, lng: 72.8777 };

function MapWithMarkers({
  mapCenter = DEFAULT_CENTER,
  markers = [],
  zoom = 11,
  userMarker,
}) {
  const [selectedPopup, setSelectedPopup] = useState('');
  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_JS_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        options={{
          styles: styles,
          streetViewControl: false,
          mapTypeControl: false,
        }}
        zoom={zoom}
      >
        {userMarker && (
          <MarkerWithInfoBox
            userMarker
            coordinates={{ lat: userMarker.lat, lng: userMarker.lng }}
            label={userMarker.label}
            center={{ lat: userMarker.lat, lng: userMarker.lng }}
            city={userMarker.city}
            setSelectedPopup={setSelectedPopup}
            selectedPopup={selectedPopup}
          />
        )}
        {markers &&
          markers.length > 0 &&
          markers.map(
            ({ lat, lng, label, city, titleImage, googlePlaceId }) => (
              <MarkerWithInfoBox
                coordinates={{ lat, lng }}
                label={label}
                center={mapCenter}
                city={city}
                titleImage={titleImage}
                setSelectedPopup={setSelectedPopup}
                selectedPopup={selectedPopup}
                googlePlaceId={googlePlaceId}
              />
            )
          )}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(MapWithMarkers);
