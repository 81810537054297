import React from "react";
import "./Snippet.scss";
import cleaning from "../../assets/images/cleaning.svg";
import coffee from "../../assets/images/coffee.svg";
import internet from "../../assets/images/internet.svg";
import printing from "../../assets/images/printing.svg";
import parking from "../../assets/images/parking.svg";

const Amenities = () => {
  return (
    <div className="amenities">
        <div className="ray-text--h2">Included Amenities</div>
      <div className="ray-grid">
        <div className="amenities-type ray-grid__cell--span-6">
          <div>
            <img src={printing} alt="" />
          </div>
          <div>
            <span className="ray-text--h5">Printing </span>
            <div className="ray-text--body-small">
            Every floor has its own space stocked with a business-class printer, office supplies and a paper shredder. (additionally paid)
            </div>
          </div>
        </div>
        <div className="amenities-type ray-grid__cell--span-6">
          <div>
            <img src={cleaning} alt="" />
          </div>
          <div>
            <span className="ray-text--h5">Enhanced cleaning</span>
            <div className="ray-text--body-small">
            In addition to WeWork's normal cleaning routine, we're cleaning ‘high-touch' surfaces frequently to mitigate the spread of COVID-19.
            </div>
          </div>
        </div>
        <div className="amenities-type ray-grid__cell--span-6">
          <div>
            <img src={coffee} alt="" />
          </div>
          <div>
            <span className="ray-text--h5">Coffee </span>
            <div className="ray-text--body-small">
            There's nothing better to start your day with than a cup of fresh micro-brewed coffee.
            </div>
          </div>
        </div>
        <div className="amenities-type ray-grid__cell--span-6">
          <div>
            <img src={internet} alt="" />
          </div>
          <div>
            <span className="ray-text--h5">High-speed Wi-Fi </span>
            <div className="ray-text--body-small">
            Hook yourself up to the secure Wi-Fi, and get going with your business.
            </div>
          </div>
        </div>
        <div className="amenities-type ray-grid__cell--span-6">
          <div>
            <img src={parking} alt="" />
          </div>
          <div>
            <span className="ray-text--h5">Onsite Parking</span>
            <div className="ray-text--body-small">
            To make your commute easier, we've onsite parking. No need to search for a parking spot any more. (additionally paid)
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Amenities;