import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useAuthContext } from "../../components/context/AuthContext";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  successToast: {
    backgroundColor: "#12872A",
    color: "#FFFFFF",
  },
  errorToast: {
    backgroundColor: "#FF0000",
    color: "#FFFFFF",
  },
  infoToast: {
    backgroundColor: "#0000FF",
    color: "#FFFFFF",
  },
  warnToast: {
    backgroundColor: "#FFD500",
    color: "#000000",
  },
  weWorkToast: {
    backgroundColor: "#0000FF",
    color: "#fff",
  },
}));

function ToastAlert() {
  const { toastInfo, showToast } = useAuthContext();
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    showToast(false, toastInfo.type, "");
  };
  const handleToastType = (type) => {
    switch (type) {
      case "success":
        return classes.weWorkToast;
      case "error":
        return classes.errorToast;
      case "info":
        return classes.infoToast;
      case "warning":
        return classes.warnToast;
      default:
        return classes.successToast;
    }
  };

  return (
    <Snackbar
      open={toastInfo.show}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      message={toastInfo.message}
    >
      <Alert
        className={handleToastType(toastInfo.type)}
        onClose={handleClose}
        severity={toastInfo.type}
        icon={false}
      >
        {toastInfo.message}
      </Alert>
    </Snackbar>
  );
}

export default ToastAlert;
