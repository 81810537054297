import React from "react";
import sanitize from "../../assets/images/sanitize.svg";
import social from "../../assets/images/social.svg";
import mask from "../../assets/images/mask.svg";
import stringent from "../../assets/images/stringent.svg";
import "./Snippet.scss";
import { useRouteMatch } from "react-router-dom";

const Safety = () => {

  let match = useRouteMatch("/:city");

  return (
    <>
      <div className="safe">
        <div className={!match ? "ray-page-container" : ""}>
          <h2 className="ray-text--h2 ">Help us keep you safe:</h2>
          <div className="ray-grid safety-section">
            <div className="img-col ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone safety-block">
              <img src={social} alt="" /> <br />
              <span className="ray-text--h4">Social Distancing</span>
            </div>
            <div className="img-col ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone safety-block">
              <img src={sanitize} alt="" /> <br />
              <span className="ray-text--h4">Frequent Sanitisation</span>
            </div>
            <div className="img-col ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone safety-block">
              <img src={stringent} alt="" /> <br />
              <span className="ray-text--h4">Temperature Screening</span>
            </div>
            <div className="img-col ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone safety-block">
              <img src={mask} alt="" /> <br />
              <span className="ray-text--h4">
              Mandatory masks within the buildings
              </span>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};

export default Safety;
