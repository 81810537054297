import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios";
import Spinner from "../Spinner";
import BookingItem from "./BookingItem";

function BookingsSection({ setBooking, changeSection }) {
  const classes = useStyles();
  const [bookings, setBookings] = useState([]);
  const [loader, setLoader] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setLoader(true);
    const fetchBookings = async () => {
      const token = await getAccessTokenSilently();
      try {
        const response = await axios.get(`customers/fetch-bookings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const bookingsData = response.data.bookings || [];
        setBookings([...bookingsData]);
        setLoader(false);
      } catch (error) {
        console.error(error);
        setBookings([]);
        setLoader(false);
      }
    };
    fetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div class="ray-grid">
      <div
        className={`ray-grid__cell--span-12-desktop ray-grid__cell--span-12-tablet ray-grid__cell--span-12-phone ${
          classes.headingMargin
        }`}
      >
        <h2 className="ray-text--h2">Your Bookings</h2>
      </div>
      {loader && <Spinner />}
      {!loader && bookings.length === 0 && (
        <div className="ray-grid__cell--span-4">
          <h5 className="ray-text--h5">No Bookings Available</h5>
        </div>
      )}
      {!loader &&
        bookings.length > 0 &&
        bookings.map((item, index) => (
          <div
            key={index.toString()}
            className={`ray-grid__cell--span-12-phone ray-grid__cell--span-12-tablet ray-grid__cell--span-10-desktop ${
              classes.bookingHover
            }`}
            onClick={() => {
              setBooking(item);
              changeSection("BOOKING_DETAIL");
            }}
          >
            <BookingItem bookingInfo={item} />
          </div>
        ))}
    </div>
  );
}

export default BookingsSection;

const useStyles = makeStyles((theme) => ({
  headingMargin: {
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: "1em",
    },
  },
  bookingHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
