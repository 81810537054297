import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "src/axios";
import { useAuthContext } from "src/components/context/AuthContext";
import { recordAnalyticsEvent } from "src/utils/Analytics";

const Note = styled.div`
  background-color: #f3f6f9;
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 1em 1em;
  border-radius: 5px;
  margin-bottom: 2em;
  span {
    margin-bottom: 0;
  }
  svg {
    font-size: 1.5em;
    margin-right: 0.5em;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 1em 1em;
    svg {
      font-size: 4em;
      margin-right: 0.2em;
    }
  }
`;

function CreateAccountStep() {
  const classes = useStyles();
  const [formError, setFormError] = useState(null);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { getAccessTokenSilently, user: auth0UserInfo } = useAuth0();
  const {
    showToast,
    setShowSignupModal,
    setUserInfo,
    analyticsOperations,
  } = useAuthContext();

  const onSubmit = (data) => {
    setLoader(true);
    signUpUser(data);
  };

  const signUpUser = async (data) => {
    setLoader(true);
    try {
      const token = await getAccessTokenSilently();
      await axios.post(`/customers/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Analytics event for Signing up
      recordAnalyticsEvent("Created Profile", {
        "Email Address": auth0UserInfo.email,
        "Full Name": data.name,
        "Phone Number": data.phone,
        "GSTIN Number": data.gstin,
      });
      // User Info override to avoid extra API call
      setUserInfo({
        ...data,
        id: auth0UserInfo.email,
        email: auth0UserInfo.email,
        auth0Id: auth0UserInfo["https://wework.com/user_uuid"],
      });

      // Analytics ops when signing in
      analyticsOperations({ email: auth0UserInfo.email, name: data.name });

      showToast(true, "success", "Information saved, Thank you!");
      setFormError(null);
      setLoader(false);
      setShowSignupModal(false);
    } catch (error) {
      console.error(error);
      setFormError({ message: "Error occurred while saving details" });
      setLoader(false);
    }
  };

  return (
    <Grid container alignItems="center" justify="center" spacing={2}>
      <Grid item md={12} xs={12}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.sidebarSignUp}
        >
          <div className={`ray-grid ${classes.gridJustify}`}>
            <div className="ray-grid__cell--span-full">
              <h3>Please complete your profile to proceed</h3>
            </div>
            {formError && (
              <div
                className={`ray-grid__cell--span-full ${classes.helpTextMb}`}
              >
                <p className={classes.fieldError}>
                  {formError && formError.message
                    ? formError.message
                    : "Error occurred while saving details"}
                </p>
              </div>
            )}

            <div className="ray-grid__cell--span-full">
              <Note>
                <span className="ray-text--body">
                  This is a one-time step, you won’t be asked for this again.
                </span>
              </Note>
            </div>
            <div
              className={`ray-grid__cell--span-full ${classes.textFieldSpace}`}
            >
              <div className="ray-text-field">
                <input
                  className="ray-text-field__input"
                  id="user-fname"
                  type="text"
                  placeholder="Sam Jackson"
                  name="name"
                  ref={register({ required: true })}
                />
                <label className="ray-text-field__label" htmlFor="user-fname">
                  Full Name*
                </label>
              </div>
              {errors.name && (
                <small className={classes.fieldError}>
                  This field is required
                </small>
              )}
            </div>
            <div
              className={`ray-grid__cell--span-full ${classes.textFieldSpace}`}
            >
              <div className="ray-text-field">
                <input
                  className="ray-text-field__input"
                  id="phone-user"
                  type="tel"
                  placeholder="9001002001"
                  name="phone"
                  ref={register({
                    minLength: {
                      value: 10,
                      message: "Enter a valid 10 digit Phone",
                    },
                    maxLength: {
                      value: 10,
                      message: "Enter a valid 10 digit Phone",
                    },
                    pattern: {
                      value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                      message: "Enter a Valid Phone",
                    },
                  })}
                />
                <label className="ray-text-field__label" htmlFor="phone-user">
                  Phone Number
                </label>
              </div>
              {errors.phone && (
                <small className={classes.fieldError}>
                  {errors.phone.message}
                </small>
              )}
            </div>
            <div
              className={`ray-grid__cell--span-full ${classes.textFieldSpace}`}
            >
              <div className="ray-text-field">
                <input
                  className="ray-text-field__input"
                  id="gstin-user"
                  type="text"
                  placeholder="22AAAAA0000A1Z5"
                  name="gstin"
                  ref={register}
                />
                <label className="ray-text-field__label" htmlFor="gstin-user">
                  GSTIN
                </label>
              </div>
            </div>
            <div className="ray-grid__cell--span-full">
              <div className="ray-text--body">
                By clicking "Continue", you agree to{" "}
                <a
                  href="https://wework.co.in/india/legal/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WeWork's Terms Of Service
                </a>
              </div>
            </div>
          </div>
          <div className={`ray-grid ${classes.gridJustifyRight}`}>
            <button
              className={`ray-button ray-button--primary ${
                classes.registerBtn
              }`}
              type="submit"
              disabled={loader}
            >
              {loader ? (
                <CircularProgress size={15} className={classes.spinner} />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default CreateAccountStep;

const useStyles = makeStyles((theme) => ({
  gridJustify: {
    justifyContent: "left",
  },
  gridJustifyRight: {
    display: "grid",
    justifyContent: "right",
  },
  spinner: {
    color: "#fff",
  },
  signUpContainer: {
    height: "100%",
    width: "640px",
    padding: "0.5em",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em",
      width: "100%",
    },
  },
  sidebarSignUp: {
    height: "100%",
    padding: "0.5em",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em",
      width: "100%",
    },
  },
  headerMb0: {
    marginBottom: 0,
  },
  textMb: {
    marginBottom: "1em",
  },
  textFieldSpace: {
    marginBottom: "1em",
    width: "100%",
  },
  registerBtn: {
    marginTop: "0em",
    marginBottom: "0em",
    marginRight: "1em",
  },
  helpTextMb: {
    marginBottom: "0.5em",
  },
  linkSpan: {
    color: "#0000FF",
    cursor: "pointer",
  },
  fieldError: {
    color: "#CC0000",
  },
}));
