import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "src/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import { CartContext } from "src/components/context/CartContext";
import { useAuthContext } from "src/components/context/AuthContext";
import { useGoogleAnalyticsCtx } from "src/components/context/GoogleAnalyticsCtx";
import CheckoutImage from "src/assets/images/invoice_final2.jpg";
import { addHours, format as DateFormat } from "date-fns";
import { stringifyPrice } from "src/utils/Functions";
import { BiUser } from "react-icons/bi";
import { RiMotorbikeLine, RiCarLine } from "react-icons/ri";
import { recordAnalyticsEvent } from "src/utils/Analytics";
import { AiFillInfoCircle } from "react-icons/ai";

const CONFERENCE_ROOM_HANDLE = "conference-room-seat";

function InvoiceStep({
  building,
  changeStepper,
  cityName,
  buildingAnalyticsBody,
}) {
  const classes = useStyles();
  const { cartState } = useContext(CartContext);

  const { getAccessTokenSilently } = useAuth0();

  const [useVoCredits, setUseVoCredits] = useState(false);

  const {
    userInfo,
    showToast,
    creditSystemActive,
    toggleCreditSystem,
  } = useAuthContext();
  const { sendDataToGoogleAnalytics } = useGoogleAnalyticsCtx();
  const [loader, setLoader] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (creditSystemActive) setUseVoCredits(true);
  }, [creditSystemActive]);

  useEffect(() => {
    if (useVoCredits && userInfo && userInfo.creditAmount) {
      const netPrice =
        cartState.invoiceScreenDetails.totalPrice - userInfo.creditAmount;
      setTotalPrice(netPrice > 0 ? netPrice : 0);
      toggleCreditSystem(true);
    } else {
      setTotalPrice(cartState.invoiceScreenDetails.totalPrice);
      toggleCreditSystem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useVoCredits]);

  const triggerCheckout = async () => {
    setLoader(true);
    try {
      const body = {
        customer_email: userInfo.email,
        notes: "NA",
        buildingId: building.id,
        line_items: [],
        gstin_note: `GSTIN: ${userInfo.gstin}`,
        draft_notes: {
          isDashboard: false,
        },
        useVoCredits,
      };
      if (useVoCredits) body.voDocId = userInfo.voDocId;
      if (
        cartState.conferences.length > 0 &&
        cartState.invoiceScreenDetails.prevStep === "CONFERENCE_ROOM_STEP"
      ) {
        body.meeting_ids = cartState.conferences.map(
          (item) => `${item.reservationId}_${item.conferenceId}`
        );
        cartState.conferences.forEach(
          ({ noOfHrs, memberCount, oldVariantId, variantId }) => {
            body.line_items.push({
              variantId: useVoCredits ? variantId : oldVariantId,
              quantity: noOfHrs * memberCount,
            });
          }
        );
        body.draft_notes.conference_rooms_info = cartState.conferences.map(
          (item) => ({
            reservation_id: item.reservationId,
            handle: CONFERENCE_ROOM_HANDLE,
            building_title: building.name,
            booking_date: item.bookingDate,
            variant_title: item.variantId,
            booking_start_time: item.startTime,
            member_count: item.memberCount,
            no_of_hrs: item.noOfHrs,
            conference_room_id: item.conferenceId,
            city_name: buildingAnalyticsBody["City Name"],
            micromarket_name: buildingAnalyticsBody["Micromarket Name"],
            building_id: buildingAnalyticsBody["Building ID"],
            building_zone: buildingAnalyticsBody["Zone"],
          })
        );
      }
      if (cartState.teamMembers.length > 0) {
        const passesInfo = [];
        Object.keys(cartState.items).forEach((item) => {
          const itemObj = cartState.items[item];
          body.line_items.push({
            variantId: useVoCredits ? itemObj.id : itemObj.oldId,
            quantity: cartState.globalQuantity,
          });
          const teamMemberParkings = cartState.teamMembers.filter(
            ({ hasParking }) => hasParking
          );
          if (teamMemberParkings.length > 0) {
            const carParkings = teamMemberParkings.filter(
              ({ parkingType }) => parkingType === "Car"
            );
            const bikeParkings = teamMemberParkings.filter(
              ({ parkingType }) => parkingType === "Bike"
            );
            if (carParkings.length > 0) {
              const carVariantId = useVoCredits
                ? carParkings[0].variantId
                : carParkings[0].oldVariantId;
              body.line_items.push({
                variantId: carVariantId,
                quantity: carParkings.length,
              });
            }
            if (bikeParkings.length > 0) {
              const bikeVariantId = useVoCredits
                ? bikeParkings[0].variantId
                : bikeParkings[0].oldVariantId;
              body.line_items.push({
                variantId: bikeVariantId,
                quantity: bikeParkings.length,
              });
            }
          }
          passesInfo.push({
            id: itemObj.id,
            price: itemObj.price,
            date: itemObj.date.toISOString(),
          });
        });
        body.draft_notes.day_pass_info = {
          passes: passesInfo,
          order_creator: { email: userInfo.email, name: userInfo.name },
          team_members: JSON.stringify(cartState.teamMembers),
          city_name: buildingAnalyticsBody["City Name"],
          micromarket_name: buildingAnalyticsBody["Micromarket Name"],
          building_zone: buildingAnalyticsBody["Zone"],
        };
      }
      const token = await getAccessTokenSilently();
      const response = await axios.post(`/shop/create-user-draft-order`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sendDataToGoogleAnalytics("event", "Confirm and pay button", "click");
      recordAnalyticsEvent("Initiate Checkout");
      const URL = response.data.invoiceURL;
      window.location.href = URL;
    } catch (error) {
      console.log(error);
      showToast(
        true,
        "error",
        "Error occurred while creating an order for you"
      );
      setLoader(false);
    }
  };

  return (
    <Grid container alignItems="flex-start" justify="center" spacing={2}>
      <Grid item xs={12}>
        <h3 className="ray-text--h3">{`Day Pass at ${building.name}`}</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item xs={6}>
              <div
                style={{ backgroundImage: `url(${CheckoutImage})` }}
                className={classes.bannerImg}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1} className={classes.infoSection}>
              <Grid className={classes.greyBkg} item xs={12}>
                <div className="ray-text--h3">Price Breakup</div>
              </Grid>

              <Grid item xs={12}>
                <h3
                  style={{ marginBottom: 0 }}
                  className={`ray-text--body-large`}
                >
                  Day Passes
                </h3>
              </Grid>
              {cartState.items &&
                Object.keys(cartState.items).length > 0 &&
                Object.keys(cartState.items).map((item) => {
                  const itemInfo = cartState.items[item];
                  return (
                    <>
                      <Grid item xs={12}>
                        <div className={classes.itemWrapper}>
                          <span
                            style={{ marginBottom: 0 }}
                            className="ray-text--body-small"
                          >
                            {DateFormat(
                              new Date(itemInfo.date.toISOString()),
                              "do MMM Y"
                            )}
                          </span>
                          {/* <h3 className="ray-text--h3">
                            {stringifyPrice(
                              itemInfo.price * cartState.teamMembers.length
                            )}
                          </h3> */}
                          {/* {cartState.conferences &&
                            cartState.conferences.length > 0 && (
                              <div className="ray-text--body">
                                {stringifyPrice(
                                  Object.keys(cartState.items).length *
                                    cartState.globalQuantity *
                                    500
                                )}
                              </div>
                            )} */}
                        </div>
                      </Grid>
                      <Grid className={classes.mbMobile} item xs={12}>
                        {cartState.teamMembers &&
                          cartState.teamMembers.length > 0 &&
                          cartState.teamMembers.map((member) => (
                            <div className={classes.itemWrapper}>
                              <div className="ray-text--body-small member-name">
                                {member.name}
                              </div>
                              {member.hasParking &&
                                (member.parkingType === "Car" ? (
                                  <RiCarLine />
                                ) : (
                                  <RiMotorbikeLine />
                                ))}
                              <div className="ray-text--body-small member-price">
                                {member.price
                                  ? `${stringifyPrice(
                                      itemInfo.price
                                    )} + ${stringifyPrice(
                                      Number(member.price)
                                    )}`
                                  : `${stringifyPrice(itemInfo.price)}`}
                              </div>
                            </div>
                          ))}
                      </Grid>
                    </>
                  );
                })}

              {cartState &&
                cartState.invoiceScreenDetails.prevStep ===
                  "CONFERENCE_ROOM_STEP" &&
                cartState.conferences &&
                cartState.conferences.length > 0 &&
                cartState.conferences[0].reservationId && (
                  <Grid item xs={12}>
                    <span
                      style={{ marginBottom: 0 }}
                      className={`ray-text--body-large`}
                    >
                      Conference Room Sessions
                    </span>
                  </Grid>
                )}

              <Grid
                className={`${classes.mbMobile} ${classes.borderBottomBlack}`}
                item
                xs={12}
              >
                {cartState &&
                  cartState.invoiceScreenDetails.prevStep ===
                    "CONFERENCE_ROOM_STEP" &&
                  cartState.conferences &&
                  cartState.conferences.length > 0 &&
                  cartState.conferences[0].reservationId &&
                  cartState.conferences.map((conf, index) => (
                    <>
                      <div
                        style={{ marginBottom: 0 }}
                        className="ray-text--body-small"
                      >{`${DateFormat(conf.bookingDate, `d MMM Y`)}`}</div>
                      <div
                        className={`${classes.itemWrapper} ${
                          classes.confRoomItem
                        }`}
                      >
                        <div
                          className={`ray-text--body-small ${classes.duration}`}
                        >{`${DateFormat(conf.startTime, "h:mm a") +
                          " - " +
                          DateFormat(
                            addHours(conf.startTime, conf.noOfHrs),
                            "h:mm a"
                          )}`}</div>
                        <div className={classes.memberCountWrapper}>
                          <BiUser />
                          <div className="ray-text--body-small">
                            {conf.memberCount}
                          </div>
                        </div>
                        <div className="ray-text--body-small">
                          {stringifyPrice(conf.price)}
                        </div>
                      </div>
                    </>
                  ))}
              </Grid>
              {userInfo && userInfo.creditAmount ? (
                <>
                  <p
                    style={{ marginBottom: 0, marginTop: "1em" }}
                    className="ray-text--body-small"
                  >
                    You have{" "}
                    <strong>{stringifyPrice(userInfo.creditAmount)}</strong> in
                    credits from your <strong>{userInfo.companyName}</strong>{" "}
                    virtual office account
                  </p>
                  <div className="ray-checkbox">
                    <input
                      id="checkbox--credit-amt"
                      name="checkbox-button-story"
                      type="checkbox"
                      className="ray-checkbox__input"
                      checked={useVoCredits}
                      onChange={() => setUseVoCredits(!useVoCredits)}
                    />
                    <label
                      className="ray-checkbox__label"
                      htmlFor="checkbox--credit-amt"
                    >
                      Use Credits?
                    </label>
                  </div>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={12}>
                <h3 className={`ray-text--h3 ${classes.totalPriceText}`}>
                  {`Total: ${stringifyPrice(totalPrice)}`}
                </h3>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.noParkingBanner}>
          <AiFillInfoCircle />
          <span className="ray-text--body-small">
            Order once placed cannot be cancelled
          </span>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justify="flex-end"
        spacing={2}
        alignItems="center"
      >
        <Grid item md={3} xs={12} className={classes.backBtn}>
          <button
            className={`ray-button ray-button--tertiary ${
              classes.fullWidthBtn
            }`}
            onClick={() =>
              changeStepper(cartState.invoiceScreenDetails.prevStep)
            }
          >
            Back
          </button>
        </Grid>
        <Grid item md={4} xs={12}>
          <button
            className={`ray-button ray-button--primary ${classes.fullWidthBtn}`}
            onClick={triggerCheckout}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={20} className={classes.whiteColor} />
            ) : (
              `Confirm & Pay ${stringifyPrice(totalPrice)}`
            )}
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default InvoiceStep;

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& .ray-text--body-small": {
      marginBottom: 0,
    },
    "& .member-name": {
      minWidth: "60%",
    },
    "& svg": {
      minWidth: "10%",
      textAlign: "center",
    },
    "& .member-price": {
      minWidth: "30%",
      textAlign: "right",
    },
  },
  confRoomItem: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mbMobile: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  backBtn: {
    order: 0,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  borderBottomBlack: {
    borderBottom: "1px solid",
  },
  fullWidthBtn: {
    width: "100%",
  },
  infoSection: {
    maxHeight: "30em",
    backgroundColor: "#F3F6F9",
    padding: "1em",
    overflowY: "scroll",
  },
  greyBkg: {
    backgroundColor: "#F3F6F9",
    "& div": {
      borderBottom: "1px solid",
    },
  },
  duration: {
    minWidth: "40%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50%",
    },
  },
  totalPriceText: {
    textAlign: "right",
    marginBottom: 0,
    // borderTop: "1px solid",
  },
  memberCountWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  bannerImg: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "35em",
    width: "100%",
  },
  whiteColor: {
    color: "#fff",
  },
  noParkingBanner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0.3em",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    "& span": {
      marginBottom: 0,
    },
    "& svg": {
      fontSize: "1.5em",
      marginRight: "0.5em",
    },
  },
}));
