import React from 'react';
import styled from 'styled-components';
import card from '../../assets/images/gift__22.png';
import { recordAnalyticsEvent } from 'src/utils/Analytics';

const StyledWrapper = styled.div`
  .gift-card-snippet {
    margin-top: 20px;
    padding: 20px;
    img {
      width: 100%;
      padding-top: 20px !important;
    }
  }
`;

const GiftCardSnippet = () => {
  return (
    <a
      style={{ borderRadius: '10px' }}
      href="/shop/gift-card"
      onClick={() => recordAnalyticsEvent('Homepage Gifting Clicked')}
    >
      <StyledWrapper>
        <div className="ray-page-container gift-card-snippet ">
          <img
            src={card}
            alt="gift_card_image"
            className="gift-card-snippet-img"
          />
        </div>
      </StyledWrapper>
    </a>
  );
};

export default GiftCardSnippet;
