import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MicroMarketCheckbox from "./MicroMarketCheckbox";
import Sidebar from "../../../reuseComponents/Sidebar";
import { MdKeyboardBackspace } from "react-icons/md";

function SelectAreaSidebar({
  sidebarState,
  toggleSidebar,
  microMarketMap,
  removeItem,
  addItem,
  selectedMicroMarkets,
  resetMarkets,
}) {
  const classes = useStyles();
  return (
    <Sidebar isOpen={sidebarState} onClose={toggleSidebar}>
      <div className={classes.root}>
        <div className="ray-grid">
          <div className="ray-grid__cell--span-full sidebar__filter_heading">
            <MdKeyboardBackspace onClick={() => toggleSidebar(false)} />
            <span className="ray-text--h2">Filter Locations</span>
          </div>
          {Object.keys(microMarketMap).map((market) => (
            <div className="ray-grid__cell--span-full">
              <MicroMarketCheckbox
                info={market}
                length={microMarketMap[market].buildings.length}
                removeItem={removeItem}
                addItem={addItem}
                check={selectedMicroMarkets.every(
                  (val) => !microMarketMap[market].buildings.includes(val)
                )}
              />
            </div>
          ))}
        </div>
        <div className={`ray-grid ${classes.actionRow}`}>
          <div className="ray-grid__cell--span-2-phone ray-grid__cell--span-3-tablet">
            <button
              onClick={resetMarkets}
              className="ray-button ray-button--tertiary"
            >
              Reset
            </button>
          </div>
          <div className="ray-grid__cell--span-2-phone ray-grid__cell--span-5-tablet">
            <button
              onClick={() => toggleSidebar(false)}
              className="ray-button ray-button--primary"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default SelectAreaSidebar;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  actionRow: {
    width: "100%",
    "& button": {
      width: "100%",
    },
  },
  nearMeBtnRow: {
    marginTop: "1em",
    marginBottom: "1em",
    "& button": {
      width: "100%",
    },
  },
}));
