import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import { useAuthContext } from "src/components/context/AuthContext";
import Spinner from "../Spinner";
import { useGoogleAnalyticsCtx } from "src/components/context/GoogleAnalyticsCtx";
import MultiStageCheckout from "../MultiStageCheckout/MultiStageCheckout.jsx";
import DatePicker from "../BuildingDetail/DatePicker.jsx";
import GuidelinesStep from "./Stepper/GuidelinesStep";
import LoginStep from "../../reuseComponents/AuthComponents/LoginStep";
import AddMemberStep from "./Stepper/AddMemberStep";
import ConferenceRoomStep from "./Stepper/ConferenceRoomStep";
import InvoiceStep from "./Stepper/InvoiceStep";
import Amenities from "../Snippets/Amenities";
import MyCarousel from "./Carousel";
import { stringifyPrice } from "src/utils/Functions";
import ReactPixel from "react-facebook-pixel";
import "./Buildings.scss";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Safety from "../Snippets/Safety";
import { capitalizeText } from "src/utils/Functions";
import { recordAnalyticsEvent } from "src/utils/Analytics";
import { GET_BUILDING_DETAILS } from "./GetBuildingDetails";
import axios from "src/axios";
import PageNotFound from "src/reuseComponents/PageNotFound";
import {
  removeRedirectUrl,
  storeRedirectUrl,
} from "src/utils/temp-storage/redirectUrl";
import {
  getStepperVisibility,
  storeStepperVisibility,
  removeStepperVisibility,
} from "src/utils/temp-storage/showStepper";

const BOOKING_PRICE = 1000;
const BOOKING_DISCOUNTED_PRICE = 500;

const BuildingDetail = () => {
  // Session Storage Variable for Showing Stepper Automatically
  const SHOW_STEPPER = getStepperVisibility();

  const { handle, city } = useParams();
  const match = useRouteMatch("/:city/:handle");

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { userInfo } = useAuthContext();

  const [building, setBuilding] = useState({});
  const [buildingLoader, setBuildingLoader] = useState(false);
  const [buildingAnalyticsBody, setBuildingAnalyticsBody] = useState({});
  const [error, setError] = useState(null);
  const [variants, setVariants] = useState([]);

  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);

  const { sendDataToGoogleAnalytics } = useGoogleAnalyticsCtx();

  const [checkoutStepper, setCheckoutStepper] = useState("DATE_PICKER");

  const { loading, data: gqlData, error: gqlError } = useQuery(
    GET_BUILDING_DETAILS,
    {
      variables: {
        handle,
      },
      onCompleted: () => {
        if (
          gqlData &&
          gqlData.productByHandle &&
          gqlData.productByHandle.variants &&
          gqlData.productByHandle.variants.edges.length > 0
        ) {
          setVariants(gqlData.productByHandle.variants.edges);
        }
      },
    }
  );

  useEffect(() => {
    if (userInfo && userInfo.auth0Id && SHOW_STEPPER === "true") {
      setCheckoutModalOpen(true);
      // removal of session storage variables
      removeStepperVisibility();
      removeRedirectUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    ReactPixel.pageView();
    fetchBuildingInfoFromFirebase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (building && Object.keys(building).length > 0) {
      ReactPixel.track("ViewContent", {
        content_ids: building.id,
        content_name: building.name,
        content_type: "product",
        currency: "INR",
        value: building.price,
      });
      const analyticsBody = {
        "Building ID": building.id,
        "Building Page": window.location.href,
        "Building Price": BOOKING_DISCOUNTED_PRICE,
        "Building Discount": "YES",
        "Building Discount Amount": BOOKING_PRICE - BOOKING_DISCOUNTED_PRICE,
        "Building Name": building.name,
        "Micromarket Name": building.micromarket ? building.micromarket : "",
        "City Name": capitalizeText(building.city),
        Zone: building.region ? building.region : "",
      };
      setBuildingAnalyticsBody(analyticsBody);
      recordAnalyticsEvent("Viewed Building", analyticsBody);
      if (building.show_covid_guidelines)
        setCheckoutStepper("SHOW_COVID_GUIDELINES");
    }
  }, [building]);

  const fetchBuildingInfoFromFirebase = async () => {
    setBuildingLoader(true);
    try {
      const { data } = await axios.get("/buildings/fetch-building-info", {
        params: { handle },
      });
      const buildingData = data.buildingInfo;
      setBuilding({
        ...buildingData,
        parkingDisabled: buildingData.parking_disabled,
        conferenceRoomDisabled: buildingData.conference_rooms_disabled,
      });
      setBuildingLoader(false);
    } catch (error) {
      console.error("error", error);
      const tempError =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong";
      setError(tempError);
      setBuildingLoader(false);
    }
  };

  const toggleCheckoutModal = () => setCheckoutModalOpen(!checkoutModalOpen);

  const handleCheckout = () => {
    if (isAuthenticated) {
      toggleCheckoutModal();
    } else {
      storeStepperVisibility(true);
      storeRedirectUrl(window.location.pathname);
      loginWithRedirect();
    }

    ReactPixel.track("AddToCart", {
      content_ids: building.id,
      content_name: building.name,
      content_type: "product",
      currency: "INR",
      value: building.price,
    });
    sendDataToGoogleAnalytics("event", "Buy a day pass Button", "click");
    recordAnalyticsEvent("Clicked PDP Day Pass", {
      "Building ID": building.id,
      "Building Price": BOOKING_DISCOUNTED_PRICE,
      "Building Discount": "YES",
      "Building Name": building.name,
      "Buy a Day Pass": "YES",
    });
  };

  if (loading || buildingLoader) return <Spinner />;

  if (error || gqlError) return <PageNotFound />;

  return (
    <div className="buildings">
      <div className="ray-page-container carousel-container">
        <div className="breadcrumbs">
          <Link className="breadcrumb-links" to="/">
            <span>HOME</span>
          </Link>
          <span className="breadcrumb-arrow">ᐳ</span>
          <Link className="breadcrumb-links" to={`/${city}`}>
            <span style={{ textTransform: "uppercase" }}>{city}</span>
          </Link>
          <span className="breadcrumb-arrow">ᐳ</span>
          <span
            className={match ? "revert-color" : ""}
            style={{ textTransform: "uppercase" }}
          >
            {building && building.name ? building.name : "--"}
          </span>
        </div>
        <div className="location-title">
          <span className="ray-text--h1">
            {building && building.name ? building.name : ""}
          </span>
          {building && building.address && (
            <div className="ray-text--body-large">{building.address}</div>
          )}
          {building && building.images && building.images.length > 0 && (
            <MyCarousel images={building.images} key={building.id} />
          )}
        </div>
        <div className="ofc-description-title">
          <span className="ray-text--h4">
            {building && building.title ? building.title : "--"}
          </span>
          <div className="ray-text--body">
            {building && building.description ? building.description : "--"}
          </div>
          <div className="buy-daypass">
            <button
              disabled={
                (building && !building.isBookingEnabled)
              }
              onClick={handleCheckout}
              className="ray-button ray-button--primary"
            >
              Buy a Day Pass&nbsp;
              <span className="strikethrough">
                {building && stringifyPrice(BOOKING_PRICE)}
              </span>
              &nbsp;{stringifyPrice(BOOKING_DISCOUNTED_PRICE)}
            </button>
          </div>
          <MultiStageCheckout
            open={checkoutModalOpen}
            toggleCheckoutModal={toggleCheckoutModal}
          >
            {checkoutStepper === "SHOW_COVID_GUIDELINES" && (
              <GuidelinesStep
                changeStepper={setCheckoutStepper}
                onClose={toggleCheckoutModal}
                building={building}
              />
            )}
            {checkoutStepper === "DATE_PICKER" && (
              <DatePicker
                changeStepper={setCheckoutStepper}
                onClose={toggleCheckoutModal}
                building={building}
                buildingVariants={variants}
                buildingAnalyticsBody={buildingAnalyticsBody}
              />
            )}
            {checkoutStepper === "LOGIN_STEP" && (
              <LoginStep
                changeStepper={setCheckoutStepper}
                building={building}
              />
            )}
            {checkoutStepper === "ADD_MEMBER_STEP" && (
              <AddMemberStep
                changeStepper={setCheckoutStepper}
                onClose={toggleCheckoutModal}
                building={building}
                buildingAnalyticsBody={buildingAnalyticsBody}
              />
            )}
            {checkoutStepper === "CONFERENCE_ROOM_STEP" && (
              <ConferenceRoomStep
                changeStepper={setCheckoutStepper}
                onClose={toggleCheckoutModal}
                building={building}
                phoneNumMetafield={
                  building && building.phoneNumber ? building.phoneNumber : ""
                }
                buildingAnalyticsBody={buildingAnalyticsBody}
              />
            )}
            {checkoutStepper === "INVOICE_STEP" && (
              <InvoiceStep
                changeStepper={setCheckoutStepper}
                onClose={toggleCheckoutModal}
                building={building}
                cityName={city}
                buildingAnalyticsBody={buildingAnalyticsBody}
              />
            )}
          </MultiStageCheckout>
          <Amenities />
          <Safety />
        </div>
      </div>
    </div>
  );
};

export default BuildingDetail;
