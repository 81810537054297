import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { FETCH_CONFERENCE_ROOM_VARIANT } from "./FetchConfRoomVariant";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CartContext } from "../../../context/CartContext";
import { stringifyPrice } from "src/utils/Functions";
import BannerImage from "../../../../assets/images/conferenceRoom.jpeg";
import ConferenceItem from "./ConferenceItem";
import ConferenceForm from "./ConferenceForm";
import { useAuthContext } from "src/components/context/AuthContext";
import { graphqlIdToRestId } from "src/utils/Functions";
import axios from "src/axios";
import { recordAnalyticsEvent } from "src/utils/Analytics";

const CONFERENCE_ROOM_HANDLE = "conference-room-seat";

function ConferenceRoomStep({
  changeStepper,
  building,
  phoneNumMetafield,
  buildingAnalyticsBody,
}) {
  const classes = useStyles();
  const { cartState, cartDispatch } = useContext(CartContext);

  const { getAccessTokenSilently } = useAuth0();

  const { showToast } = useAuthContext();

  const [variantDetails, setVariantDetails] = useState({});

  const [deleteLoader, setDeleteLoader] = useState(false);

  const [checkoutPrice, setCheckoutPrice] = useState({
    discounted: 0,
    normal: 0,
  });

  const { loading: variantLoader, error: variantError, data } = useQuery(
    FETCH_CONFERENCE_ROOM_VARIANT,
    {
      variables: { handle: CONFERENCE_ROOM_HANDLE },
      onCompleted: () => {
        if (data.productByHandle) {
          const {
            id,
            price,
            title,
          } = data.productByHandle.variants.edges[0].node;
          const newId = graphqlIdToRestId(id);
          setVariantDetails({ id: Number(newId), oldId: id, price, title });
        }
      },
    }
  );

  // const [conferenceRooms, setConferenceRooms] = useState([{}]);

  const [displayFormOnly, toggleFormOnlyDisplay] = useState(true);
  const [selectedConfIndex, setSelectedConfIndex] = useState(0);

  const [selectedAvailableDates, setSelectedAvailableDates] = useState([]);

  const [checkoutLoader, setCheckoutLoader] = useState(false);

  const cartConferences =
    cartState && cartState.conferences ? cartState.conferences : [];

  // Effect for calculation of total price START ---->

  useEffect(() => {
    if (cartState) {
      if (cartConferences.length > 0) {
        if (cartConferences[0].reservationId) toggleFormOnlyDisplay(false);
      } else {
        setCtxConferences([{}]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cartState && cartState.invoiceScreenDetails) {
      const dayPassTotal = cartState.invoiceScreenDetails.originalPrice;
      const discountedDayPassTotal = cartState.invoiceScreenDetails.totalPrice;
      let reservationPrice = 0;
      if (cartConferences.length > 0) {
        cartConferences.forEach((room) => {
          if (Object.keys(room).length !== 0) {
            const roomPrice = room.price;
            reservationPrice += roomPrice;
          }
        });
      }
      setCheckoutPrice({
        normal: dayPassTotal + reservationPrice,
        discounted: discountedDayPassTotal + reservationPrice,
      });
    }
  }, [cartState, cartConferences]);

  // <---- Effect for calculation of total price END

  const setCtxConferences = (confData) => {
    cartDispatch({ type: "SET_CONFERENCES", payload: confData });
  };

  const updateConference = (memberInfo, index) => {
    const oldConferences = [...cartConferences];
    oldConferences[index] = { ...memberInfo };
    setCtxConferences([...oldConferences]);
    recordAnalyticsEvent("Added Conference Room To Cart", {
      ...buildingAnalyticsBody,
      "Conference room Confirmation": "YES",
      "Duration (Hrs.)": memberInfo.noOfHrs,
      Capacity: memberInfo.memberCount,
    });
    recordAnalyticsEvent("Added To Cart", {
      ...buildingAnalyticsBody,
      "Product Type": "Conference Room",
      "Conference room Confirmation": "YES",
      "Duration (Hrs.)": memberInfo.noOfHrs,
      Capacity: memberInfo.memberCount,
    });
  };

  const appendRoom = () => {
    const len = cartConferences.length;
    setCtxConferences(cartConferences.concat({}));
    setSelectedConfIndex(len);
    toggleFormOnlyDisplay(true);
  };
  const deleteConferenceItem = async (reservation) => {
    setDeleteLoader(true);
    try {
      const body = {
        reservation_id: reservation.reservationId,
        conference_room_id: reservation.conferenceId,
      };
      const token = await getAccessTokenSilently();
      await axios.post(`/shop/cancel-conf-room-reservation`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDeleteLoader(false);
      toggleFormOnlyDisplay(false);
      setSelectedConfIndex(-1);
      setCtxConferences(
        cartConferences.filter(
          (conference) => conference.reservationId !== reservation.reservationId
        )
      );
      recordAnalyticsEvent("Removed from Cart", {
        ...buildingAnalyticsBody,
        "Product Type": "Conference Room",
        "Conference room Confirmation": "NO",
        "Duration (Hrs.)": reservation.noOfHrs,
        Capacity: reservation.memberCount,
      });
    } catch (error) {
      console.error(error);
      showToast(
        true,
        "error",
        error && error.response && error.response.data
          ? error.response.data
          : error
      );
      setDeleteLoader(false);
    }
  };

  const handleFormDisplay = (item) => {
    toggleFormOnlyDisplay(item === -1 ? false : true);
    setSelectedConfIndex(item);
  };
  const deleteConferenceRoom = () => {
    if (!cartConferences[selectedConfIndex].reservationId) {
      setCtxConferences(
        cartConferences.filter((room, index) => index !== selectedConfIndex)
      );
    }
    toggleFormOnlyDisplay(false);
    setSelectedConfIndex(-1);
  };

  const selectConferenceRoom = (index) => {
    setSelectedConfIndex(index);
    toggleFormOnlyDisplay(true);
  };

  const triggerCheckout = async () => {
    setCheckoutLoader(true);
    cartDispatch({
      type: "SET_INVOICE_SCREEN_DETAILS",
      payload: {
        originalPrice: checkoutPrice.normal,
        totalPrice: checkoutPrice.discounted,
        prevStep: "CONFERENCE_ROOM_STEP",
      },
    });
    changeStepper("INVOICE_STEP");
    recordAnalyticsEvent("Clicked Next Step", {
      "Previous Step": "ADD MEMBER STEP",
      "Next Step": "SUMMARY STEP",
      "Present Step": "CONFERENCE ROOM STEP",
    });
    setCheckoutLoader(false);
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <h3 className="ray-text--h3">{`Day Pass at ${building.name}`}</h3>
        {/* <p className={`ray-text--body ${classes.greyText}`}>
          Book conference rooms for your meetings:
        </p> */}
      </Grid>
      <Grid item xs={12} container justify="center" spacing={0}>
        <Grid item xs={12} container alignItems="center" spacing={3}>
          <Hidden smDown>
            <Grid item xs={12}>
              <div
                style={{ backgroundImage: `url(${BannerImage})` }}
                className={classes.bannerImg}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <p className={`ray-text--body-large ${classes.greyText}`}>
              Schedule your Meetings
            </p>
          </Grid>
          {!displayFormOnly &&
            cartConferences.length > 0 &&
            React.Children.toArray(
              cartConferences.map((item, index) => (
                <ConferenceItem
                  conferenceInfo={item}
                  index={index}
                  deleteConference={deleteConferenceItem}
                  updateExistingConference={updateConference}
                  building={building}
                  toggleEditState={selectConferenceRoom}
                  handleFormDisplay={handleFormDisplay}
                  variantDetails={variantDetails}
                  deleteLoader={deleteLoader}
                />
              ))
            )}
          {displayFormOnly &&
            cartConferences.length > 0 &&
            selectedConfIndex > -1 && (
              <ConferenceForm
                conferenceInfo={cartConferences[selectedConfIndex]}
                index={selectedConfIndex}
                deleteConference={deleteConferenceItem}
                updateExistingConference={updateConference}
                building={building}
                handleFormDisplay={handleFormDisplay}
                variantDetails={variantDetails}
                selectedItemInfo
                toggleEditState={toggleFormOnlyDisplay}
                selectedAvailableDates={selectedAvailableDates}
                setSelectedAvailableDates={setSelectedAvailableDates}
                cartState={cartState}
                phoneNumMetafield={phoneNumMetafield}
                deleteLoader={deleteLoader}
              />
            )}
          {!displayFormOnly && (
            <Grid item xs={12} sm={4} md={4} className={classes.addMemberMT}>
              <button
                onClick={appendRoom}
                className={`ray-button ray-button--secondary`}
              >
                {cartConferences.length > 0
                  ? "+Add another meeting room"
                  : "+Add a meeting room"}
              </button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {displayFormOnly && (
        <Grid item xs={12} className={classes.buttonRow}>
          <button
            className={`ray-button ray-button--tertiary`}
            onClick={deleteConferenceRoom}
          >
            Back
          </button>
        </Grid>
      )}
      {!displayFormOnly && (
        <Grid
          item
          xs={12}
          container
          justify="flex-end"
          alignItems="center"
          spacing={3}
          className={classes.buttonRow}
        >
          <Grid item xs={12} md={2} className={classes.backBtn}>
            <button
              className={`ray-button ray-button--tertiary ${
                classes.fullWidthBtn
              }`}
              onClick={() => changeStepper("ADD_MEMBER_STEP")}
            >
              Back
            </button>
          </Grid>
          <Grid item xs={12} md={4}>
            <button
              className={`ray-button ray-button--primary ${
                classes.fullWidthBtn
              }`}
              onClick={triggerCheckout}
              disabled={
                cartConferences.length === 0 ||
                variantLoader ||
                (variantError ? true : false) ||
                checkoutLoader
              }
              style={{ marginTop: "1em" }}
            >
              {checkoutLoader ? (
                <CircularProgress size={25} className={classes.whiteColor} />
              ) : (
                <>
                  <span>Continue </span>
                  <span className="strikethrough">
                    {stringifyPrice(checkoutPrice.normal)}
                  </span>
                  <span>{` ${stringifyPrice(checkoutPrice.discounted)}`}</span>
                </>
              )}
            </button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ConferenceRoomStep;

const useStyles = makeStyles((theme) => ({
  linkSpan: {
    color: "#0000FF",
    cursor: "pointer",
  },
  backBtn: {
    order: 0,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
    marginTop: "1em",
  },
  headingMargin: {
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: "1em",
    },
  },
  addMemberMT: {
    marginTop: "1em",
    "& button": {
      width: "100%",
    },
  },
  greyText: {
    color: "#737373",
    marginBottom: 0,
  },

  whiteColor: {
    color: "#FFFFFF",
  },
  loaderParent: {
    textAlign: "center",
    "& svg": {
      color: "#0000FF",
    },
  },
  gridPadding: {
    padding: "1.5em",
  },
  buttonRow: {
    padding: "1em",
  },
  fullWidthBtn: {
    width: "100%",
  },
  bannerImg: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "15em",
    width: "100%",
  },
}));
