import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

function MicroMarketChip({ info, removeItem, addItem, length, check }) {
  const classes = useStyles();
  const [checked, setChecked] = useState(!check);
  const handleClickChange = () => {
    if (!checked === false) removeItem(info);
    else addItem(info);
    setChecked(!checked);
  };
  return (
    <div className={classes.gridJustify}>
      <div className="ray-checkbox">
        <input
          id={`checkbox-${info}`}
          name="filter-option"
          type="checkbox"
          onChange={handleClickChange}
          className="ray-checkbox__input"
          checked={checked}
        />
        <label className="ray-checkbox__label" htmlFor={`checkbox-${info}`}>
          {`${info} (${length})`}
        </label>
      </div>
    </div>
  );
}

export default MicroMarketChip;

const useStyles = makeStyles((theme) => ({
  gridJustify: {
    padding: "0.5em 1em",
  },
}));
