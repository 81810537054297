import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import pageNotFoundLogo from '../../assets/images/page_not_found.png';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      xs={12}
      className={classes.gridHeight}
    >
      <Grid item xs={12} className={classes.gridPadding}>
        <img
          src={pageNotFoundLogo}
          alt="page_not_found"
          className={classes.image}
        />
      </Grid>
      <Grid item xs={2}>
        <div class="ray-text--body-large">Oops, this URL in invalid</div>
      </Grid>
      <Grid item xs={12}>
        <div class="ray-text--body">
          <span class="ray-text--h5">Error 404</span> - Sorry, we can’t seem to
          find the page you’re looking for.
        </div>
      </Grid>
      <Grid item xs={12}>
        <div class="ray-text--body">
          <Link className={classes.linkStyle} to="/">
            Return to homepage
          </Link>{' '}
        </div>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;

const useStyles = makeStyles((theme) => ({
  gridPadding: {
    padding: '1.5em',
  },
  gridHeight: {
    minHeight: '54vh',
  },
  image: {
    height: '6em',
  },
  linkStyle: {
    textDecoration: 'none !important',
  },
}));
