import axios from "src/axios";

async function FetchUserDocument(token) {
  try {
    const response = await axios.get(`/customers/get-info`, {
      headers: {
        Authorization: token,
      },
    });
    return { valid: true, data: response.data };
  } catch (error) {
    console.error(error);
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Error occurred while fetching User Document";

    const errorCode =
      error.response && error.response.data && error.response.data.code
        ? error.response.data.code
        : "Error occurred while fetching User Document";
    return { valid: false, errorType: errorCode, message: errorMessage };
  }
}

export default FetchUserDocument;
