import React from 'react';

const RayRadioPillGroup = ({ label, options, onChange, ...rest }) => {
    return (
      <div {...rest}>
        <fieldset className="ray-fieldset">
          {label && <legend className="ray-fieldset__legend">{label}</legend>}
          <div className="ray-radio-pill__wrapper">
            {options.map((option, index) => (
              <div className="ray-radio-pill" onClick={() => onChange(option)}>
                <input
                  id={`radio-pill-${index}`}
                  name="radio-button-story"
                  type="radio"
                  className="ray-radio-pill__input"
                  defaultChecked={index === 0}
                />
                <label
                  className="ray-radio-pill__label"
                  for={`radio-pill-${index}`}
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    );
}

export default RayRadioPillGroup;