import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import {
  getRedirectUrl,
  removeRedirectUrl,
} from "src/utils/temp-storage/redirectUrl";
import DialogModal from "../../reuseComponents/DialogModal";
import { useAuthContext } from "../context/AuthContext";
import SignUpSection from "./CreateAccountStep";

const Auth0SignUp = () => {
  const { push: changeRoute } = useHistory();
  const { showSignupModal, setShowSignupModal } = useAuthContext();
  const { isLoading, isAuthenticated } = useAuth0();
  const redirectUrl = getRedirectUrl();

  React.useEffect(() => {
    if (!isLoading && isAuthenticated && redirectUrl) {
      changeRoute(redirectUrl);
      removeRedirectUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <DialogModal
      isOpen={showSignupModal}
      showModal={setShowSignupModal}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
    >
      <SignUpSection />
    </DialogModal>
  );
};

export default Auth0SignUp;
