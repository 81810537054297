import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useAuthContext } from "../../context/AuthContext";
import MemberSectionItem from "./MemberSectionItem";

function MembersSection() {
  const classes = useStyles();

  const { userTeamMembers, setUserTeamMembers } = useAuthContext();

  const updateMember = (memberInfo, index) => {
    const oldMembers = userTeamMembers;
    oldMembers[index] = { ...memberInfo };
    setUserTeamMembers([...oldMembers]);
  };

  const appendMember = () => {
    setUserTeamMembers(userTeamMembers.concat([{}]));
  };
  const deleteMember = (member) => {
    setUserTeamMembers(userTeamMembers.filter((mem) => mem !== member));
  };
  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        {/* <div className={`ray-grid__cell--span-12 ${classes.headingMargin}`}> */}
        <h3 className="ray-text--h3">Your Registered Team Members</h3>
        {/* </div> */}
      </Grid>
      <Grid item xs={12} sm={12} md={9} container spacing={0}>
        <Grid item xs={12} container alignItems="center" spacing={1}>
          <Grid item sm={5} xs={12}>
            <p className={`ray-text--body-small ${classes.greyText}`}>
              Team Member
            </p>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={6} sm={5}>
              <p className={`ray-text--body-small ${classes.greyText}`}>
                Email
              </p>
            </Grid>
          </Hidden>
          {userTeamMembers.length > 0 &&
            React.Children.toArray(
              userTeamMembers.map((item, index) => (
                <MemberSectionItem
                  index={index}
                  memberInfo={item}
                  deleteMember={deleteMember}
                  updateExistingMember={updateMember}
                />
              ))
            )}
          <Grid item xs={12} sm={4} md={3} className={classes.addMemberMT}>
            <button
              onClick={appendMember}
              className={`ray-button ray-button--secondary ray-button--compact`}
            >
              + Add Member
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MembersSection;

const useStyles = makeStyles((theme) => ({
  linkSpan: {
    color: "#0000FF",
    cursor: "pointer",
  },
  headingMargin: {
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: "1em",
    },
  },
  addMemberMT: {
    marginTop: "1em",
    "& button": {
      width: "100%",
    },
  },
  greyText: {
    color: "#737373",
  },
}));
