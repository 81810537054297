import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

function GuidelinesStep({ changeStepper }) {
  const classes = useStyles();

  const [agreeTerms, setAgreeTerms] = React.useState(false);
  return (
    <Grid container alignItems="center" justify="center" spacing={2}>
      <Grid item xs={12}>
        <span className="ray-text--h2">Please accept to proceed further</span>
      </Grid>
      <Grid item xs={12}>
        <div className="ray-text--body">
          Considering the recent notifications from respective governments with
          regard to lockdowns, please note that only specific industries are
          permitted to access private office spaces. These circulars are updated
          from time to time including but not limited to extension of timelines,
          modification of essential industries, or otherwise.
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={`ray-checkbox ${classes.mb}`}>
          <input
            id="guideline_checkbox"
            name="checkbox-button-story"
            type="checkbox"
            checked={agreeTerms}
            onChange={() => setAgreeTerms(!agreeTerms)}
            className="ray-checkbox__input"
          />
          <label className="ray-checkbox__label" htmlFor="guideline_checkbox">
            By checking this box you are disclosing that you fall within the
            scope of the permitted services.
          </label>
        </div>
        <div className="ray-text--body">
          Please note that you will be solely liable for any violations in this
          regard. This communication is issued without prejudice to our rights
          in law.
        </div>
      </Grid>
      <Grid item md={4} xs={12}>
        <button
          className={`ray-button ray-button--primary ${classes.fullWidthBtn}`}
          disabled={!agreeTerms}
          onClick={() => changeStepper('DATE_PICKER')}
        >
          Accept & Continue
        </button>
      </Grid>
    </Grid>
  );
}

export default GuidelinesStep;

const useStyles = makeStyles((theme) => ({
  fullWidthBtn: { width: '100%' },
  mb: { marginBottom: '1em' },
}));
