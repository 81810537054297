import React, { useEffect, useState } from 'react';
import BuildingCard from '../BuildingCard/BuildingCard.jsx';
import Spinner from '../Spinner.jsx';
import './CityCard.scss';
import { useParams } from 'react-router-dom';
import Amenities from '../Snippets/Amenities.jsx';
import Safety from '../Snippets/Safety.jsx';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import EasySteps from '../Snippets/EasySteps.jsx';
import MicroMarketChip from './MicroMarketChip';
import MapWithMarkers from '../../reuseComponents/MapWithMarkers';
import SelectAreaSidebar from './SelectAreaSidebar';
import { MdLocationOn } from 'react-icons/md';
import SwitchButton from '../../reuseComponents/SwitchButton';
import { CITY_COORDINATES } from '../../constants';
import { RiBuildingFill } from 'react-icons/ri';
import { recordAnalyticsEvent } from 'src/utils/Analytics';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'src/axios.js';
import PageNotFound from 'src/reuseComponents/PageNotFound/index.jsx';
import { capitalizeText } from 'src/utils/Functions.js';

const ProductsByCollection = () => {
  const { city } = useParams();

  const [selectedMicroMarkets, setSelectedMicroMarkets] = useState([]);
  const [microMarketMap, setMicroMarketMap] = useState({});
  const [mmSidebar, toggleMMSidebar] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  const [userCoordinates, setUserCoordinates] = useState();

  const [mobileSection, setMobileSection] = useState('ITEMS');

  const [selectedArea, setSelectedArea] = useState([]);

  const [getLocationLoader, setLocationLoader] = useState(false);

  const match = useRouteMatch('/:city/');

  useEffect(() => {
    setMicroMarketMap({});
    fetchBuildingsForCity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    if (data) {
      const productsData = data;
      const microObj = { ...microMarketMap };

      productsData.forEach((product) => {
        if (!microObj[product.micromarket]) {
          microObj[product.micromarket] = {
            coordinates: {
              lat: Number(product.micromarket_latitude),
              lng: Number(product.micromarket_longitude),
            },
            buildings: [],
          };
        }
        microObj[product.micromarket].buildings.push({
          ...product,
        });
      });
      setMicroMarketMap(microObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const addItemToMicroMarkets = (item) => {
    setSelectedArea(
      selectedArea.concat({
        location: item,
        coordinates: microMarketMap[item].coordinates,
      })
    );
    setSelectedMicroMarkets(
      selectedMicroMarkets.concat(microMarketMap[item].buildings)
    );
    setSelectedArea((elements) =>
      elements.filter((area) => area.location !== 'My Location')
    );
    recordAnalyticsEvent('Clicked PLP Filter', {
      'Filter Location': 'YES',
      'Micromarket filter': item,
    });
  };
  const removeItemFromMicroMarkets = (item) => {
    setSelectedMicroMarkets((mMarkets) =>
      mMarkets.filter(
        (market) => !microMarketMap[item].buildings.includes(market)
      )
    );
    setSelectedArea((areas) =>
      areas.filter((element) => element.location !== item)
    );
  };

  const getLocation = () => {
    setLocationLoader(true);
    navigator.geolocation.getCurrentPosition((position, error, options) => {
      setUserCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setSelectedArea(
        [].concat({
          location: 'My Location',
          coordinates: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
      );
      setLocationLoader(false);
    });
    recordAnalyticsEvent('Clicked PLP Change View', {
      'Find a WeWork near me': 'YES',
    });
    setSelectedMicroMarkets([]);
    setMobileSection('MAP');
    recordAnalyticsEvent('Clicked PLP Change View', { 'Map View': 'YES' });
    toggleMMSidebar(false);
  };

  const fetchBuildingsForCity = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get('buildings/get-buildings-by-city', {
        params: {
          city_handle: city,
        },
      });
      setData(data.buildingsInfo);
      setLoader(false);
    } catch (error) {
      console.log(error);
      const tempError =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : 'Something went wrong';
      setError(tempError);
      setLoader(false);
    }
  };

  const resetMarkets = () => {
    setSelectedMicroMarkets([]);
    toggleMMSidebar(false);
  };

  if (loader) return <Spinner />;

  if (error) return <PageNotFound />;

  return (
    <>
      <div className="wework-into">
        <div className="ray-page-container">
          <div className="breadcrumbs">
            <Link className="breadcrumb-links" to="/">
              <span>HOME</span>
            </Link>
            <span className="breadcrumb-arrow">ᐳ</span>
            <span
              className={match ? 'revert-color' : ''}
              style={{ textTransform: 'uppercase' }}
            >
              {city}
            </span>
          </div>
          <div className="ray-grid">
            <div className="ray-grid__cell--span-full get-back-work">
              <h1 className="ray-text--h1">
                Workspaces at the tap of a button at WeWork
              </h1>
              <div className="ray-text--body-large textbox-for-desktop">
                Step out of home to a safe and inspiring workspace at WeWork.
                With a day pass at Rs. 500, WeWork gives you premium amenities
                like meeting rooms, high speed WiFi, and a creative environment
                that empowers you to focus and create your best work.
              </div>
              <div className="ray-text--body-large textbox-for-mobile">
                Step out of home to a safe and inspiring workspace at WeWork,
                with a day pass at Rs.500.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="location-list">
        <div className="ray-page-container">
          <div className="ray-grid">
            <div className="ray-grid__cell--span-full title__row_geo">
              <h2 className="ray-text--h2">{city && capitalizeText(city)}</h2>
              <SwitchButton
                section={mobileSection}
                setSection={setMobileSection}
                recordAnalyticsEvent={recordAnalyticsEvent}
              />
            </div>
            <div className="ray-grid__cell--span-full micromarket__row">
              {Object.keys(microMarketMap).map((market) => (
                <MicroMarketChip
                  info={market}
                  length={microMarketMap[market].buildings.length}
                  removeItem={removeItemFromMicroMarkets}
                  addItem={addItemToMicroMarkets}
                  selectedArea={selectedArea.length > 0 ? selectedArea : {}}
                />
              ))}
            </div>
            <div className="ray-grid__cell--span-12-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-4-phone filter__btn_row">
              <button
                onClick={() => toggleMMSidebar(true)}
                className={`ray-button ray-button--${
                  selectedMicroMarkets.length >= 1 ? 'secondary' : 'primary'
                }`}
              >
                <span>Filter Locations</span>
                {selectedMicroMarkets.length >= 1 ? (
                  <div>
                    <RiBuildingFill />{' '}
                    <span>{selectedMicroMarkets.length}</span>
                  </div>
                ) : (
                  ''
                )}
              </button>
            </div>
            <div className="ray-grid__cell--span-12-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-4-phone near--me__btn-row">
              <button
                onClick={getLocation}
                disabled={getLocationLoader}
                className="ray-button ray-button--tertiary wework_near_me__btn"
              >
                {getLocationLoader ? (
                  <CircularProgress size={25} style={{ color: '#0000FF' }} />
                ) : (
                  <>
                    <MdLocationOn />
                    <span>Find a WeWork near me</span>
                  </>
                )}
              </button>
            </div>
            <div
              className={`ray-grid__cell--span-6-desktop ray-grid__cell--span-8-tablet ray-grid__cell--span-4-phone ${
                mobileSection === 'MAP' ? 'hide__on--Mobile' : ''
              }`}
            >
              <div className="ray-grid">
                {selectedMicroMarkets.length > 0
                  ? selectedMicroMarkets.map((building) => (
                      <div className="ray-grid__cell--span-6-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-4-phone">
                        <BuildingCard
                          {...building}
                          city={city}
                          recordAnalyticsEvent={recordAnalyticsEvent}
                        />
                      </div>
                    ))
                  : data &&
                    data.map((building) => (
                      <div className="ray-grid__cell--span-6-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-4-phone">
                        <BuildingCard
                          {...building}
                          city={city}
                          recordAnalyticsEvent={recordAnalyticsEvent}
                        />
                      </div>
                    ))}
              </div>
            </div>
            <div
              className={`ray-grid__cell--span-6-desktop ray-grid__cell--span-8-tablet ray-grid__cell--span-4-phone ${
                mobileSection === 'ITEMS' ? 'hide__on--Mobile' : ''
              }`}
            >
              {data.length > 0 && (
                <MapWithMarkers
                  mapCenter={
                    selectedArea.length === 1
                      ? selectedArea[0].coordinates
                      : CITY_COORDINATES[city]
                  }
                  zoom={selectedArea.length === 1 ? 13 : 11}
                  userMarker={
                    userCoordinates
                      ? { ...userCoordinates, label: 'My Location', city: city }
                      : null
                  }
                  markers={
                    selectedMicroMarkets.length > 0
                      ? selectedMicroMarkets.map((building) => ({
                          lat: Number(building.latitude),
                          lng: Number(building.longitude),
                          googlePlaceId: building.googlePlaceId
                            ? building.googlePlaceId
                            : '',
                          label: building.name,
                          titleImage: building.imageSrc,
                          city: city,
                        }))
                      : data.map((building) => ({
                          lat: Number(building.latitude),
                          lng: Number(building.longitude),
                          googlePlaceId: building.googlePlaceId
                            ? building.googlePlaceId
                            : '',
                          label: building.name,
                          titleImage: building.imageSrc,
                          city: city,
                        }))
                  }
                />
              )}
            </div>
            <EasySteps />
            <Amenities />
            <Safety />
          </div>
        </div>
      </div>
      <SelectAreaSidebar
        sidebarState={mmSidebar}
        toggleSidebar={toggleMMSidebar}
        microMarketMap={microMarketMap}
        selectedMicroMarkets={selectedMicroMarkets}
        addItem={addItemToMicroMarkets}
        removeItem={removeItemFromMicroMarkets}
        resetMarkets={resetMarkets}
      />
    </>
  );
};

export default ProductsByCollection;
