const TEMP_STORAGE_KEY = "show-stepper";

export const getStepperVisibility = () =>
  sessionStorage.getItem(TEMP_STORAGE_KEY);

export const storeStepperVisibility = (value) =>
  sessionStorage.setItem(TEMP_STORAGE_KEY, value);

export const removeStepperVisibility = () =>
  sessionStorage.removeItem(TEMP_STORAGE_KEY);
