import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BsGrid1X2 } from "react-icons/bs";
import SwitchForMap from "src/assets/svg-components/SwitchForMap";

function SwitchButton({ section, setSection, recordAnalyticsEvent }) {
  const classes = useStyles();
  const handleSwitch = (option) => {
    setSection(option);
    recordAnalyticsEvent(
      "Clicked PLP Change View",
      option === "ITEMS" ? { "List View": "YES" } : { "Map View": "YES" }
    );
  };
  return (
    <div className={classes.root}>
      <div
        onClick={() => handleSwitch("ITEMS")}
        className={`${classes.itemBtn} ${
          section === "ITEMS" ? classes.selected : classes.unselected
        }`}
      >
        <BsGrid1X2 />
      </div>
      <div
        onClick={() => handleSwitch("MAP")}
        className={`${classes.mapBtn} ${
          section === "MAP" ? classes.selected : classes.unselected
        }`}
      >
        <SwitchForMap />
      </div>
    </div>
  );
}
export default SwitchButton;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50px",
    border: "1.82868px solid #E5E5E5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "7em",
    [theme.breakpoints.up(1195)]: {
      display: "none",
    },
  },
  mapBtn: {
    borderRadius: "0 50px 50px 0",
    flexBasis: "50%",
    textAlign: "center",
    padding: "0.6em",
    "& svg": {
      fontSize: "1.4em",
    },
  },
  itemBtn: {
    borderRadius: "50px 0 0 50px",
    flexBasis: "50%",
    textAlign: "center",
    padding: "0.7em",
  },
  selected: {
    color: "#000",
    backgroundColor: "#E5E5E5",
  },
  unselected: {
    color: "#000",
    backgroundColor: "#fff",
  },
}));
