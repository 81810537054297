import React from "react";
import { FaBalanceScaleRight } from "react-icons/fa";
import Slider from "react-slick";
import styled from "styled-components";

export default function Testimonials() {
  let carouselRef = React.useRef();

  const next = () => {
    carouselRef.slickNext();
  };
  const previous = () => {
    carouselRef.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: FaBalanceScaleRight,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledWrapper>
      <div className="container ray-page-container">
        <div className="d-flex">
          <div className="intro">
            <h2 className="ray-text--h2">Testimonials</h2>
            <p className="ray-text--body-large testimonial-intro">
              Our core values are at the heart of all that we do. They are
              integrated into our daily lives and help us to remember our
              customers always comes first, the last thank you should always
              come from us.
            </p>
          </div>
          <div className="nav-btn-section">
            <div className="nav-btn-inner-section">
              <div
                onClick={previous}
                className={`previous btns cursor-pointer`}
              >
                <svg
                  className="nav-btns"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                </svg>
              </div>
              &nbsp; &nbsp;
              <div onClick={next} className={`next btns cursor-pointer`}>
                <svg
                  className="nav-btns"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <Slider {...settings} ref={(slider) => (carouselRef = slider)}>
          {React.Children.toArray(
            TESTIMONIAL_DATA?.map((data) => (
              <div className="padding-space">
                <div className="testimonial-section">
                  <div className="client-info cliet-info-height">
                    <h3 className="ray-text--h3 name-spacing highlighted-color">
                      {data.name}
                    </h3>
                    {data.position && (
                      <p className="ray-text--body position-spacing highlighted-color">
                        {data.position}
                      </p>
                    )}
                    <p className="ray-text--body highlighted-color">
                      {data.companyName}
                    </p>
                  </div>
                  <div className="client-info">
                    <p
                      className={`ray-text--body testimonial-description`}
                      id="testimonials"
                    >
                      {data.testimonial}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </StyledWrapper>
  );
}

const TESTIMONIAL_DATA = [
  {
    name: "Priyanka Singh",
    position: "",
    companyName: "Studio Ruh",
    testimonial:
      "WeWork is a  great co-working space. I have been using the Daypass for a couple of months now this option has made it so convenient and easy to work. It's amazing how we could get to use professional space and still make it fun and motivating to work. With all the positive attitudes around helps us to grow professionally and as an individual. Would like to thank everyone at WeWork.",
  },
  {
    name: "Ritu Jain",
    position: "Director and Head of Business Development",
    companyName: "TEAPOD Services",
    testimonial:
      "WeWork has been very quick and resourceful in helping us with our workspace requirements in a short span of time. The team understands our specifications and provides us with an equipped and convenient place to work in terms of cleanliness, comfort and financially viable resource by giving the option to purchase day passes as and when it’s required.",
  },
  {
    name: "Sushil Verghese",
    position: "Founder",
    companyName: "SlowStories - Strategic Content Creation Consultancy",
    testimonial:
      "I used the WeWork day-pass as soon as I heard about it. With WeWork's day-pass, I get to step out of my house and work in a space that is relatively safer, quieter, and more comfortable than my local coffee shop. Since I can book any WeWork location for a day, the pass gives me the added advantage of using WeWork as a base of operation in-between meetings with clients at locations far from home. Kudos to you guys! Keep it up!",
  },
  {
    name: "Mohammed Roshan",
    position: "Cofounder",
    companyName: "GoSats",
    testimonial:
      "The On-Demand service has provided great flexibility for our startup. The WeWork network helped us grow immensely over the past few months and the hub staff are super-cool and get all our needs sorted. They even surprise the members with free sandwiches and muffins every now and then!",
  },
  {
    name: "Aakarsh Nambiar",
    position: "Associate Consultant - Analytics",
    companyName: "Bigbasket",
    testimonial:
      "With offices still being closed, WeWork on On-Demand was the perfect way to get out of the Work From Home rut and work in a great office environment again. The option of booking a day at any of the several offices across the city was a very attractive proposition and I have done so after the great experience I had the first time I used On-Demand. The beautifully designed offices and the availability of amenities made me feel more productive and creative.",
  },
];

const StyledWrapper = styled.div`
  margin-top: 50px;

  .cursor-pointer {
    cursor: pointer;
  }

  .name-spacing {
    margin-bottom: 5px;
  }

  .d-flex {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }
  }

  .intro {
    @media (max-width: 600px) {
      padding-left: 5px;
    }
  }

  .position-spacing {
    margin-bottom: 0px;
  }

  .highlighted-color {
    color: #0000ff;
  }

  .read-more-btn-hover:hover {
    text-decoration: underline;
  }

  .testimonial-section {
    padding: 20px;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
    display: flex;
    flex-direction: column;
  }

  .slick-track {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex !important;
    .slick-slide {
      padding-right: 30px;
      height: auto !important;

      @media (max-width: 700px) {
        padding-right: 10px;
      }

      div:not(.client-info),
      .padding-space {
        height: 100%;
      }
    }
  }

  .testimonial-intro {
    width: 80%;
    max-width: 1400px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .nav-btns {
    fill: blue;
  }

  .nav-btn-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .nav-btn-inner-section {
      margin-left: auto;
      margin-right: 50px;
      width: 100px;
      display: flex;

      @media (max-width: 600px) {
        margin-left: unset;
        margin-right: unset;
      }

      .btns {
        padding: 10px;
      }
    }
  }

  .padding-space {
    margin-left: 10px;

    @media (max-width: 600px) {
      margin-left: 5px;
    }
  }

  .cliet-info-height {
    min-height: 133px;
  }
`;
