import React, { useState, useContext } from 'react';
import { createMuiTheme } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker as DatePicker2,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { CartContext } from '../context/CartContext';
import CheckoutButton from '../CheckoutButton/CheckoutButton';
import './DatePicker.scss';
import { graphqlIdToRestId } from 'src/utils/Functions';
import { recordAnalyticsEvent } from 'src/utils/Analytics';

const weWorkBlueTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
  },
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        color: '#E3E3E3',
      },
    },
  },
});

const DatePicker = ({
  building,
  onClose,
  changeStepper,
  buildingAnalyticsBody,
  buildingVariants,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState();
  const [presentDayDisabled, setPresentDayDisabled] = useState(false);
  // const [showCalendar, setCalendarVisibility] = useState(true);
  // const [daysWithDot, setDaysWithDot] = useState([]);

  const { cartState, cartDispatch } = useContext(CartContext);

  const variants = buildingVariants.map((edge) => edge.node);

  const getVariantFromDateString = (dateString) =>
    variants.find(
      (variant) => variant.title === dateString && variant.availableForSale
    );

  const addNewPass = (date) => {
    // hacky fix for shouldDisableDate triggering onChange on initial load
    const millisecondsForDate = date.get('millisecond');

    if (millisecondsForDate !== 0) return;

    setSelectedDate(date);
    let variant = getVariantFromDateString(date.format('DD/MM/YYYY'));
    const variantId = graphqlIdToRestId(variant.id);
    if (variant) {
      cartDispatch({
        type: 'ADD_ITEM',
        payload: {
          id: Number(variantId),
          oldId: variant.id,
          price: parseInt(variant.price),
          variant: variant,
          date: date,
        },
      });
    }
    recordAnalyticsEvent('Added Date To Cart', {
      ...buildingAnalyticsBody,
      'Date Confirmation': 'YES',
      Date: date.format('dddd, Do MMMM YYYY'),
      'Pass Quantity': 1,
    });
    recordAnalyticsEvent('Added To Cart', {
      ...buildingAnalyticsBody,
      'Product Type': 'Day Pass',
      'Date Confirmation': 'YES',
      Date: date.format('dddd, Do MMMM YYYY'),
      'Pass Quantity': 1,
    });
    // setCalendarVisibility(false);
  };

  // useEffect(() => {
  //   if (Object.keys(cartState.items).length > 0) {
  //     setCalendarVisibility(false);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const shouldDisableDate = (date) => {
    const isNotToday = date.isAfter(new Date(), 'day');
    const variant = getVariantFromDateString(date.format('DD/MM/YYYY'));
    if (isNotToday) return variant === undefined;
    if (variant === undefined) {
      if (!presentDayDisabled) setPresentDayDisabled(true);
      return true;
    }
    const [presentHour, presentMinutes] = [
      moment().get('hour'),
      moment().get('minute'),
    ];
    const modifiedPresentDate = date
      .set('hour', presentHour)
      .set('minute', presentMinutes);
    const presentDate = moment(new Date())
      .set('hour', 18)
      .set('minute', 0);
    const diff = presentDate.diff(modifiedPresentDate, 'hours');
    if (diff <= 0 && diff >= -6) {
      if (!presentDayDisabled) setPresentDayDisabled(true);
      return true;
    }
    return false;
  };
  // const renderDayInPicker = (
  //   date,
  //   selectedDate,
  //   dayInCurrentMonth,
  //   dayComponent
  // ) => {
  //   console.log({date:date.toDate(),daysWithDot});
  //   // if (daysWithDot.includes(date.toDate())) {
  //     return (
  //       <div className={classes.dayWithDotContainer}>
  //         {dayComponent}
  //         <div className={classes.dayWithDot} />
  //       </div>
  //     );
  //   // }

  //   return dayComponent;
  // };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3 className="ray-text--h3">Day Pass at {building.name}</h3>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={
          presentDayDisabled ? classes.disabledDayGrid : classes.validDayGrid
        }
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={weWorkBlueTheme}>
            <DatePicker2
              onChange={addNewPass}
              value={selectedDate}
              variant="static"
              disableToolbar
              disablePast
              // renderDay={renderDayInPicker}
              shouldDisableDate={shouldDisableDate}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} md={6}>
        <h4 className="ray-text--h4">Day Passes Added:</h4>
        {Object.keys(cartState.items).length > 0 ? (
          Object.keys(cartState.items).map((item, index) => (
            <ProductListItemTile
              item={cartState.items[item]}
              key={index}
              deleteItem={cartDispatch}
              recordAnalyticsEvent={recordAnalyticsEvent}
              buildingBody={buildingAnalyticsBody}
            />
          ))
        ) : (
          <p style={{ color: '#737373' }} className="ray-text--body">
            Select dates from the calendar to add the day passes for
          </p>
        )}
        {/* <button
          className="ray-button ray-button--tertiary add-item"
          disabled={Object.keys(cartState.items).length === 0}
          onClick={() => setCalendarVisibility(true)}
        >
          Add another pass +
        </button> */}
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.buttonRow}
        container
        spacing={2}
        justify="flex-end"
        alignItems="center"
      >
        <CheckoutButton
          changeStepper={changeStepper}
          disabled={Object.keys(cartState.items).length === 0}
          onClose={onClose}
          building={building}
        />
      </Grid>
    </Grid>
  );
};

const ProductListItemTile = ({
  item,
  deleteItem,
  recordAnalyticsEvent,
  buildingBody,
}) => {
  const handleDelete = () => {
    deleteItem({ type: 'REMOVE_ITEM', payload: item.id });
    recordAnalyticsEvent('Removed from Cart', {
      ...buildingBody,
      'Product Type': 'Day Pass',
      'Date Confirmation': 'NO',
      Date: item.date.format('dddd, Do MMMM YYYY'),
      'Pass Quantity': 1,
    });
  };
  return (
    <div className="product-list-item-tile">
      <span>{item.date.format('dddd, Do MMMM YYYY')}</span>
      <button
        onClick={handleDelete}
        className="ray-button ray-button--tertiary remove-item"
      >
        &times;
      </button>
    </div>
  );
};

export default DatePicker;

const useStyles = makeStyles((theme) => ({
  gridPadding: {
    padding: '1.5em',
  },
  buttonRow: {
    padding: '1em',
  },
  dayWithDotContainer: {
    position: 'relative',
  },
  dayWithDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: '#f1f1f1',
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%',
  },
  validDayGrid: {
    '& button.MuiPickersDay-current': {
      fontWeight: '400',
    },
  },
  disabledDayGrid: {
    '& button.MuiPickersDay-current': {
      fontWeight: '400',
      color: '#E3E3E3 !important',
    },
  },
}));
