import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IoIosArrowRoundBack } from "react-icons/io";

function BookDetailSection({ bookingDetail, changeSection }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        onClick={() => changeSection("BOOKINGS")}
        className={`ray-text--body ${classes.goBackBtn}`}
      >
        {" "}
        <IoIosArrowRoundBack /> Go Back
      </div>
      <h4 className="ray-text--h3">Order Detail</h4>
      <div className={classes.detailContainer}>
        <h4 className="ray-text--h3">
          {bookingDetail && bookingDetail.locationName
            ? bookingDetail.locationName
            : "Location Not Available"}
        </h4>
        <div className={`ray-text--body ${classes.secondaryColor}`}>
          {bookingDetail && bookingDetail.dateString
            ? bookingDetail.dateString
            : "Date Not Available"}
        </div>
      </div>
      <div className={classes.memberContainer}>
        {bookingDetail &&
        bookingDetail.teamMembers &&
        bookingDetail.teamMembers.length > 0 ? (
          bookingDetail.teamMembers.map((item, index) => (
            <div key={index.toString()} className={`ray-text--body`}>
              {item.name}
            </div>
          ))
        ) : (
          <div className={`ray-text--body ${classes.secondaryColor}`}>
            No Members Available
          </div>
        )}
      </div>
    </div>
  );
}

export default BookDetailSection;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  secondaryColor: {
    color: "#666666",
  },
  detailContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "start",
    padding: "1em",
    backgroundColor: "#e3e3e3",
    borderRadius: "5px",
  },
  memberContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "start",
    padding: "1em",
  },
  goBackBtn: {
    cursor: "pointer",
    color: "#0000FF",
  },
}));
