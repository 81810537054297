import React from "react";
import { createStyles, withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";
import Typography from "@material-ui/core/Typography";

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const dialogStyles = makeStyles(theme=>({
    modalStyle: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: 0,
        maxHeight: '100%',
        height: '100%',
      },
    },
  }));
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DialogModal({
  isOpen,
  children,
  showModal,
  title,
  maxWidth = "sm",
  fullWidth = false,
  disableBackdropClick=false,
  ...props
}) {
  const customClasses = dialogStyles();
  return (
    <Dialog
      onClose={() => showModal(false)}
      aria-labelledby={`${title}-dialog-title`}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className={customClasses.modalStyle}
      disableBackdropClick={disableBackdropClick}
      PaperProps={
        {
          classes: {
            root: customClasses.modalStyle
          },
        }
      }
      {...props}
    >
      {title && (
        <DialogTitle
          id={`${title}-dialog-title`}
          onClose={() => showModal(false)}
        >
          <span class="ray-text--h4">{title}</span>
        </DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}