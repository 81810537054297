import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { CartContext } from "../context/CartContext";
import { stringifyPrice } from "src/utils/Functions";
import { useAuthContext } from "../context/AuthContext";
import { recordAnalyticsEvent } from "src/utils/Analytics";

import "./CheckOutButton.scss";

const CheckoutButton = ({
  changeStepper,
  building,
  onClose,
  disabled = false,
}) => {
  const classes = useStyles();

  const { cartState } = useContext(CartContext);
  const { isAuthenticated } = useAuthContext();

  const handleProceed = () => {
    if (isAuthenticated) {
      changeStepper("ADD_MEMBER_STEP");
      recordAnalyticsEvent("Clicked Next Step", {
        "Next Step": "ADD MEMBER STEP",
        "Previous Step": "--",
        "Present Step": "DATE PICKER STEP",
      });
    } else {
      changeStepper("LOGIN_STEP");
    }
  };

  // const toggleCheckbox = () => {
  //   cartDispatch({ type: "TOGGLE_BOOKING_FOR_SELF" });
  // };

  return (
    <>
      {/* {!disabled && <div className="self__booking_checkbox-row">
      <div className="ray-checkbox">
    <input
      id="checkbox_self--booking"
      name="checkbox-booking-self"
      type="checkbox"
      checked={cartState.bookingForSelf}
      className="ray-checkbox__input"
      onChange={toggleCheckbox}
    />
    <label className="ray-checkbox__label" for="checkbox_self--booking">I'm booking for myself</label>
  </div>
      </div>} */}
      <Hidden smDown>
        <Grid item md={2} xs={12} className={classes.backBtn}>
          <button
            onClick={() =>
              building.show_covid_guidelines
                ? changeStepper("SHOW_COVID_GUIDELINES")
                : onClose()
            }
            className={`ray-button ray-button--tertiary ${
              classes.fullWidthBtn
            }`}
          >
            {building.show_covid_guidelines ? "Back" : "Cancel"}
          </button>
        </Grid>
      </Hidden>
      <Grid item md={4} xs={12}>
        <button
          disabled={disabled}
          onClick={handleProceed}
          className={`ray-button ray-button--primary ${classes.fullWidthBtn}`}
        >
          Continue &nbsp;
          {cartState.globalQuantity > 0 && (
            <>
              <span className="strikethrough">
                {stringifyPrice(Object.keys(cartState.items).length * 1000)}
              </span>
              <span>
                &nbsp;
                {stringifyPrice(Object.keys(cartState.items).length * 500)}
              </span>
            </>
          )}
        </button>
      </Grid>
      <Hidden smUp>
        <button
          onClick={onClose}
          className={`ray-button ray-button--tertiary ${classes.fullWidthBtn}`}
        >
          Cancel
        </button>
      </Hidden>
    </>
  );
};

export default CheckoutButton;

const useStyles = makeStyles((theme) => ({
  fullWidthBtn: {
    width: "100%",
  },
}));
