import axios from "src/axios";

async function FetchMembers(token) {
  try {
    const response = await axios.get(`/customers/fetch-members`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.members;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export default FetchMembers;
