import React from "react";
import step1 from "../../assets/images/step1.png";
import step2 from "../../assets/images/step2.png";
import step3 from "../../assets/images/step3.png";

const EasySteps = () => {
  return (
    <div className="easy-steps">
      <p className="ray-text--h2">Book your space at WeWork in 3 easy steps:</p>
      <div className="ray-grid">
        <div className="ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone easy-steps-section">
          <img className="easy-step-img" src={step1} alt="" />
          <span className="step-text">1. Find your nearest location </span>
        </div>
        <div className="ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone easy-steps-section">
          <img className="easy-step-img" src={step2} alt="" />
          <span className="step-text">2. Choose the days you need an office space or conference room </span>
        </div>
        <div className="ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-2-phone easy-steps-section">
          <img className="easy-step-img" src={step3} alt="" />
          <span className="step-text">3. Make the booking </span>
        </div>
      </div>
    </div>
  );
};

export default EasySteps;
