import React from "react";
import "./Footer.scss";
import { Link } from 'react-router-dom';
import { useRouteMatch } from "react-router-dom";
import { recordAnalyticsEvent } from "src/utils/Analytics";

const Footer = () => {

  let match = useRouteMatch("/:city/:handle");

  const eventRecorder = footerLinkName => {
    recordAnalyticsEvent("Clicked Footer", {"Viewed URL": window.location.href, "Footer link": footerLinkName})
  }

  return (
    <div className={match ? 'footer footer-top-margin' : 'footer'}>
      <div className="ray-page-container">
        <div className="ray-grid">
          <div className="ray-grid__cell--span-full">
            <p>WeWork India HQ</p>
            <p>36 Infantry Road</p>
            <p>Bengaluru, Karnataka</p>
          </div>

          <div className="ray-grid__cell--span-full">
            <div className="terms-privacy">
              <a href="https://wework.co.in/india/legal/terms-of-service" target="_blank" rel="noopener noreferrer" onClick={() => eventRecorder("Terms")}>
                <span>Terms</span>
              </a>
              <span className="bullets">&#8226;</span>
              <a href="https://wework.co.in/india/legal/privacy" target="_blank" rel="noopener noreferrer" onClick={() => eventRecorder("Privacy")}>
                <span>Privacy</span>
              </a>
              <span className="bullets">&#8226;</span>
              <Link to="/faq" onClick={() => eventRecorder("FAQ")}>
                <span>FAQ</span>
              </Link>
              <span className="bullets">&#8226;</span>
              <Link to="/about" onClick={() => eventRecorder("About us")}>
                <span>About us</span>
              </Link>
            </div>
          </div>

          <div className="ray-grid__cell--span-full">
            <div className="ray-text--body-small">
              Copyright &copy; 2020 WeWork Companies Inc. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;