import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Analytics from "react-router-ga";
import ReactPixel from "react-facebook-pixel";
import * as Sentry from "@sentry/react";

import Hotjar from "./components/Hotjar";
import { CartProvider } from "./components/context/CartContext.jsx";
import { GoogleAnalyticsProvider } from "./components/context/GoogleAnalyticsCtx/context";
import { UserProvider } from "./components/context/AuthContext/context";
import Home from "./components/Home/Home.jsx";
import BuildingDetail from "./components/BuildingDetail/BuildingDetail.jsx";
import DatePicker from "./components/BuildingDetail/DatePicker.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import client from "../src/apollo";
import "./App.scss";
import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import UserAccount from "./components/UserAcccountPage";
import Faq from "./components/FAQ/Faq.jsx";
import NotFound from "./components/NotFound";
import About from "./components/About/About.jsx";
import ProductsByCollection from "./components/ProductsByCollection/ProductsByCollection.jsx";
import ToastAlert from "./reuseComponents/ToastAlert";
import GiftCardDetail from "./components/GiftCard/GiftCardDetail.jsx";
// import Login from 'src/components/Auth0/Login';
import Auth0SignUp from "./components/Auth0SignUp/Auth0SignUp.jsx";

const App = () => {
  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init("188153455086791", options);
  });

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <div className="App">
        <Helmet>
          {process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" && (
            <meta
              name="facebook-domain-verification"
              content="j21azx39ql2yuh51tlhcfctff6bpdb"
            />
          )}
          <link
            rel="stylesheet"
            href="//www-static.wework.com/apercu/apercu.css"
          />
          <link
            rel="stylesheet"
            href="//www-static.wework.com/apercu/apercu_mono.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <ApolloProvider client={client}>
          <GoogleAnalyticsProvider>
            <UserProvider>
              <CartProvider>
                <Router>
                  <ScrollToTop />
                  <Header />
                  <Analytics id={process.env.REACT_APP_GA_ID} debug>
                    <Switch>
                      {/* <Route exact path="/" component={Login} /> */}
                      <Route exact path="/" component={Home} />
                      <Route exact path="/date" component={DatePicker} />
                      <Route exact path="/faq" component={Faq} />
                      <Route exact path="/about" component={About} />
                      <Route exact path="/account" component={UserAccount} />
                      <Route
                        exact
                        path="/shop/:handle"
                        component={GiftCardDetail}
                      />
                      <Route
                        exact
                        path="/:city/:handle"
                        component={BuildingDetail}
                      />
                      <Route
                        exact
                        path="/:city"
                        component={ProductsByCollection}
                      />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </Analytics>
                  <Auth0SignUp />
                  <Footer />
                </Router>
                <ToastAlert />
              </CartProvider>
            </UserProvider>
          </GoogleAnalyticsProvider>
        </ApolloProvider>
        <Hotjar />
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default App;
