import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { CgClose } from "react-icons/cg";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import axios from "src/axios";
import { recordAnalyticsEvent } from "src/utils/Analytics";
import ParkingSwitch from "./ParkingSwitch";

function DefaultSection({
  memberInfo,
  deleteMember,
  disabled,
  selectMember,
  index,
  checked,
  setChecked,
  buildingInfo,
  selectedMembers,
  parkingVariants,
  buildingAnalyticsBody,
  parkingDisabled,
}) {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [parkingType, setParkingType] = useState(null);

  useEffect(() => {
    if (!checked) setParkingType("NO_PARKING");
  }, [checked]);

  useEffect(() => {
    const ifMemberAlreadyPresent = selectedMembers.filter(
      ({ email }) => email === memberInfo.email
    );
    console.log(ifMemberAlreadyPresent.length);
    if (
      ifMemberAlreadyPresent.length === 1 &&
      ifMemberAlreadyPresent[0].hasParking
    ) {
      console.log(ifMemberAlreadyPresent[0].parkingType);
      setParkingType(ifMemberAlreadyPresent[0].parkingType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DeleteTeamMember = async () => {
    const body = {
      memberEmail: memberInfo.email,
    };
    const token = await getAccessTokenSilently();
    try {
      await axios.patch(`/customers/delete-member`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      deleteMember(memberInfo);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckbox = () => {
    setChecked(!checked);
    selectMember({ ...memberInfo, hasParking: false }, !checked);
  };
  const handleParking = (parkingOption) => {
    let memberObj;
    if (parkingOption !== "NO_PARKING") {
      const variantSelection = parkingVariants.find(
        ({ title }) => title === parkingOption
      );
      if (variantSelection) {
        memberObj = {
          ...memberInfo,
          hasParking: true,
          parkingType: parkingOption,
          variantId: variantSelection.id,
          oldVariantId: variantSelection.oldId,
          price: variantSelection.price,
        };
        recordAnalyticsEvent("Added Parking To Cart", {
          ...buildingAnalyticsBody,
          "Parking confirmation": "YES",
          "Parking type": parkingOption,
        });
        recordAnalyticsEvent("Added To Cart", {
          ...buildingAnalyticsBody,
          "Product Type": "Parking",
          "Parking confirmation": "YES",
          "Parking type": parkingOption,
        });
      }
    } else {
      memberObj = { ...memberInfo, hasParking: false };
    }
    selectMember(memberObj, checked);
  };
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.defaultContainer}
    >
      <Grid item xs={10} md={6}>
        <div className="ray-checkbox">
          <input
            id={`checkbox-${memberInfo.id}`}
            name="checkbox-button-story"
            type="checkbox"
            checked={checked}
            className="ray-checkbox__input"
            onChange={handleCheckbox}
            disabled={disabled}
          />
          <label
            className="ray-checkbox__label"
            htmlFor={`checkbox-${memberInfo.id}`}
          >
            {index === 901022 ? `${memberInfo.name} (You)` : memberInfo.name}
          </label>
        </div>
      </Grid>
      <Hidden smUp>
        <Grid item xs={2}>
          <button
            onClick={DeleteTeamMember}
            className={`ray-button ray-button--tertiary ray-button--compact ${
              classes.deleteBtn
            }`}
            disabled={disabled}
          >
            <CgClose />
          </button>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={4}>
          <span
            style={{ color: "#B3B3B3", marginBottom: 0 }}
            className="ray-text--body-x-small"
          >
            Add Parking
          </span>
        </Grid>
      </Hidden>
      <Grid item xs={4} sm={1}>
        <ParkingSwitch
          selectedParkingType={parkingType}
          setParkingType={setParkingType}
          handleParking={handleParking}
          disabled={!checked || disabled || parkingDisabled}
        />
      </Grid>
      <Grid style={{ textAlign: "center" }} item xs={4} md={3}>
        {parkingType === "Car"
          ? `+ ₹200/day`
          : parkingType === "Bike"
          ? `+ ₹75/day`
          : null}
      </Grid>
      <Hidden smDown>
        <Grid item xs={2}>
          <button
            onClick={DeleteTeamMember}
            className={`ray-button ray-button--tertiary ray-button--compact ${
              classes.deleteBtn
            }`}
            disabled={disabled}
          >
            <CgClose />
          </button>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default DefaultSection;

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    float: "right",
  },
  accountPageEmail: {
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    marginBottom: 0,
  },
  accountPageName: {
    display: "-webkit-box",
    maxWidth: "100%",
    height: "auto",
    WebkitLineClamp: 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 0,
  },
  mobileEmail: {
    color: "#737373",
    marginBottom: 0,
  },
  defaultContainer: {
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid #E3E3E3",
      padding: "1em !important",
    },
  },
}));
