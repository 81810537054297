import React, { createContext, useReducer, useEffect } from "react";
import { useAuthContext } from "./AuthContext";

const CartContext = createContext();

const initialState = {
  items: {},
  globalQuantity: 0,
  bookingForSelf: true,
  teamMembers: [],
  conferences: [{}],
  checkoutURL: null,
  draftOrderBody: null,
  invoiceScreenDetails: {
    prevStep: null,
    originalPrice: 0,
    totalPrice: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM": {
      let id = action.payload.id;
      return {
        ...state,
        items: { ...state.items, [id]: action.payload },
      };
    }
    case "REMOVE_ITEM": {
      const { [action.payload]: value, ...remaining } = state.items;
      return {
        ...state,
        items: remaining,
      };
    }
    case "SET_GLOBAL_QUANTITY": {
      return {
        ...state,
        globalQuantity: action.payload,
      };
    }
    case "INCREASE_GLOBAL_QUANTITY": {
      return {
        ...state,
        globalQuantity: state.globalQuantity + 1,
      };
    }
    case "DECREASE_GLOBAL_QUANTITY": {
      if (state.globalQuantity === 1) return { ...state };
      return {
        ...state,
        globalQuantity: state.globalQuantity - 1,
      };
    }
    case "SET_TEAM_MEMBERS": {
      return {
        ...state,
        teamMembers: action.payload,
      };
    }
    case "SET_CONFERENCES": {
      return {
        ...state,
        conferences: action.payload,
      };
    }
    case "SET_INVOICE_SCREEN_DETAILS": {
      return {
        ...state,
        invoiceScreenDetails: action.payload,
      };
    }
    case "TOGGLE_BOOKING_FOR_SELF": {
      return {
        ...state,
        bookingForSelf: !state.bookingForSelf,
      };
    }
    case "CLEAR_CART": {
      return {
        ...state,
        items: {},
        globalQuantity: 0,
      };
    }
    case "GET_CHECKOUT_URL": {
      return {
        ...state,
        checkoutURL: action.payload,
      };
    }
    case "REMOVE_CHECKOUT_URL": {
      return {
        ...state,
        checkoutURL: null,
      };
    }
    default:
      return Error("Invalid action type");
  }
};

const CartProvider = ({ children }) => {
  const [cartState, cartDispatch] = useReducer(reducer, initialState);

  const { userInfo } = useAuthContext();

  useEffect(() => {
    const teamMembers = cartState.teamMembers;
    if (userInfo && userInfo.email) {
      if (teamMembers && teamMembers.length > 0) {
        const teamMemberEmails = teamMembers.map(({ email }) => email);
        if (!teamMemberEmails.includes(userInfo.email)) {
          cartDispatch({ type: "SET_TEAM_MEMBERS", payload: teamMembers.concat([userInfo]) });
          cartDispatch({ type: "SET_GLOBAL_QUANTITY", payload: teamMembers.length + 1 });
        }
      } else {
        cartDispatch({ type: "SET_TEAM_MEMBERS", payload: [userInfo] });
        cartDispatch({ type: "SET_GLOBAL_QUANTITY", payload: 1 });
      }
    } else {
      cartDispatch({ type: "SET_TEAM_MEMBERS", payload: [] });
      cartDispatch({ type: "SET_GLOBAL_QUANTITY", payload: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <CartContext.Provider value={{ cartState, cartDispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
