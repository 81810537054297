import axios from "src/axios";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const fetchBookingStartTimes = async (
  teamMemberCount,
  bookingDuration,
  dateOfBooking,
  bookingBuilding,
  getAccessToken,
) => {
  try {
    const body = {
      buildingId: bookingBuilding,
      noOfPeople: teamMemberCount,
      noOfHrs: bookingDuration,
      conferenceDate: dateOfBooking,
    };
    const token = await getAccessToken();
    const response = await axios.post(`/shop/get-available-times-customer`, body, {
      headers: { Authorization: `Bearer ${token}`, buildingHandle: bookingBuilding },
    });
    return { success: true, items: response.data };
  } catch (error) {
    console.error(error);
    return { success: false, items: [] };
  }
};

export const debouncedFetchingOfDates = AwesomeDebouncePromise(
  fetchBookingStartTimes,
  700
);
