import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
  loop: true,
  margin: 10,
  items: 2,
  center: true,
  dots: false,
  responsive: {
    1024: {
      nav: true,
      navText: [`<p class="next-prev"> < </p>`, `<p class="next-prev"> > </p>`],
    },
    600: {
      dots: false,
    },
    0: {
      items: 2,
      center: false,
      margin: -60,
    },
  },
};
const MyCarousel = ({ images }) => (
  <div className="carousel">
    <OwlCarousel className="owl-theme" {...options}>
      {React.Children.toArray(
        images.map((image, index) => (
          <div className="ray-image ray-image--16by9 white-background">
            <div className="item" key={index}>
              <img src={image} alt="" />
            </div>
          </div>
        ))
      )}
    </OwlCarousel>
  </div>
);

export default MyCarousel;
