import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthContext } from "src/components/context/AuthContext";
import logo from "../../assets/images/logo.png";
import phnLogo from "../../assets/images/phnLogo.svg";
import "./Header.scss";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { recordAnalyticsEvent } from "src/utils/Analytics";

const Header = () => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { creditSystemActive } = useAuthContext();

  useEffect(() => {
    sidebarOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [sidebarOpen]);

  const eventRecorder = (property) => {
    recordAnalyticsEvent("Clicked Navigation Bar", {
      "Viewed URL": window.location.href,
      "Telephone Link": property,
    });
  };

  return (
    <>
      {creditSystemActive && (
        <header className={classes.itemWrapper}>
          <small className="ray-text--body-small">
            Your Virtual Office credits will be applied at checkout*
          </small>
        </header>
      )}
      <div>
        <Sidebar
          closeSidebar={() => setSidebarOpen(false)}
          sidebarOpen={sidebarOpen}
        />

        <div
          onClick={() => setSidebarOpen(false)}
          className={sidebarOpen ? "blur" : ""}
          id="background-blur"
        />
        <div className="ray-page-container header-container">
          <div className="ray-grid">
            <div className="logo-col ray-grid__cell--span-10-desktop ray-grid__cell--span-6-tablet ray-grid__cell--span-2-phone">
              <Link to="/">
                <img
                  className="wework-logo"
                  src={logo}
                  alt="wework logo"
                  onClick={() => eventRecorder("NO")}
                />
              </Link>
            </div>
            <div className="right-col ray-grid__cell--span-1-desktop ray-grid__cell--span-1-tablet ray-grid__cell--span-1-phone">
              <a href="tel:1800123999000">
                {" "}
                <img
                  src={phnLogo}
                  alt=""
                  onClick={() => eventRecorder("YES")}
                />
              </a>
            </div>
            <div className="right-col ray-grid__cell--span-1-desktop ray-grid__cell--span-1-tablet ray-grid__cell--span-1-phone">
              <div
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                  recordAnalyticsEvent("Navigation Menu Clicked");
                }}
                className={sidebarOpen ? "open" : ""}
                id="menu-toggle"
              >
                <button
                  className={
                    sidebarOpen
                      ? "hamburger hamburger--squeeze is-active"
                      : "hamburger hamburger--squeeze"
                  }
                  id="hamburgur"
                  type="button"
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    position: "sticky",
    top: 0,
    textAlign: "center",
    backgroundColor: "#0000FF",
    color: "#fff",
    zIndex: "999",
    padding: "0.5em",
  },
}));
