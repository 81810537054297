import React from "react";
import spinner from "../assets/images/spinner.gif";

const Spinner = ({ positionAbsolute }) => {
  return (
    <div>
      <div
        className={
          positionAbsolute
            ? "d-flex justify-content-center align-items-center fixed-height"
            : "d-flex justify-content-center align-items-center loading-spinner"
        }
      >
        <img style={{ maxWidth: 160 }} src={spinner} alt="" />
      </div>
    </div>
  );
};

export default Spinner;
