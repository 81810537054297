export const recordAnalyticsEvent = (eventAction, additionalProperties) => {
  // Accessing KMQ variable by initialiazing Kissmetrics Script inside index.html
  const _kmq = window._kmq;
  if (additionalProperties && Object.keys(additionalProperties).length > 0) {
    _kmq.push(["record", eventAction, additionalProperties]);
    return;
  }
  _kmq.push(["record", `${eventAction}`]);
  return;
};
export const setAnalyticsEvent = (additionalProperties) => {
  // Accessing KMQ variable by initialiazing Analytics Script inside index.html
  const _kmq = window._kmq;
  if (additionalProperties && Object.keys(additionalProperties).length > 0) {
    _kmq.push(["set", additionalProperties]);
    return;
  }
  return;
};

export const identifyAnalyticsEvent = (emailAddress) => {
  // Accessing KMQ variable by initialiazing Analytics Script inside index.html
  const _kmq = window._kmq;
  _kmq.push(["identify", emailAddress]);
  return;
};

export const clearIdentity = () => {
  // Accessing KMQ variable by initialiazing Analytics Script inside index.html
  const _kmq = window._kmq;
  _kmq.push(["clearIdentity"]);
  return;
};
