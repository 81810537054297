import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { capitalizeText } from 'src/utils/Functions';
import { recordAnalyticsEvent } from 'src/utils/Analytics';

const SingleCityCard = ({ city }) => {
  useEffect(() => {
    ReactPixel.pageView();
  });

  return (
    <div className="ray-card individual-card building-carousel-card">
      <div className="ray-card__image ray-image ray-image--16by9">
        {city.imageSrc && <img src={city.imageSrc} alt="card graphic" />}
      </div>

      <div className="ray-card__content card-details building-carousel-card-details">
        <div className="ray-card__heading card-product-title">{city.name}</div>

        <div className="card-description individual-card-description">
          {city.noOfBuildings}{' '}
          {city.noOfBuildings > 1 ? 'buildings' : 'building'}
        </div>
        <div className="btn-btn">
          <Link to={`/${city.city_handle}`}>
            <button
              className="ray-button ray-button--primary card-btn individual-card-btn"
              onClick={() =>
                recordAnalyticsEvent('Clicked Homepage', {
                  'City name': capitalizeText(city.name),
                })
              }
            >
              Explore City
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SingleCityCard;
