import React, { createContext, useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useMutation, gql } from "@apollo/client";
import { CartContext } from "../context/CartContext";
import { useAuthContext } from "../context/AuthContext";
import Spinner from "../Spinner";
import "./MultiStageCheckout.scss";

const CREATE_CHECKOUT = gql`
  mutation CreateCheckout($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
        order {
          orderNumber
          statusUrl
        }
      }
    }
  }
`;

const CheckoutContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
});

const MultiStageCheckout = ({ open, child, toggleCheckoutModal, children }) => {
  const { cartState } = useContext(CartContext);
  const isTabletOrAbove = useMediaQuery("(min-width: 600px)");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useAuthContext();

  const [createCheckout] = useMutation(CREATE_CHECKOUT, {
    onCompleted: (data) =>
      window.open(data.checkoutCreate.checkout.webUrl, "_self"),
    onError: (error) => {
      console.error("Something went wrong!", error);
      setIsLoading(false);
    },
  });

  const initiateCheckout = (membersStringified, conferenceCheckout = false) => {
    const checkoutCreateInput = {
      lineItems: [],
    };

    Object.keys(cartState.items).forEach((item) => {
      checkoutCreateInput.lineItems.push({
        variantId: cartState.items[item].id,
        quantity: cartState.globalQuantity,
      });
    });

    checkoutCreateInput.note = `GSTIN: ${userInfo.gstin}`;

    checkoutCreateInput.customAttributes = [
      {
        key: "teamMembers",
        value: membersStringified,
      },
      {
        key: "orderCreator",
        value: JSON.stringify({ email: userInfo.email, name: userInfo.name }),
      },
    ];

    setIsLoading(true);
    if (conferenceCheckout) return checkoutCreateInput;

    createCheckout({ variables: { input: checkoutCreateInput } });
  };

  return (
    <CheckoutContext.Provider
      value={{
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        initiateCheckout: initiateCheckout,
      }}
    >
      <Dialog
        fullScreen={!isTabletOrAbove}
        maxWidth="md"
        open={open}
        onClose={toggleCheckoutModal}
        positionAbsolute
        fullWidth
      >
        <DialogContent>
          {isLoading ? <Spinner positionAbsolute /> : children}
        </DialogContent>
      </Dialog>
    </CheckoutContext.Provider>
  );
};

export default MultiStageCheckout;
export { CheckoutContext };

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);
