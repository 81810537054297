import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../components/context/AuthContext";
import axios from "../../axios";
import { recordAnalyticsEvent } from "src/utils/Analytics";

function ProfileSection() {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const { userInfo, showToast, setUserInfo } = useAuthContext();

  const [formError, setFormError] = useState(null);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: userInfo,
  });

  useEffect(() => {
    if (userInfo && userInfo.email) reset(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const onSubmit = (data) => {
    console.log(data);
    setLoader(true);
    updateUser(data);
  };
  const updateUser = async (data) => {
    setLoader(true);
    let body = data;
    const token = await getAccessTokenSilently();
    try {
      await axios.post(`/customers/update`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFormError(null);
      setLoader(false);
      showToast(true, "success", "Details Updated Successfully");
      setUserInfo({
        ...userInfo,
        name: data.name,
        phone: data.phone,
        gstin: data.gstin,
      });
    } catch (error) {
      console.error(error.response);
      setFormError(error.response.data);
      setLoader(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.profileContainer}
    >
      <div className="ray-grid">
        <div className="ray-grid__cell--span-full">
          <h2 className={`ray-text--h2 ${classes.textMb}`}>Profile Details</h2>
        </div>
        <div className={`ray-grid__cell--span-7 ${classes.textFieldSpace}`}>
          <div className={`ray-text-field`}>
            <input
              className="ray-text-field__input"
              id="email-user"
              type="email"
              placeholder="tom@wework.co.in"
              disabled
              name="email"
              ref={register({ required: true })}
            />
            <label className="ray-text-field__label" htmlFor="email-user">
              Email Address*
            </label>
          </div>
          {errors.email && (
            <small className={classes.fieldError}>This field is required</small>
          )}
        </div>
        <div className={`ray-grid__cell--span-7 ${classes.textFieldSpace}`}>
          <div className={`ray-text-field`}>
            <input
              className="ray-text-field__input"
              id="user-fname"
              type="text"
              placeholder="John Doe"
              name="name"
              ref={register({ required: true })}
            />
            <label className="ray-text-field__label" htmlFor="user-fname">
              Full Name*
            </label>
          </div>
          {errors.name && (
            <small className={classes.fieldError}>This field is required</small>
          )}
        </div>
        <div className={`ray-grid__cell--span-7 ${classes.textFieldSpace}`}>
          <div className={`ray-text-field`}>
            <input
              className="ray-text-field__input"
              id="phone-user"
              type="tel"
              placeholder="9999999999"
              name="phone"
              ref={register({
                minLength: {
                  value: 10,
                  message: "Enter a valid 10 digit Phone",
                },
                maxLength: {
                  value: 10,
                  message: "Enter a valid 10 digit Phone",
                },
                pattern: {
                  value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                  message: "Enter a Valid Phone",
                },
              })}
            />
            <label className="ray-text-field__label" htmlFor="phone-user">
              Phone Number
            </label>
          </div>
          {errors.phone && (
            <small className={classes.fieldError}>{errors.phone.message}</small>
          )}
        </div>
        <div className={`ray-grid__cell--span-7 ${classes.textFieldSpace}`}>
          <div className={`ray-text-field`}>
            <input
              className="ray-text-field__input"
              id="gstin-user"
              type="text"
              placeholder="22AAAAA0000A1Z5"
              name="gstin"
              ref={register}
            />
            <label className="ray-text-field__label" htmlFor="gstin-user">
              GSTIN
            </label>
          </div>
        </div>
        {formError && (
          <div className={`ray-grid__cell--span-12 ${classes.helpTextMb}`}>
            <p className={classes.fieldError}>
              {formError && formError.message
                ? formError.message
                : "Error occurred while updating user"}
            </p>
          </div>
        )}
        <div className="ray-grid__cell--span-6">
          <button
            className={`ray-button ray-button--primary ${classes.registerBtn}`}
            type="submit"
            disabled={loader}
            onClick={() => recordAnalyticsEvent("Save Clicked")}
          >
            {loader ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default ProfileSection;

const useStyles = makeStyles({
  subtitles: {
    color: "#B3B3B3",
  },
  centerText: {
    textAlign: "center",
  },
  headingMargin: {
    margin: "2em",
  },
  signUpContainer: {
    height: "100%",
    width: "100%",
  },
  headerMb0: {
    marginBottom: 0,
  },
  textMb: {
    marginBottom: "1em",
  },
  textFieldSpace: {
    marginBottom: "1em",
  },
  registerBtn: {
    marginTop: "1.5em",
    marginBottom: "0.5em",
    width: "100%",
  },
  helpTextMb: {
    marginBottom: "0.5em",
  },
  linkSpan: {
    color: "#0000FF",
    cursor: "pointer",
  },
  fieldError: {
    color: "#CC0000",
  },
  profileContainer: {
    width: "100%",
  },
});
