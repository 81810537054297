import React, { useEffect } from "react";
import "./Faq.scss";
import ReactPixel from "react-facebook-pixel";


const Faq = () => {

  useEffect(() => {
    ReactPixel.pageView();
  })

  return (
    <div className="faq">
      <div className="ray-page-container">
        <span className="ray-text--h2">FAQ:</span>
        <br />
        <div className="question-answer">
          <h4 className="ray-text--h4">How do I sign up for a day-pass?</h4>
          <div className="ray-text--body">
            To sign up : <br />
            <ul className="ray-list">
              <li>Select your preferred building</li>
              <li>Click "Buy a day pass"</li>
              <li>Select your preferred date(s)</li>
              <li>Complete the checkout process</li>
            </ul>
            <p>
              You will receive an email confirmation once your booking has been
              completed.
            </p>
          </div>
          <br />
          <h4 className="ray-text--h4">
            How do I access the on-demand workspaces?
          </h4>
          <div className="ray-text--body">
            When you arrive at the building, just check in with a member of our
            Community Team. They will get you set up with a keycard for all
            future reservations – don’t forget to carry the required documents
            which will be listed on an email that’ll be sent to you after you
            buy a day-pass. Workspace can be accessed from 9am-6pm. We request
            you to carry your own essentials. (cutleries, coffee mugs).
          </div>
          <br />
          <h4 className="ray-text--h4">What you need when you check in?</h4>
          <div className="ray-text--body">
            Please keep the following points in mind when you arrive at WeWork.
            <br />
            <ul className="ray-list">
              <li>
                Download the Aarogya Setu app before reaching your new
                workspace. Access will be denied to visitors and members if the
                Aarogya Setu App shows ‘high’ or ‘moderate’ risk.
              </li>
              <li>
                Keep a government-issued ID. You’ll have to show it to our
                Community Team for identity verification.
              </li>
              <li>
                Our Community Team will ask you to show this confirmation email
                once you arrive, followed by a complete self-declaration.
              </li>
              <li>Wearing a mask is mandatory.</li>
            </ul>
          </div>
          <br />
          <h4 className="ray-text--h4">Can I bring guests?</h4>
          <div className="ray-text--body">
            Guests are only permitted for those booking conference rooms (up to
            the quantity of the respective conference room).
          </div>
          <br />
          <h4 className="ray-text--h4">How do I modify or cancel a booking?</h4>
          <div className="ray-text--body">
            Once you have purchased a day-pass, it cannot be refunded, modified or
            cancelled. Please select the dates and location carefully, while you
            are buying a day-pass. (Please reach out to
            <a href="mailto:bookspace@wework.co.in"> bookspace@wework.co.in </a>
            )
          </div>
          <br />
          <h4 className="ray-text--h4">
            Can I book spaces or rooms outside of these 6 on-demand workspaces?
          </h4>
          <div className="ray-text--body">
            At this time, you can only buy a day-pass for the listed 6
            locations. Stay tuned as additional locations become available.
          </div>
          <br />
          <h4 className="ray-text--h4">How can I print?</h4>
          <div className="ray-text--body">
            A member from the Community Team can help you get your prints.
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Faq;
