import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { CartContext } from "src/components/context/CartContext";
import BannerImage from "src/assets/images/memberConference.jpeg";
import { recordAnalyticsEvent } from "src/utils/Analytics";

function ConferenceSection({
  changeStepper,
  disabled,
  selectedMembers,
  checkoutPrice,
  building,
}) {
  const classes = useStyles();
  const { cartState, cartDispatch } = useContext(CartContext);

  const proceedToConferenceStep = () => {
    if (cartState.globalQuantity === selectedMembers.length) {
      cartDispatch({
        type: "SET_INVOICE_SCREEN_DETAILS",
        payload: {
          prevStep: "ADD_MEMBER_STEP",
          originalPrice: checkoutPrice.normal,
          totalPrice: checkoutPrice.discounted,
        },
      });
      changeStepper("CONFERENCE_ROOM_STEP");
      recordAnalyticsEvent("Clicked Next Step", {
        "Present Step": "ADD MEMBER STEP",
        "Previous Step": "DATE PICKER",
        "Next Step": "CONFERENCE ROOM STEP",
      });
    }
  };

  return (
    <Grid container alignItems="center" justify="center" spacing={1}>
      <Hidden smDown>
        <Grid item xs={6}>
          <div
            style={{ backgroundImage: `url(${BannerImage})` }}
            className={classes.bannerImg}
          />
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        md={6}
        container
        spacing={2}
        justify="flex-end"
        alignItems="center"
        className={classes.infoSection}
      >
        <Grid item xs={12}>
          <div class="ray-text--body-large">
            Add a conference room to your booking
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="ray-text--body">
            Planning a quick huddle or a board meeting? There’s plenty of room
            to fit in your needs.
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <button
            onClick={proceedToConferenceStep}
            className={`ray-button ray-button--primary ray-button--compact ${
              classes.proceedButton
            }`}
            disabled={disabled || building.conferenceRoomDisabled}
          >
            <div
              className={
                building.conferenceRoomDisabled
                  ? "conf-disabled"
                  : "conf-enabled"
              }
            >
              {building.conferenceRoomDisabled ? (
                <span>Coming Soon</span>
              ) : (
                <>
                  <b>Add Now</b> <span>from ₹250/hr</span>
                </>
              )}
            </div>
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConferenceSection;

const useStyles = makeStyles((theme) => ({
  proceedButton: {
    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    "& .conf-enabled": {
      justifyContent: "space-between",
    },
    "& .conf-disabled": {
      justifyContent: "center",
    },
    width: "100%",
  },
  headingMargin: {
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: "1em",
    },
  },
  infoSection: {
    minHeight: "15em",
    backgroundColor: "#F3F6F9",
  },
  greyText: {
    color: "#737373",
  },

  loaderParent: {
    textAlign: "center",
    "& svg": {
      color: "#0000FF",
    },
  },
  gridPadding: {
    padding: "1.5em",
  },
  buttonRow: {
    padding: "1em",
  },
  fullWidthBtn: {
    width: "100%",
  },
  bannerImg: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "15em",
    width: "100%",
  },
}));
