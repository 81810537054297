import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: process.env.REACT_APP_SHOPIFY_STOREFRONT_API_ENDPOINT,
  cache: new InMemoryCache(),
  headers: {
    "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_SHOPIFY_STOREFRONT_API_TOKEN,
  },
});

export default client;
