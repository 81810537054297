import { gql } from "@apollo/client";

export const FETCH_CONFERENCE_ROOM_VARIANT = gql`
  query getProductByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      descriptionHtml
      productType
      handle
      variants(first: 100) {
        edges {
          node {
            id
            title
            price
          }
        }
      }
    }
  }
`;
