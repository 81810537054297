const stringifyPrice = (price) => {
  price = Math.round(price);
  price = price.toString();
  price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  price = "₹" + price;
  return price;
};

const graphqlIdToRestId = (id) => {
  const arrLength = atob(id).split("/").length;
  const restId = atob(id).split("/")[arrLength - 1];
  return restId;
};

const capitalizeText = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
export { stringifyPrice, graphqlIdToRestId, capitalizeText };
