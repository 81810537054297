import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { stringifyPrice } from 'src/utils/Functions';
import ReactPixel from 'react-facebook-pixel';
import { recordAnalyticsEvent } from 'src/utils/Analytics';

const BuildingCard = ({ name, handle, city, imageSrc, id }) => {
  useEffect(() => {
    ReactPixel.pageView();
  });
  return (
    <div className="ray-card individual-card">
      <div className="ray-card__image ray-image ray-image--16by9">
        {imageSrc && <img src={imageSrc} alt="card graphic" />}
      </div>

      <div className="ray-card__content card-details">
        <div className="ray-card__heading card-product-title">{name}</div>
        <div className="btn-btn">
          <Link to={`/${city}/${handle}`}>
            <button
              className="ray-button ray-button--primary card-btn individual-card-btn"
              onClick={() =>
                recordAnalyticsEvent('Clicked PLP Building', {
                  'Building ID': id,
                  'Building Price': 500,
                  'Building Discount': 'YES',
                  'Building Name': name,
                })
              }
            >
              Book Now at {stringifyPrice(500)}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BuildingCard;
