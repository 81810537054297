import React, { useState, useEffect } from "react";
import DefaultSection from "./DefaultSection";
import FormSection from "./FormSection";

function MemberItem({
  memberInfo,
  index,
  deleteMember,
  updateExistingMember,
  selectMember,
  selectedMembers,
  globalQuantity,
  buildingInfo,
  parkingVariants,
  setMemberInEditState,
  memberInEditState,
  buildingAnalyticsBody,
  parkingDisabled
}) {
  const [editState, toggleEditState] = useState(memberInfo.id ? false : true);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectedMembers) {
      const selectedMembersEmails = selectedMembers.map(({ email }) => email);
      if (selectedMembersEmails.includes(memberInfo.email)) {
        setDisabled(false);
        setChecked(true);
      }
      // if (selectedMembers.length === globalQuantity) {
      //   if (!selectedMembersEmails.includes(memberInfo.email)) {
      //     setDisabled(true);
      //     toggleEditState(false);
      //     setChecked(false);
      //   }
      // } else {
      //   setDisabled(false);
      // }
    } else {
      setDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMembers]);

  useEffect(() => {
    if (editState)
      setMemberInEditState({ selectedIndex: index, disabled: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState]);

  useEffect(() => {
    console.log(memberInEditState);
    if (memberInEditState.disabled) {
      if (memberInEditState.selectedIndex !== index) setDisabled(true);
    } else {
      setDisabled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberInEditState]);
  return (
    <>
      {editState ? (
        <FormSection
          index={index}
          memberInfo={memberInfo}
          toggleEditState={toggleEditState}
          deleteMember={deleteMember}
          updateExistingMember={updateExistingMember}
          disabled={disabled}
          setMemberInEditState={setMemberInEditState}
          checked={checked}
          setChecked={setChecked}
          selectMember={selectMember}
        />
      ) : (
        <DefaultSection
          memberInfo={memberInfo}
          disabled={disabled}
          deleteMember={deleteMember}
          selectMember={selectMember}
          index={index}
          checked={checked}
          setChecked={setChecked}
          buildingInfo={buildingInfo}
          selectedMembers={selectedMembers}
          parkingVariants={parkingVariants}
          buildingAnalyticsBody={buildingAnalyticsBody}
          parkingDisabled={parkingDisabled}
        />
      )}
    </>
  );
}

export default MemberItem;
