import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function BookingItem({ bookingInfo }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h4 className="ray-text--h3">
        {bookingInfo && bookingInfo.locationName
          ? bookingInfo.locationName
          : "Location Not Available"}
      </h4>
      <div className={`ray-text--body ${classes.secondaryColor}`}>
        {bookingInfo && bookingInfo.dateString
          ? bookingInfo.dateString
          : "Date Not Available"}
      </div>
      <div className={`ray-text--body-small ${classes.secondaryColor}`}>
        {bookingInfo && bookingInfo.teamMembers
          ? `${bookingInfo.teamMembers.length} ${
              bookingInfo.teamMembers.length === 1 ? "member" : "members"
            } `
          : "0 members"}
      </div>
    </div>
  );
}

export default BookingItem;

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "start",
    padding: "1em",
    marginBottom: "1em",
    backgroundColor: "#e3e3e3",
    borderRadius: "5px",
  },
  secondaryColor: {
    color: "#666666",
  },
});
