import React from "react";
import "./Location.scss";


const HomeFloatingButton = () => {
  return (
      <a href="#cities" className="floating-btn-ref">
    <button className="ray-button ray-button--primary home-float-btn">
      Explore Our Locations
    </button>
    </a>
  );
};

export default HomeFloatingButton;