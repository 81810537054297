import React from 'react';
import styled from 'styled-components';

const QuantityEditor = ({ quantity, onDecrement, onIncrement, ...rest }) => {
  return (
    <StyledWrapper {...rest}>
      <div className="ray-chip ray-chip-for-festive-page">
        <div
          className="quantity-edit-button ray-text--h3"
          onClick={onDecrement}
        >
          -
        </div>
        <p className="ray-text--h4">{quantity}</p>
        <div
          className="quantity-edit-button ray-text--h3"
          onClick={onIncrement}
        >
          +
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  div.ray-chip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;

    &:hover {
      background-color: unset;
    }

    p {
      width: 30%;
      text-align: center;
      margin-bottom: 0;
    }

    .quantity-edit-button {
      margin: 0 !important;
      min-width: 35%;
      text-align: center;
    }
  }
`;

export default QuantityEditor;