import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { setHours, setMinutes, format as formatTime } from "date-fns";

function TimePill({ timeSlot, setStartTime, selectedStartTime }) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const [isoDate, setIsoDate] = useState();

  useEffect(() => {
    const minutes = Number(timeSlot.slice(2));
    const hours = Number(timeSlot.slice(0, 2));
    setIsoDate(setHours(setMinutes(new Date(), minutes), hours));
  }, [timeSlot]);

  useEffect(() => {
    if (
      selectedStartTime &&
      isoDate &&
      selectedStartTime === isoDate
    )
      setChecked(true);
    else setChecked(false);
  }, [selectedStartTime, isoDate]);

  const handleChange = () => {
    if (!checked) {
        console.log(isoDate);
      setStartTime(isoDate ? isoDate : null);
    } else setStartTime(null);
    setChecked(!checked);
  };
  return (
    <div
      onClick={handleChange}
      className={`${classes.root} ${checked ? classes.selected : ""}`}
    >
      <span className="ray-text--body">
        {timeSlot && isoDate ? formatTime(isoDate, "h:mm a") : "--"}
      </span>
    </div>
  );
}
export default TimePill;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid #E3E3E3",
      borderRadius: "4px",
      padding: "0.5em",
      width: "15%",
      marginRight: "0.5em",
      marginTop: "0.5em",
      "&:hover": {
        cursor: "pointer",
        border: "2px solid #0000FF",
      },
      "& span":{
          marginBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: '45%'
      },
    },
    selected: {
      border: "2px solid #0000FF",
    },
    priceText: {
      color: "#858585",
    },
  })
);
