import React, { createContext, useEffect } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

const GoogleAnalyticsCtx = createContext(undefined);

const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GA_ID;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

export const GoogleAnalyticsProvider = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_KEY, { debug: false });
    TagManager.initialize(tagManagerArgs);
  }, []);

  const sendDataToGoogleAnalytics = (hitType, eventCategory, eventAction) => {
    ReactGA.send({ hitType, eventCategory, eventAction });
    return;
  };
  return (
    <GoogleAnalyticsCtx.Provider
      value={{
        sendDataToGoogleAnalytics,
      }}
    >
      {children}
    </GoogleAnalyticsCtx.Provider>
  );
};
export const GoogleAnalyticsConsumer = GoogleAnalyticsCtx.Consumer;

export default GoogleAnalyticsCtx;
