import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

function SelectInput({
  id = `native_Select${new Date().toTimeString()}`,
  value,
  label,
  onChange,
  name,
  inputRef,
  className,
  placeholder,
  disabled = false,
  options = [],
  fullWidth = false,
}) {
  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        native
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        fullWidth={fullWidth}
        placeholder={placeholder}
        inputRef={inputRef}
        disabled={disabled}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        <option aria-label="None" value="" />
        {options.length > 0 &&
          React.Children.toArray(
            options.map((option) => (
              <option selected={option.selected} value={option.value}>{option.name}</option>
            ))
          )}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
