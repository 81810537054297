import React, { useState } from "react";
import DefaultSection from "./DefaultSection";
import FormSection from "./FormSection";

function MemberSectionItem({
  memberInfo,
  index,
  deleteMember,
  updateExistingMember,
}) {
  const [editState, toggleEditState] = useState(memberInfo.id ? false : true);
  return (
    <>
      {editState ? (
        <FormSection
          index={index}
          memberInfo={memberInfo}
          toggleEditState={toggleEditState}
          deleteMember={deleteMember}
          updateExistingMember={updateExistingMember}
        />
      ) : (
        <DefaultSection memberInfo={memberInfo} deleteMember={deleteMember} />
      )}
    </>
  );
}

export default MemberSectionItem;
