export const CITY_COORDINATES = {
  mumbai: { lat: 19.076, lng: 72.8777 },
  bengaluru: { lat: 12.9716, lng: 77.5946 },
  gurugram: { lat: 28.4595, lng: 77.0266 },
  hyderabad: { lat: 17.385, lng: 78.4867 },
  noida: { lat: 28.5355, lng: 77.391 },
  pune: { lat: 18.5204, lng: 73.8567 },
};

export const CITIES_LIST = [
  { name: 'Bengaluru', handle: 'bengaluru' },
  { name: 'Gurugram', handle: 'gurugram' },
  { name: 'Mumbai', handle: 'mumbai' },
  { name: 'Noida', handle: 'noida' },
  { name: 'Hyderabad', handle: 'hyderabad' },
  { name: 'Pune', handle: 'Pune' },
];
