import React from "react";
import placeholder from "../../assets/images/placeholder.jpg";
import HomeFloatingButton from "./HomeFloatingButton";
import { recordAnalyticsEvent } from "src/utils/Analytics";

const HomeIntro = () => {
  return (
    <div className="intro-location">
      <div className="hero-section">
        <img className="hero-img" onClick={() => recordAnalyticsEvent("Homepage Hero Clicked")} src={placeholder} alt="Hero" />
      </div>

      <div className="hero-text-container">
        <div className="ray-grid__cell--span-10-desktop ray-grid__cell--full-tablet get-back-work">
          <h1 className="ray-text--h1 buy-now-heading">
            Workspaces at the tap of a button at WeWork
            </h1>
          <div className="ray-text--body-large textbox-for-desktop">
            Step out of home to a safe and inspiring workspace at WeWork. With
            a day pass at Rs. 500, WeWork gives you premium amenities like
            meeting rooms, high speed WiFi, and a creative environment that
            empowers you to focus and create your best work.
            </div>
          <div className="ray-text--body-large textbox-for-mobile">
            Step out of home to a safe and inspiring workspace at WeWork, with a day pass at Rs.500.
            </div>
          <HomeFloatingButton />
        </div>
      </div>
    </div>
  );
};

export default HomeIntro;
