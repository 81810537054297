import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { firebase } from "../../firebase";
import Grid from "@material-ui/core/Grid";
import { useAuthContext } from "../../components/context/AuthContext";
import { recordAnalyticsEvent } from "src/utils/Analytics";
// import { CartContext } from "../../components/context/CartContext";

const ERROR_MESSAGES_FIREBASE = {
  "auth/invalid-email": "Please enter a valid email address",
  "auth/user-not-found":
    "We couldn’t find a user with that email address. Please create an account.",
  "auth/wrong-password":
    "The email/password entered was incorrect. Please try again.",
};

function LoginStep({ changeStepper, sidebar, closeSidebarAuth }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [error, setError] = useState(null);

  const { showToast } = useAuthContext();
  // const { cartState } = useContext(CartContext);

  const loginUser = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    try {
      const request = await firebase.doSignInWithEmailAndPassword(
        email,
        password
      );
      const { user } = request;
      if (user) {
        if (sidebar) {
          closeSidebarAuth(false);
        } else {
          // if (cartState.bookingForSelf && cartState.globalQuantity === 1) {
          //   changeStepper("DATE_PICKER");
          // } else {
          //   changeStepper("ADD_MEMBER_STEP");
          // }
          changeStepper("ADD_MEMBER_STEP");
        }
        showToast(true, "success", "Logged in Successfully");
        setBtnDisabled(false);
      }
    } catch (error) {
      setError(null);
      console.error(error);
      setBtnDisabled(false);
      showToast(true, "error", ERROR_MESSAGES_FIREBASE[error.code]);
      recordAnalyticsEvent("Authentication Failure", {
        Error: ERROR_MESSAGES_FIREBASE[error.code],
      });
    }
  };

  const resetPassword = async () => {
    if (email.length < 3) {
      showToast(true, "error", ERROR_MESSAGES_FIREBASE["auth/invalid-email"]);
    } else {
      try {
        await firebase.resetPasswordWithEmail(email);
        showToast(
          true,
          "success",
          `A password reset link has been sent to ${email}`
        );
      } catch (e) {
        console.error(e);
        showToast(true, "error", ERROR_MESSAGES_FIREBASE[e.code]);
      }
    }
  };
  return (
    <Grid container alignItems="center" justify="center" spacing={2}>
      <Grid item md={sidebar ? 12 : 9} xs={12}>
        <form onSubmit={loginUser} className={classes.loginAutoContainer}>
          <div className={`ray-grid ${classes.gridJustify}`}>
            <div className="ray-grid__cell--span-full">
              <h2 className={`ray-text--h2 ${classes.headerMb0}`}>Log In</h2>
              <div className={`ray-text--body ${classes.textMb}`}>
                Don't have an account?{" "}
                <span
                  className={classes.linkSpan}
                  onClick={() =>
                    sidebar
                      ? changeStepper("SIGNUP_SECTION")
                      : changeStepper("CREATE_USER_STEP")
                  }
                >
                  Register
                </span>
              </div>
            </div>
            <div className="ray-grid__cell--span-9">
              <div className={`ray-text-field ${classes.textFieldSpace}`}>
                <input
                  className="ray-text-field__input"
                  id="email"
                  type="email"
                  placeholder="tony@wework.co.in"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="ray-text-field__label" for="email">
                  Email address
                </label>
              </div>
            </div>
            <div className="ray-grid__cell--span-9">
              <div className={`ray-text-field`}>
                <input
                  className="ray-text-field__input"
                  id="password-tf"
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label className="ray-text-field__label" for="password-tf">
                  Password
                </label>
              </div>
              <small
                onClick={resetPassword}
                className={`ray-text--x-small ${classes.linkSpan} ${
                  classes.textFieldSpace
                }`}
              >
                Forgot your Password?
              </small>
            </div>
            <div className="ray-grid__cell--span-4-phone ray-grid__cell--span-8-tablet ray-grid__cell--span-7-desktop">
              <div className={classes.buttonContainer}>
                <button
                  className={`ray-button ray-button--primary ${
                    classes.loginBtn
                  }`}
                  disabled={btnDisabled}
                  type="submit"
                >
                  {!btnDisabled ? "Log in" : "Loading..."}
                </button>
                {error && <p className={classes.errorText}>{error}</p>}
                {/* <div className="ray-text--body">OR</div>
            <div>
              <button
                disabled
                className={`ray-button ray-button--primary ${
                  classes.socialBtn
                }`}
              >
                Log in with Google
              </button>
              <button
                disabled
                className={`ray-button ray-button--primary ${
                  classes.socialBtn
                }`}
              >
                Log in with Facebook
              </button>
            </div> */}
              </div>
            </div>
            <div className="ray-grid__cell--span-4-phone ray-grid__cell--span-8-tablet ray-grid__cell--span-7-desktop">
              <button
                className={`ray-button ray-button--tertiary ${
                  classes.loginBtn
                }`}
                onClick={() =>
                  sidebar
                    ? closeSidebarAuth(false)
                    : changeStepper("DATE_PICKER")
                }
              >
                {sidebar ? "Cancel" : "Go Back"}
              </button>
            </div>
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default LoginStep;

const useStyles = makeStyles((theme) => ({
  gridJustify: {
    justifyContent: "center",
  },
  loginContainer: {
    height: "100%",
    width: "640px",
    padding: "2em",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
      width: "100%",
    },
  },
  loginAutoContainer: {
    height: "100%",
    padding: "2em",
    [theme.breakpoints.down("sm")]: {
      padding: "1em",
      width: "100%",
    },
  },
  headerMb0: {
    marginBottom: 0,
  },
  textMb: {
    marginBottom: "1em",
  },
  textFieldSpace: {
    marginBottom: "1em",
  },
  loginBtn: {
    marginTop: "1.5em",
    marginBottom: "0.5em",
    width: "100%",
    marginRight: 0,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  socialBtn: {
    width: "100%",
    marginBottom: "0.5em",
  },
  linkSpan: {
    color: "#0000FF",
    cursor: "pointer",
  },
  errorText: {
    color: "",
    marginBottom: "0.5em",
  },
}));
