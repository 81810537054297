import React from 'react';
import './CityCard.scss';
import SingleCityCard from './SingleCityCard';

const CityCards = ({ city }) => {
  return (
    <div
      className="city-card ray-grid__cell--span-3-desktop ray-grid__cell--span-4-tablet ray-grid__cell--span-4-phone"
      style={{ display: `${city.noOfBuildings === 0 ? 'none' : 'block'}` }}
    >
      <SingleCityCard city={city} />
    </div>
  );
};

export default CityCards;
