import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuthContext } from "src/components/context/AuthContext";
import MemberItem from "./MemberItem";
import { CartContext } from "src/components/context/CartContext";
import { stringifyPrice, graphqlIdToRestId } from "src/utils/Functions";
import ConferenceSection from "./ConferenceSection";
import { FETCH_CONFERENCE_ROOM_VARIANT } from "../ConferenceRoomStep/FetchConfRoomVariant";
import { useQuery } from "@apollo/client";
import { recordAnalyticsEvent } from "src/utils/Analytics";
import { AiFillInfoCircle } from "react-icons/ai";

const PARKING_HANDLE = "parking";

function MembersSection({ changeStepper, building, buildingAnalyticsBody }) {
  const classes = useStyles();

  const {
    userInfo,
    userTeamMembers,
    setUserTeamMembers,
    membersLoader,
    showToast,
  } = useAuthContext();
  const { cartState, cartDispatch } = useContext(CartContext);

  const [checkoutLoader, setCheckoutLoader] = useState(false);

  const [parkingVariants, setParkingVariants] = useState([]);

  const [memberInEditState, setMemberInEditState] = useState({
    selectedIndex: -1,
    disabled: false,
  });

  const [checkoutPrice, setCheckoutPrice] = useState({
    discounted: 0,
    normal: 0,
  });

  const cartTeamMembers = cartState.teamMembers;

  const { loading: variantLoader, error: variantError, data } = useQuery(
    FETCH_CONFERENCE_ROOM_VARIANT,
    {
      variables: { handle: PARKING_HANDLE },
      onCompleted: () => {
        if (data.productByHandle) {
          const variantsForParking = data.productByHandle.variants.edges.map(
            ({ node }) => {
              const newId = graphqlIdToRestId(node.id);
              return {
                id: Number(newId),
                oldId: node.id,
                title: node.title,
                price: node.price,
              };
            }
          );
          setParkingVariants(variantsForParking);
        }
      },
    }
  );

  const setGlobalQuantity = (payload) => {
    cartDispatch({
      type: "SET_GLOBAL_QUANTITY",
      payload: payload,
    });
  };

  useEffect(() => {
    if (cartState && cartState.items) {
      const cartStateItems = Object.keys(cartState.items);
      const dayPassTotal =
        cartStateItems.length * cartState.globalQuantity * 1000;
      const discountedDayPassTotal =
        cartStateItems.length * cartState.globalQuantity * 500;
      let parkingPrice = 0;
      if (cartTeamMembers.length > 0) {
        cartTeamMembers.forEach((teamMember) => {
          if (Object.keys(teamMember).length !== 0) {
            const memberPrice = teamMember.hasParking
              ? Number(teamMember.price) * cartStateItems.length
              : 0;
            parkingPrice += memberPrice;
          }
        });
      }
      setCheckoutPrice({
        normal: dayPassTotal + parkingPrice,
        discounted: discountedDayPassTotal + parkingPrice,
      });
    }
  }, [cartState, cartTeamMembers]);

  const setCtxTeamMembers = (teamData) => {
    cartDispatch({ type: "SET_TEAM_MEMBERS", payload: teamData });
  };

  const memberSelection = (item, checked) => {
    if (checked) {
      const presentMembersCtx = cartTeamMembers.filter(
        ({ email }) => item.email === email
      );
      if (presentMembersCtx.length) {
        setCtxTeamMembers(
          cartTeamMembers
            .filter(({ email }) => item.email !== email)
            .concat([item])
        );
      } else {
        setGlobalQuantity(cartTeamMembers.length + 1);
        setCtxTeamMembers(cartTeamMembers.concat([item]));
        recordAnalyticsEvent("Added Team Member To Cart", {
          ...buildingAnalyticsBody,
          "Team member confirmation": "YES",
          "Team member Name": item.name,
        });
      }
    } else {
      const arr = cartTeamMembers.filter((member) => member.id !== item.id);
      setGlobalQuantity(arr.length);
      setCtxTeamMembers(arr);
      recordAnalyticsEvent("Removed Team Member from Cart", {
        ...buildingAnalyticsBody,
        "Team member confirmation": "NO",
        "Team member Name": item.name,
      });
    }
  };

  const updateMember = (memberInfo, index) => {
    const oldMembers = userTeamMembers;
    oldMembers[index] = { ...memberInfo };
    setUserTeamMembers([...oldMembers]);
  };

  const appendMember = () => {
    setUserTeamMembers(userTeamMembers.concat([{}]));
  };
  const deleteMember = (member) => {
    const filteredSelectedMembers = cartTeamMembers.filter(
      (mem) => mem !== member
    );
    setUserTeamMembers(userTeamMembers.filter((mem) => mem !== member));
    setGlobalQuantity(filteredSelectedMembers.length);
    setCtxTeamMembers(filteredSelectedMembers);
  };

  const triggerCheckout = async () => {
    setCheckoutLoader(true);
    try {
      cartDispatch({
        type: "SET_INVOICE_SCREEN_DETAILS",
        payload: {
          prevStep: "ADD_MEMBER_STEP",
          originalPrice: checkoutPrice.normal,
          totalPrice: checkoutPrice.discounted,
        },
      });
      console.log(checkoutPrice);
      setCheckoutLoader(false);
      changeStepper("INVOICE_STEP");
      recordAnalyticsEvent("Clicked Next Step", {
        "Present Step": "ADD MEMBER STEP",
        "Previous Step": "DATE PICKER",
        "Next Step": "SUMMARY STEP",
      });
    } catch (error) {
      console.error(error);
      showToast(true, "error", error.response.data.message);
      setCheckoutLoader(false);
    }
  };
  const changeSection = () => {
    cartDispatch({
      type: "SET_INVOICE_SCREEN_DETAILS",
      payload: {
        prevStep: "ADD_MEMBER_STEP",
        originalPrice: checkoutPrice.normal,
        totalPrice: checkoutPrice.discounted,
      },
    });
    console.log(checkoutPrice);
    changeStepper("DATE_PICKER");
  };

  const getDisableCondition = () =>
    cartState.globalQuantity === 0 ? true : false;
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      spacing={3}
    >
      <Grid item xs={12} container spacing={0}>
        <Grid item xs={12} container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <h3
              className={`ray-text--h3 ${
                building.parkingDisabled ? classes.buildingTitle : ""
              }`}
            >{`Day Pass at ${building.name}`}</h3>
          </Grid>
          {building.parkingDisabled && (
            <Grid item xs={12}>
              <div className={classes.noParkingBanner}>
                <AiFillInfoCircle />
                <span className="ray-text--body-small">
                  Parking can be purchased at the building
                </span>
              </div>
            </Grid>
          )}
          <Grid item sm={5} xs={12}>
            <p className={`ray-text--body-small ${classes.greyText}`}>
              Team Member
            </p>
          </Grid>
          <Hidden smDown>
            <Grid style={{ textAlign: "center" }} item sm={3} xs={5}>
              <p className={`ray-text--body-small ${classes.greyText}`}>
                Add Parking
              </p>
            </Grid>
          </Hidden>
          {(membersLoader || variantLoader) && (
            <Grid item xs={12} className={classes.loaderParent}>
              <CircularProgress size={25} />
            </Grid>
          )}
          {!(membersLoader || variantLoader || variantError) &&
            userInfo &&
            userInfo.auth0Id && (
              <MemberItem
                index={901022}
                memberInfo={userInfo}
                selectMember={memberSelection}
                selectedMembers={cartTeamMembers}
                globalQuantity={cartState.globalQuantity}
                checkedDefault={cartState.bookingForSelf}
                parkingVariants={parkingVariants}
                memberInEditState={memberInEditState}
                setMemberInEditState={setMemberInEditState}
                buildingAnalyticsBody={buildingAnalyticsBody}
                parkingDisabled={building.parkingDisabled}
              />
            )}
          {!(membersLoader || variantLoader) &&
            userTeamMembers.length > 0 &&
            React.Children.toArray(
              userTeamMembers.map((item, index) => (
                <MemberItem
                  index={index}
                  memberInfo={item}
                  deleteMember={deleteMember}
                  updateExistingMember={updateMember}
                  selectMember={memberSelection}
                  selectedMembers={cartTeamMembers}
                  globalQuantity={cartState.globalQuantity}
                  buildingInfo={building}
                  parkingVariants={parkingVariants}
                  memberInEditState={memberInEditState}
                  setMemberInEditState={setMemberInEditState}
                  buildingAnalyticsBody={buildingAnalyticsBody}
                  parkingDisabled={building.parkingDisabled}
                />
              ))
            )}
          <Grid item xs={12} sm={4} md={3} className={classes.addMemberMT}>
            <button
              onClick={appendMember}
              disabled={memberInEditState.disabled}
              className={`ray-button ray-button--secondary ray-button--compact`}
            >
              + Add Member
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ConferenceSection
          changeStepper={changeStepper}
          disabled={getDisableCondition() || memberInEditState.disabled}
          selectedMembers={cartTeamMembers}
          checkoutPrice={checkoutPrice}
          building={building}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={classes.buttonRow}
      >
        <Hidden smDown>
          <Grid item xs={2}>
            <button
              className={`ray-button ray-button--tertiary ${
                classes.fullWidthBtn
              }`}
              onClick={changeSection}
              disabled={memberInEditState.disabled}
            >
              Back
            </button>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={4}>
          <button
            className={`ray-button ray-button--secondary ${
              classes.fullWidthBtn
            }`}
            onClick={triggerCheckout}
            disabled={
              getDisableCondition() ||
              checkoutLoader ||
              memberInEditState.disabled
            }
          >
            {checkoutLoader ? (
              <CircularProgress size={15} className={classes.whiteColor} />
            ) : (
              <>
                <span>Skip & Pay </span>
                <span className="strikethrough">
                  {`${stringifyPrice(checkoutPrice.normal)}`}
                </span>
                <span>{` ${stringifyPrice(checkoutPrice.discounted)}`}</span>
              </>
            )}
          </button>
        </Grid>

        <Hidden smUp>
          <Grid item xs={12}>
            <button
              className={`ray-button ray-button--tertiary ${
                classes.fullWidthBtn
              }`}
              onClick={changeSection}
            >
              Back
            </button>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}

export default MembersSection;

const useStyles = makeStyles((theme) => ({
  headingMargin: {
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: "1em",
    },
  },
  addMemberMT: {
    marginTop: "1em",
    "& button": {
      width: "100%",
    },
  },
  greyText: {
    color: "#737373",
  },

  loaderParent: {
    textAlign: "center",
    "& svg": {
      color: "#0000FF",
    },
  },
  gridPadding: {
    padding: "1.5em",
  },
  buttonRow: {
    padding: "1em",
  },
  fullWidthBtn: {
    width: "100%",
  },
  parkingNoteText: {
    textAlign: "right",
    alignSelf: "flex-end",
  },
  noParkingBanner: {
    backgroundColor: "#F3F6F9",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0.7em",
    borderRadius: "5px",
    "& span": {
      marginBottom: 0,
    },
    "& svg": {
      fontSize: "1.5em",
      marginRight: "0.5em",
    },
  },
  buildingTitle: {
    marginBottom: 0,
  },
}));
