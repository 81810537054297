import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Marker, InfoWindow } from "@react-google-maps/api";
import LocationMarker from "../../assets/images/buildingLocation.svg";
import PinMarker from "../../assets/images/userLocation.svg";
import { BiLinkExternal } from "react-icons/bi";
import { recordAnalyticsEvent } from "src/utils/Analytics";

const GOOGLE_MAPS_REDIRECT_URL = (latitude, longitude, placeId) =>
  `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&query_place_id=${placeId}`;

const onLoad = (infoBox) => {
  console.log("infoBox: ", infoBox);
};

const PLACEHOLDER_IMG =
  "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081";

function MarkerWithInfoBox({
  center,
  coordinates,
  label = "",
  userMarker = false,
  city = "",
  titleImage,
  selectedPopup,
  setSelectedPopup,
  googlePlaceId = "",
}) {
  const classes = useStyles();
  const [showInfoBox, toggleInfoBox] = useState(false);

  useEffect(() => {
    if (selectedPopup && selectedPopup !== label) {
      toggleInfoBox(false);
    }
  }, [selectedPopup, label]);
  const openUrl = () => {
    window
      .open(
        GOOGLE_MAPS_REDIRECT_URL(
          coordinates.lat,
          coordinates.lng,
          googlePlaceId
        ),
        "_blank"
      )
      .focus();
  };
  const handleInfoBox = () => {
    if (!showInfoBox) {
      setSelectedPopup(label);
      recordAnalyticsEvent("Clicked PLP Change View", { "Pin on Map": label });
    } else setSelectedPopup("");
    toggleInfoBox(!showInfoBox);
  };
  return (
    <Marker
      onClick={handleInfoBox}
      position={coordinates}
      icon={userMarker ? PinMarker : LocationMarker}
    >
      {showInfoBox && (
        <InfoWindow onLoad={onLoad} onCloseClick={handleInfoBox}>
          <div className={classes.root}>
            {titleImage && (
              <div
                style={{
                  backgroundImage: `url(${
                    titleImage !== "" ? titleImage : PLACEHOLDER_IMG
                  })`,
                }}
                className={classes.bkgImg}
              />
            )}
            <div className={classes.textArea}>
              <h5 className="ray-text--h5">{label}</h5>
              <div className={classes.infoArea}>
                <span className="ray-text--body-small">{city}</span>
                <div className={classes.openMap}>
                  <span
                    onClick={openUrl}
                    className="ray-text--body-small google-map-link"
                  >
                    Open in Google Maps
                  </span>
                  <BiLinkExternal onClick={openUrl} />
                </div>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}

export default MarkerWithInfoBox;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "5px",
    // boxShadow: "0px 0px 6px 0px rgba(50, 50, 50, 0.8)",
    // WebkitBoxShadow:"0px 0px 6px 0px rgba(50, 50, 50, 0.8)",
    color: "#000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflow: "hidden",
    width: "100%",
  },
  bkgImg: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "10em",
    width: "100%",
  },
  textArea: {
    padding: "0.8em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& span": {
      color: "#A9A9A9",
      textTransform: "capitalize",
      marginBottom: 0,
    },
  },
  infoArea: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    "& span": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9em",
      },
    },
  },
  openMap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& .google-map-link": {
      color: "#0000FF",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    "& svg": {
      color: "#0000FF",
      fontSize: "1.3em",
      marginLeft: "0.3em",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
}));
