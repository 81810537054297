import React from "react";
import cleaning from "../../assets/images/cleaning.svg";
import internet from "../../assets/images/internet.svg";
import parking from "../../assets/images/parking.svg";
import connect from "../../assets/images/connect.svg";
import support from "../../assets/images/support.svg";
import "./Snippet.scss";


const WhyWeWork = () => {
  return (
      <div className="day-pass">
      <div className="ray-page-container">
        <div className="ray-text--h2">Why WeWork?</div>
        <div className="ray-grid">
         <div className="ray-grid__cell why-wework-list"> <img className="why-wework-icon" src={internet} alt=""/> High-speed Wi-Fi </div>
          <div className="ray-grid__cell why-wework-list"><img className="why-wework-icon" src={cleaning} alt=""/> Frequent sanitisation and cleaning </div>
          <div className="ray-grid__cell why-wework-list"><img className="why-wework-icon" src={connect} alt=""/> Connect with members </div>
          <div className="ray-grid__cell why-wework-list"><img className="why-wework-icon" src={support} alt=""/> Onsite community support </div>
          <div className="ray-grid__cell why-wework-list"><img className="why-wework-icon" src={parking} alt=""/> Onsite parking </div>
        </div>
        </div>
        </div>
  );
};

export default WhyWeWork;
