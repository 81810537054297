import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { BiCalendar, BiUser } from "react-icons/bi";
import { IoMdTime } from "react-icons/io";
import { format, addHours } from "date-fns";
import { stringifyPrice } from "src/utils/Functions";

function DefaultSection({
  conferenceInfo,
  toggleEditState,
  deleteConference,
  deleteLoader,
  index,
}) {
  const classes = useStyles();
  const [endTime, setEndTime] = useState();
  useEffect(() => {
    if (conferenceInfo && conferenceInfo.startTime && conferenceInfo.noOfHrs) {
      const computedEndTime = addHours(
        conferenceInfo.startTime,
        Number(conferenceInfo.noOfHrs)
      );
      setEndTime(computedEndTime);
    }
  }, [conferenceInfo]);
  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      justify="space-between"
      alignItems="center"
      className={classes.conferenceItemContainer}
    >
      {/* <Grid item xs={8} md={1}>
        <span className="ray-text--body-small">{`Meeting ${
          conferenceInfo && conferenceInfo.id > -1
            ? conferenceInfo.id + 1
            : "--"
        }`}</span>
      </Grid> */}
      <Grid item xs={7} md={3}>
        <div className={`${classes.iconWrapper} ${classes.leftAlignDate}`}>
          <BiCalendar />
          <span className="ray-text--body-small">
            {conferenceInfo && conferenceInfo.bookingDate
              ? format(conferenceInfo.bookingDate, "do MMM y")
              : "---"}
          </span>
        </div>
      </Grid>
      <Grid item xs={3} md={1}>
        <div className={classes.iconWrapper}>
          <BiUser />
          <span className="ray-text--body-small">
            {conferenceInfo && conferenceInfo.memberCount
              ? conferenceInfo.memberCount
              : "---"}
          </span>
        </div>
      </Grid>
      <Hidden smUp>
        <Grid item xs={2}>
          <button
            onClick={() => toggleEditState(index)}
            className="ray-button ray-button--tertiary ray-button--compact"
          >
            Edit
          </button>
        </Grid>
      </Hidden>
      <Grid item xs={7} md={3}>
        <div className={classes.iconWrapper}>
          <IoMdTime />
          <span className="ray-text--body-small">
            {conferenceInfo &&
            conferenceInfo.noOfHrs &&
            conferenceInfo.startTime &&
            endTime
              ? `${format(conferenceInfo.startTime, "hh:mm aa")} - ${format(
                  endTime,
                  "hh:mm aa"
                )}`
              : "--"}
          </span>
        </div>
      </Grid>
      <Grid item xs={5} md={2}>
        <div className={classes.iconWrapper}>
          <span className="ray-text--body-small">
            {conferenceInfo && conferenceInfo.price
              ? stringifyPrice(conferenceInfo.price)
              : "--"}
          </span>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid item xs={6} md={1}>
          <button
            onClick={() => toggleEditState(index)}
            className="ray-button ray-button--tertiary ray-button--compact"
          >
            Edit
          </button>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default DefaultSection;

const useStyles = makeStyles((theme) => ({
  formBackground: {
    backgroundColor: "#F3F6F9",
    padding: "1.5em",
  },
  conferenceItemContainer: {
    borderBottom: "1px solid",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    "& span": {
      marginBottom: 0,
    },
    "& svg": {
      fontSize: "1.3em",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  leftAlignDate:{
    justifyContent: "flex-start",
  },
  conferenceItem: {
    borderBottom: "1px solid",
  },
}));
