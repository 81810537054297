import app from "firebase/app";
import "firebase/auth";
const configDev = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    const config =
      process.env.NODE_ENV === "development"
        ? configDev
        : JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    app.initializeApp(config);
    // app.analytics();
    this.auth = app.auth();
  }
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  resetPasswordWithEmail = (email) =>
    this.auth.sendPasswordResetEmail(email, {
      url: `${process.env.REACT_APP_URL}`,
    });

  doSignOut = () => this.auth.signOut();
}

const firebase = new Firebase();

export { firebase };
