import { gql } from '@apollo/client';

export const GET_BUILDING_DETAILS = gql`
  query getProductByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      descriptionHtml
      productType
      handle
      variants(first: 100) {
        edges {
          node {
            id
            title
            price
            availableForSale
          }
        }
      }
    }
  }
`;
