import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debouncedFetchingOfDates } from "./FetchTimes";
import axios from "src/axios";
import { useAuthContext } from "src/components/context/AuthContext";
import RayNumInput from "src/reuseComponents/RayInputs/RayNumInput";
import RaySelectInput from "src/reuseComponents/RayInputs/SelectInput";
import TimePill from "./TimePill";
import RoomCapacity from "./RoomCapacity";
import { FaPhoneAlt } from "react-icons/fa";
import { stringifyPrice } from "src/utils/Functions";
import { recordAnalyticsEvent } from "src/utils/Analytics";

function ConferenceForm({
  toggleEditState,
  conferenceInfo,
  index,
  updateExistingConference,
  deleteConference,
  building,
  variantDetails,
  cartState,
  phoneNumMetafield,
  deleteLoader,
}) {
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();

  const { showToast } = useAuthContext();

  const [bookingDate, setBookingDate] = useState();

  const [noOfHrs, setNoOfHrs] = useState(1);
  const [memberCount, setMemberCount] = useState(0);

  const [selectedStartTime, setSelectedStartTime] = useState();

  const [loader, setLoader] = useState(false);

  const [availableTimes, setAvailableTimes] = useState([]);
  const [availableTimeLoader, setAvailableTimeLoader] = useState(false);
  const [availableTimesError, setAvailableTimesError] = useState("");

  const [availableRoomsCapacity, setAvailableRoomsCapacity] = useState([]);
  const [
    availableRoomCapacityLoader,
    setAvailableRoomCapacityLoader,
  ] = useState(false);
  const [availableRoomCapacityError, setAvailableRoomCapacityError] = useState(
    false
  );

  const [totalPrice, setTotalPrice] = useState(stringifyPrice(0));

  useEffect(() => {
    if (cartState.items && Object.keys(cartState.items).length > 0) {
      setBookingDate(Object.values(cartState.items)[0].date.toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conferenceInfo && conferenceInfo.reservationId) {
      setSelectedStartTime(conferenceInfo.startTime);
      setMemberCount(conferenceInfo.memberCount);
      setNoOfHrs(conferenceInfo.noOfHrs);
      setBookingDate(conferenceInfo.bookingDate.toISOString());
    }
  }, [conferenceInfo]);

  useEffect(() => {
    if (cartState && cartState.globalQuantity > 2)
      setMemberCount(cartState.globalQuantity);
  }, [cartState]);

  useEffect(() => {
    if (noOfHrs > 0 && memberCount > 0 && bookingDate && building) {
      setAvailableTimes([]);
      fetchAvailableDates(memberCount, noOfHrs, bookingDate, building.id);
      setSelectedStartTime(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfHrs, memberCount, bookingDate, building]);

  useEffect(() => {
    if (building?.id) {
      fetchConferenceRoomsCapacity(building.id);
    }
  }, [building]);

  const createReservation = async () => {
    setLoader(true);
    try {
      const body = {
        booking_start_time: selectedStartTime,
        booking_date: bookingDate,
        no_of_hrs: noOfHrs,
        member_count: memberCount,
        buildingId: building.id,
      };
      const token = await getAccessTokenSilently();
      const response = await axios.post(`/shop/check-for-booking`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const reservationId = response.data.reservationId;
      const conferenceId = response.data.conferenceRoomId;
      updateExistingConference(
        {
          startTime: selectedStartTime,
          bookingDate: new Date(bookingDate),
          memberCount: memberCount,
          noOfHrs: noOfHrs,
          id: index,
          oldVariantId: variantDetails.oldId,
          variantId: variantDetails.id,
          price: memberCount * noOfHrs * variantDetails.price,
          reservationId,
          conferenceId,
        },
        index
      );
      setLoader(false);
      toggleEditState(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
      const errorMsg = error.response.data.message;
      showToast(true, "error", errorMsg);
      if (errorMsg === "Slot Requested not Available") {
        setAvailableTimes([]);
        setAvailableTimesError("Requested Time Slot not available");
        setSelectedStartTime(undefined);
      }
    }
  };
  const updateReservation = async () => {
    setLoader(true);
    const body = {
      booking_start_time: selectedStartTime,
      booking_date: bookingDate,
      no_of_hrs: noOfHrs,
      member_count: memberCount,
      buildingId: building.id,
      reservation_id: conferenceInfo.reservationId,
      conference_room_id: conferenceInfo.conferenceId,
    };
    const token = await getAccessTokenSilently();
    try {
      const response = await axios.post(`/shop/update-reservation`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const reservationId = response.data.reservationId;
      const conferenceId = response.data.conferenceRoomId;
      updateExistingConference(
        {
          startTime: selectedStartTime,
          bookingDate: new Date(bookingDate),
          memberCount: memberCount,
          noOfHrs: noOfHrs,
          id: index,
          variantId: variantDetails.id,
          oldVariantId: variantDetails.oldId,
          price: memberCount * noOfHrs * variantDetails.price,
          reservationId,
          conferenceId,
        },
        index
      );
      toggleEditState(false);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
      const errorMsg = error.response.data.message;
      showToast(true, "error", errorMsg);
    }
  };
  const handleStartDate = (e) => {
    setBookingDate(e.target.value !== "" ? e.target.value : undefined);
  };
  const fetchAvailableDates = async (
    teamMemberCount,
    bookingDuration,
    dateOfBooking,
    bookingBuilding
  ) => {
    setAvailableTimeLoader(true);

    const timesResponse = await debouncedFetchingOfDates(
      teamMemberCount,
      bookingDuration,
      dateOfBooking,
      bookingBuilding,
      getAccessTokenSilently
    );
    setAvailableTimes(timesResponse.items);
    if (timesResponse.items.length === 0)
      setAvailableTimesError("Sorry, we don’t have any slots available.");
    else setAvailableTimesError("");
    setAvailableTimeLoader(false);
  };
  const fetchConferenceRoomsCapacity = async (buildingId) => {
    setAvailableRoomCapacityLoader(true);
    try {
      const response = await axios.get("/shop/get-available-conference-rooms", {
        params: { buildingId: buildingId },
      });
      const roomsCapacity = response.data.conferenceRooms;
      setAvailableRoomsCapacity(roomsCapacity);
      setMemberCount(roomsCapacity[0]); // Set smallest seater value as default
      setAvailableRoomCapacityLoader(false);
    } catch (error) {
      console.error(error);
      setAvailableRoomCapacityLoader(false);
      setAvailableRoomCapacityError(true);
      setAvailableTimeLoader(false);
      setAvailableTimesError(
        "Sorry, we couldn't find rooms in this building that match your criteria"
      );
    }
  };
  const handleStartTime = (e) => {
    setSelectedStartTime(e);
  };
  const handleRoomCapacityChange = (e) => {
    setMemberCount(e);
  };
  useEffect(() => {
    if (variantDetails && variantDetails.price && memberCount && noOfHrs)
      setTotalPrice(
        stringifyPrice(variantDetails.price * memberCount * noOfHrs)
      );
    else setTotalPrice(stringifyPrice(0));
  }, [variantDetails, memberCount, noOfHrs]);

  return (
    <>
      <Grid item xs={12}>
        <h3 className="ray-text--h3">{`Meeting ${index + 1}`}</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid className={classes.formBackground} container spacing={2}>
          <Grid container className={classes.addMarginBottom} spacing={3}>
            <Grid item xs={12} md={6}>
              <RaySelectInput
                fullWidth
                className={classes.fullWidthBtn}
                name="select_building"
                label="Date"
                placeholder="Select Date"
                value={bookingDate}
                onChange={handleStartDate}
                options={
                  cartState.items && Object.keys(cartState.items).length > 0
                    ? Object.values(cartState.items).map((x, index) => ({
                        name: x.date.format("dddd, Do MMMM YYYY"),
                        value: x.date.toISOString(),
                        selected: index === 0 ? true : false,
                      }))
                    : []
                }
                disabled={
                  Object.keys(cartState.items).length === 0 ||
                  Object.keys(cartState.items).length === 1
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RayNumInput
                id={`no_of_hrs_${index}`}
                name="noOfHrs"
                placeholder="Enter No. of Hrs"
                label="No. of Hrs."
                value={noOfHrs}
                className={[classes.transparentBkg, classes.fullWidthBtn]}
                fullWidth
                increment={() => {
                  if (!(noOfHrs + 1 > 9)) setNoOfHrs(noOfHrs + 1);
                }}
                decrement={() => {
                  if (!(noOfHrs - 1 < 1)) setNoOfHrs(noOfHrs - 1);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.divider}>
            {!availableRoomCapacityLoader &&
              !availableRoomCapacityError &&
              availableRoomsCapacity &&
              availableRoomsCapacity.map((capacity, index) => (
                <RoomCapacity
                  key={index}
                  capacity={capacity}
                  memberCount={memberCount}
                  setRoomCapacity={handleRoomCapacityChange}
                />
              ))}
          </Grid>
          {
            <Grid item xs={12} md={3} className={classes.addMarginTop}>
              {!availableTimeLoader && availableTimes.length > 0 && (
                <span style={{ marginBottom: 0 }} className="ray-text--body">
                  {" "}
                  Starting at:
                </span>
              )}
            </Grid>
          }
          <Grid className={classes.startTimesWrapper} item xs={12}>
            {!availableTimeLoader &&
              availableTimes.length > 0 &&
              React.Children.toArray(
                availableTimes
                  .sort()
                  .map((timeslot) => (
                    <TimePill
                      timeSlot={timeslot}
                      selectedStartTime={selectedStartTime}
                      setStartTime={handleStartTime}
                    />
                  ))
              )}
            {availableTimeLoader && (
              <Grid className={classes.startTimesWrapper} item xs={12}>
                <CircularProgress size={25} className={classes.loaderColor} />
              </Grid>
            )}
            {!availableTimeLoader &&
              availableTimesError &&
              availableTimes.length === 0 && (
                <Grid className={classes.noTimesWrapper} item xs={12}>
                  <p
                    style={{ marginBottom: 0 }}
                    className={`ray-text--body-small`}
                  >
                    Sorry, we couldn’t find any available slots that match your
                    criteria.
                  </p>
                  <p className={`ray-text--body-small`}>
                    Please contact us and our community team at{" "}
                    {building.name.split(",")[0]} will help book a slot for you.
                  </p>
                  <a
                    className={`ray-button ray-button--primary ray-button--compact ${
                      classes.callBtnWrapper
                    }`}
                    href={`tel:${phoneNumMetafield}`}
                    onClick={() =>
                      recordAnalyticsEvent("Checkout Call Us Clicked")
                    }
                  >
                    <FaPhoneAlt />{" "}
                    <span className="ray-text--body-small">Call us at</span>{" "}
                    {` ${phoneNumMetafield}`}
                  </a>
                </Grid>
              )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            className={classes.addMarginTop}
          >
            <Grid item xs={5} md={3}>
              <h4
                className={`ray-text--h4 ${classes.totalPriceHeading} ${
                  classes.marginBottomZero
                }`}
              >{`Price ${totalPrice}`}</h4>
            </Grid>
            {conferenceInfo.reservationId ? (
              <Grid item xs={12} md={4} container spacing={2}>
                <Grid item xs={5}>
                  <button
                    onClick={() => deleteConference(conferenceInfo)}
                    className={`ray-button ray-button--tertiary ray-button--compact ${
                      classes.fullWidthBtn
                    }`}
                    disabled={loader || deleteLoader}
                  >
                    Delete
                  </button>
                </Grid>
                <Grid item xs={7}>
                  <button
                    onClick={updateReservation}
                    className={`ray-button ray-button--primary ray-button--compact ${
                      classes.fullWidthBtn
                    }`}
                    disabled={
                      selectedStartTime === undefined || loader || deleteLoader
                    }
                  >
                    {loader ? (
                      <CircularProgress
                        size={25}
                        className={classes.loaderColor}
                      />
                    ) : (
                      <div className={classes.addBtnRow}>
                        <span>Update</span>
                      </div>
                    )}
                  </button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={5} md={2}>
                <button
                  onClick={createReservation}
                  className={`ray-button ray-button--primary ray-button--compact ${
                    classes.fullWidthBtn
                  }`}
                  disabled={
                    selectedStartTime === undefined ||
                    bookingDate === undefined ||
                    loader
                  }
                >
                  {loader ? (
                    <CircularProgress
                      size={25}
                      className={classes.loaderColor}
                    />
                  ) : (
                    <div className={classes.addBtnRow}>
                      <span>Add</span>
                    </div>
                  )}
                </button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ConferenceForm;

const useStyles = makeStyles((theme) => ({
  formBackground: {
    backgroundColor: "#F3F6F9",
    padding: "1.5em",
  },
  startTimesWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& p": {
      color: "#990000",
    },
    marginBottom: 0,
  },
  noTimesWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& p": {
      color: "#990000",
    },
    marginBottom: 0,
  },
  addMarginTop: {
    marginTop: "1em",
  },
  addMarginBottom: {
    marginBottom: "1em",
  },
  divider: {
    overflowX: "auto",
    overflowY: "hidden",
    float: "left",
    whiteSpace: "nowrap",
    borderBottom: "1px solid #E3E3E3",
  },
  totalPriceHeading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  loaderParent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  transparentBkg: {
    backgroundColor: "transparent",
  },
  dangerText: {
    color: "#990000",
    marginBottom: 0,
  },
  loaderColor: {
    color: "#0000FF",
  },
  marginBottomZero: {
    marginBottom: 0,
  },
  fullWidthBtn: {
    width: "100%",
  },
  addBtnRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "1.3em",
    },
  },
  callBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: "0.3em",
    },
    "& span": {
      marginBottom: 0,
      marginRight: "0.3em",
    },
  },
}));
