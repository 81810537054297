import { useEffect } from "react";
import { hotjar } from "react-hotjar";

function HotJar() {
  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
  }, []);
  return null;
}

export default HotJar;
