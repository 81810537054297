import React, { createContext, useState, useEffect } from "react";
import QueryString from "query-string";
import { useAuth0 } from "@auth0/auth0-react";
import fetchUserDocument from "./ApiCalls/FetchUserDocument";
import fetchMembers from "./ApiCalls/FetchMembers";
import {
  identifyAnalyticsEvent,
  setAnalyticsEvent,
  recordAnalyticsEvent,
} from "src/utils/Analytics";

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user: auth0UserInfo,
    isLoading,
    logout: auth0Logout,
  } = useAuth0();

  const [userInformation, setUserInformation] = useState("");

  const [userTeamMembers, setUserTeamMembers] = useState([]);
  const [membersLoader, setMembersLoader] = useState(false);

  const [toastType, setToastType] = useState({
    show: false,
    type: undefined,
    message: "",
  });

  const [showSignupModal, setShowSignupModal] = useState(false);
  const [creditSystemActive, toggleCreditSystem] = useState(false);

  useEffect(() => {
    checkIfCreditsApplicable();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        loginOps();
      } else {
        logoutOps();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Global Function to trigger Toast Message across components
  const changeToast = (show, type, message) => {
    setToastType({ show, type, message });
  };

  // Checks Query Params for checking if User came to OnDemand App from VO App
  const checkIfCreditsApplicable = () => {
    const parsed = QueryString.parse(window.location.search);
    toggleCreditSystem(parsed.credits_at_checkout ? true : false);
  };

  const loginOps = async () => {
    setMembersLoader(true);
    try {
      const token = await getAccessTokenSilently();
      const bearerToken = `Bearer ${token}`;

      // Fetch User Document and Team Members
      const [userDocumentData, userMembers] = await Promise.all([
        fetchUserDocument(bearerToken),
        fetchMembers(bearerToken),
      ]);

      setUserTeamMembers(userMembers);
      setMembersLoader(false);
      if (!userDocumentData.valid) {
        errorOperations(userDocumentData.errorType);
        return;
      }
      if (userDocumentData.data.isB2BUser) {
        errorOperations("b2b-user");
        return;
      }
      setUserInformation(userDocumentData.data);
      analyticsOperations(userDocumentData.data);
    } catch (error) {
      console.error(error);
    }
    return;
  };

  const logoutOps = () => {
    setUserInformation(null);
    setUserTeamMembers([]);
    setShowSignupModal(false);
    setMembersLoader(false);
    auth0Logout({ returnTo: process.env.REACT_APP_URL });
  };
  // Kissmetrics Events Start
  const analyticsOperations = ({ email, name }) => {
    identifyAnalyticsEvent(email);
    setAnalyticsEvent({ Name: name });
    recordAnalyticsEvent("Added Team Member To Cart", {
      "Team member confirmation": "YES",
      "Team member Name": name,
    });
  };
  // Kissmetrics Events End

  const errorOperations = (errorType) => {
    switch (errorType) {
      case "user-not-found":
        setShowSignupModal(true);
        return;
      case "admin-user":
        changeToast(true, "error", "Admin user found, logging out");
        logoutOps();
        return;
      default:
        logoutOps();
        return;
    }
  };

  return (
    <UserContext.Provider
      value={{
        userInfo: userInformation,
        toastInfo: toastType,
        userTeamMembers: userTeamMembers,
        membersLoader: membersLoader,
        creditSystemActive: creditSystemActive,
        toggleCreditSystem: toggleCreditSystem,
        showToast: changeToast,
        setUserInfo: setUserInformation,
        setUserTeamMembers: setUserTeamMembers,
        getAccessToken: getAccessTokenSilently,
        showSignupModal,
        isAuthenticated,
        auth0UserInfo,
        loginOps,
        setShowSignupModal,
        setMembersLoader,
        analyticsOperations,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;
