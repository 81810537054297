import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import Spinner from "../Spinner";
import RayRadioPillGroup from "../RayRadioPillGroup/RayRadioPillGroup";
import RayPrimaryButton from "../RayPrimaryButton";
import QuantityEditor from "../QuantityEditor";
import styled from "styled-components";
import { stringifyPrice } from "src/utils/Functions";
import ReactHtmlParser from "react-html-parser";
import festive from "../../assets/images/festive.png";
import offc from "../../assets/images/offc.jpg";

const GET_PRODUCT_DETAILS = gql`
  query getProductByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      descriptionHtml
      productType
      variants(first: 100) {
        edges {
          node {
            id
            title
            price
            availableForSale
            image {
              originalSrc
            }
          }
        }
      }
    }
  }
`;

const CREATE_CHECKOUT = gql`
  mutation CreateCheckout($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
        order {
          orderNumber
          statusUrl
        }
      }
    }
  }
`;

const GiftCardDetail = () => {
  const { handle } = useParams();
  const [selectedVariant, setSelectedVariant] = useState();
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { loading, error, data } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { handle },
    onCompleted: () => {
      if (data.productByHandle) {
        setSelectedVariant({
          ...data.productByHandle.variants.edges[0].node,
        });
      }
    },
  });
  const [createCheckout] = useMutation(CREATE_CHECKOUT, {
    onCompleted: (data) => {
      window.open(data.checkoutCreate.checkout.webUrl, "_self");
    },
    onError: (error) => {
      console.error("Something went wrong!", error);
      setCheckoutLoading(false);
    },
  });

  const initiateCheckout = () => {
    setCheckoutLoading(true);
    const checkoutCreateInput = {
      lineItems: [
        {
          variantId: selectedVariant.id,
          quantity: quantity,
        },
      ],
    };
    setCheckoutLoading(true);
    createCheckout({ variables: { input: checkoutCreateInput } });
  };

  if (error) return error;

  if (loading || checkoutLoading) return <Spinner />;

  if (data) {
    const { title, descriptionHtml, variants } = data.productByHandle;
    return (
      <StyledWrapper>
        <div className="ray-page-container background-img-for-desktop">
          <div className="grid-container fixed-grid-container">
            <div className="festive-head-section">
              <img src={festive} alt="" />{" "}
              <div className="title ray-text--h1 festive-heading">{title}</div>
            </div>
            {selectedVariant && (
              <div className="grid-cell__card-image">
                <img
                  className="card-img img-fluid"
                  src={selectedVariant.image.originalSrc}
                  alt="gift card"
                />
                <span className="denomination-label">
                  {stringifyPrice(selectedVariant.price)}
                </span>
              </div>
            )}
            <div class="ray-text--body">{ReactHtmlParser(descriptionHtml)}</div>
            <div class="ray-text--body text-center select">Select</div>
            <RayRadioPillGroup
              className="gift-card-amount-radio"
              options={variants.edges.map((variant) => ({
                ...variant.node,
                label: stringifyPrice(variant.node.price),
              }))}
              onChange={setSelectedVariant}
            />
            <div class="ray-text--body text-center select">Quantity</div>
            <QuantityEditor
              className="quantity-editor mx-auto"
              quantity={quantity}
              onDecrement={() => quantity > 1 && setQuantity(quantity - 1)}
              onIncrement={() => setQuantity(quantity + 1)}
            />

            <div>
              <RayPrimaryButton
                id="checkout"
                label={
                  selectedVariant &&
                  `Checkout ${stringifyPrice(
                    selectedVariant.price * quantity
                  )}`
                }
                onClick={initiateCheckout}
              />
            </div>
          </div>
        </div>
      </StyledWrapper>
    );
  }
};

const StyledWrapper = styled.div`
  .festive-head-section {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    img {
      width: 20px;
      height: 20px;
      position: relative;
      margin: auto;
      @media (min-width: 600px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .festive-heading {
      font-size: 22px;
      text-align: center;
      margin: auto;
      @media (min-width: 600px) {
        margin-left: 10px;
      }
    }
  }

  .select {
    margin: 0;
    position: relative;
    top: 10px;
  }

  .ray-chip-for-festive-page {
    margin: 30px auto 50px auto;
  }

  .festive-buy-btn {
    width: 100%;
  }

  .grid-cell__card-image {
    position: relative;
    max-width: 400px !important;
    margin: auto auto 30px auto;
    img {
      max-width: 100%;
      height: auto;
    }
    span {
      color: white;
      font-weight: bold;
      position: absolute;
      top: 2px;
      right: 10px;
    }
  }

  .fixed-grid-container {
    background-color: white;
    padding: 10px;
    @media (min-width: 600px) {
      max-width: 800px;
      margin: auto;
      padding: 50px;
    }
  }

  .background-img-for-desktop {
    background-image: unset;
    max-width: unset;
    @media (min-width: 600px) {
      padding: 50px;
      background-image: url(${offc});
      background-size: cover;
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }

  .primary-giftcard-btn {
    max-width: 400px;
    width: 100%;
    display: block;
    margin: auto;
  }
`;

export default GiftCardDetail;
