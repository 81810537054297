import React from 'react';

const RayPrimaryButton = ({ label, onClick, ...rest }) => {
    return (
        <button class="ray-button ray-button--primary primary-giftcard-btn" onClick={() => onClick()} {...rest}>
            {label}
        </button>
    );
}

export default RayPrimaryButton;