import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import styled from "styled-components";
import axios from "src/axios";
import HomeIntro from "./HomeIntro.jsx";
import Safety from "../Snippets/Safety";
import CityCards from "../ProductsByCollection/CityCards.jsx";
import WhyWeWork from "../Snippets/WhyWework.jsx";
import EasySteps from "../Snippets/EasySteps.jsx";
import Testimonials from "../Snippets/Testimonials.jsx";
import Spinner from "../Spinner";
import "./Location.scss";

const StyledWrapper = styled.div`
  .d-none-mobile {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .d-none-desktop {
    @media (min-width: 600px) {
      display: none;
    }
  }
`;

// const WarningText = styled.div`
//   background-color: #f3f6f9;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 2em 1em;
//   border-radius: 5px;
//   span {
//     margin-bottom: 0;
//   }
//   svg {
//     font-size: 1.5em;
//     margin-right: 0.5em;
//   }
//   @media (min-width: 320px) and (max-width: 480px) {
//     padding: 1em 0.5em;
//     svg {
//       font-size: 4em;
//       margin-right: 0.2em;
//     }
//   }
// `;

const Home = () => {
  const [cities, setCities] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    ReactPixel.pageView();
    fetchCitiesData();
  }, []);

  const fetchCitiesData = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get("/buildings/get-all-cities");
      setCities(data.citiesData);
      setLoader(false);
    } catch (error) {
      console.log(error);
      const tempError =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong";
      setError(tempError);
      setLoader(false);
    }
  };
  if (loader) return <Spinner />;
  return (
    <StyledWrapper>
      <HomeIntro />
      <div className="ray-page-container card-container">
        {/* <div className="ray-grid">
          <div className="ray-grid__cell--span-full">
            <WarningText>
              <AiFillInfoCircle />
              <span className="ray-text--body">
                Bengaluru, Gurugram, Mumbai, Noida & Pune Buildings are
                currently closed for OnDemand bookings due to state-wide
                restrictions.
              </span>
            </WarningText>
          </div>
        </div> */}
        <div className="carousel-building-heading">
          <span className="ray-text--h2 city-title" id="cities">
            Locations
          </span>
        </div>
        {error && <div className="ray-grid">{error}</div>}
        <div className="ray-grid">
          {React.Children.toArray(
            cities.map((city) => <CityCards city={city} />)
          )}
        </div>
        <EasySteps />
      </div>
      <WhyWeWork />
      <Testimonials />
      <Safety />
    </StyledWrapper>
  );
};

export default Home;
