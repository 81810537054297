import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { RiTeamLine, RiUserLine } from "react-icons/ri";
import ProfileSection from "./ProfileSection";
import BookingsSection from "./BookingsSection";
import MemberSection from "./MembersSection";
import BookingDetailSection from "./BookDetailSection";

function UserAccountPage() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("PROFILE");
  const [selectedBooking, setSelectedBooking] = useState(null);
  return (
    <main className="ray-page-container">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-3-desktop ray-grid__cell--span-12-tablet ray-grid__cell--span-12-phone">
          <div className={classes.tabRow}>
            <div
              onClick={() => setSelectedTab("PROFILE")}
              className={`${classes.tabChild} ${
                selectedTab === "PROFILE" ? classes.tabSelected : ""
              }`}
            >
              <RiUserLine />
              <span>Profile</span>
            </div>
            <div
              onClick={() => setSelectedTab("TEAM_MEMBERS")}
              className={`${classes.tabChild} ${
                selectedTab === "TEAM_MEMBERS" ? classes.tabSelected : ""
              }`}
            >
              <RiTeamLine />
              <span>Team</span>
            </div>
            {/* <div
              onClick={() => setSelectedTab("BOOKINGS")}
              className={`${classes.tabChild} ${
                selectedTab === "BOOKINGS" ? classes.tabSelected : ""
              }`}
            >
              <RiCalendar2Line />
              <span>Bookings</span>
            </div> */}
          </div>
        </div>
        <div
          className={`ray-grid__cell--span-9-desktop ray-grid__cell--span-12-tablet ray-grid__cell--span-12-phone ${
            classes.mainDisplay
          }`}
        >
          {selectedTab === "PROFILE" && <ProfileSection />}
          {selectedTab === "TEAM_MEMBERS" && <MemberSection />}
          {selectedTab === "BOOKINGS" && (
            <BookingsSection
              setBooking={setSelectedBooking}
              changeSection={setSelectedTab}
            />
          )}
          {selectedTab === "BOOKING_DETAIL" && (
            <BookingDetailSection
              bookingDetail={selectedBooking}
              changeSection={setSelectedTab}
            />
          )}
        </div>
      </div>
    </main>
  );
}

export default UserAccountPage;

const useStyles = makeStyles((theme) => ({
  tabRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2em",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      padding: "1em",
    },
  },
  tabChild: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "80%",
    fontSize: "1.2em",
    marginBottom: "0.5em",
    "& svg": {
      fontSize: "1.2em",
    },
    "& span": {
      marginLeft: "0.5em",
    },
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
      justifyContent: "center",
    },
  },
  tabSelected: {
    fontWeight: "bolder",
    "& svg": {
      fontWeight: "bolder",
    },
  },
  mainDisplay: {
    minHeight: "100vh",
  },
}));
