import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { CgClose } from "react-icons/cg";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import axios from "src/axios";

function DefaultSection({ memberInfo, deleteMember }) {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const DeleteTeamMember = async () => {
    const body = {
      memberEmail: memberInfo.email,
    };
    const token = await getAccessTokenSilently();
    try {
      await axios.patch(`/customers/delete-member`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      deleteMember(memberInfo);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.defaultContainer}
    >
      <Grid item xs={10} sm={5}>
        <p className={`ray-text--body ${classes.accountPageName}`}>
          {memberInfo.name}
        </p>
        <Hidden smUp>
          <p className={`ray-text--body-small ${classes.mobileEmail}`}>
            {memberInfo.email}
          </p>
        </Hidden>
      </Grid>
      <Hidden xsDown>
        <Grid item sm={5} md={5}>
          <p className={`ray-text--body ${classes.accountPageEmail}`}>
            {memberInfo.email}
          </p>
        </Grid>
      </Hidden>
      <Grid item xs={2}>
        <button
          onClick={DeleteTeamMember}
          className={`ray-button ray-button--tertiary ray-button--compact ${
            classes.deleteBtn
          }`}
        >
          <CgClose />
        </button>
      </Grid>
    </Grid>
  );
}

export default DefaultSection;

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    float: "right",
  },
  accountPageEmail: {
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    marginBottom: 0,
  },
  accountPageName: {
    display: "-webkit-box",
    maxWidth: "100%",
    height: "auto",
    WebkitLineClamp: 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 0,
  },
  mobileEmail: {
    color: "#737373",
    marginBottom: 0,
  },
  defaultContainer: {
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid #E3E3E3",
    },
  },
}));
