import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

function RoomCapacity({ capacity, memberCount, setRoomCapacity }) {
  const classes = useStyles();
  const handleChange = () => {
    setRoomCapacity(capacity);
  };
  return (
    <span
      className={`${
        capacity === memberCount
          ? classes.selectedRoomCapacity
          : classes.roomCapacity
      }`}
      onClick={handleChange}
  >
    {capacity} Seater
  </span>
  )
};
export default RoomCapacity;

const useStyles = makeStyles((theme) =>
  createStyles({
    roomCapacity: {
      marginRight: "3em",
      "&:hover": {
        cursor: "pointer",
      },
      paddingBottom: "3em",
    },
    selectedRoomCapacity: {
      cursor: "pointer",
      borderBottom: "4px solid #0000FF",
      marginRight: "3em",
      paddingBottom: "0.53em",
    },
  })
);
