import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    left: 0,
    top: 0,
    position: "fixed",
    width: "100%",
    zIndex: "9999",
  },
  backdrop: {
    zIndex: "-1",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: "100%",
    left: 0,
    top: 0,
    position: "fixed",
    width: "100%",
  },
  sidebar: {
    backgroundColor: "#fff",
    width: "20em",
    height: "100%",
    right: 0,
    top: 0,
    position: "fixed",
    padding: "1em",
  },
}));

function Sidebar({ isOpen = false, onClose, children }) {
  const classes = useStyles();
  if (!isOpen) return <></>;
  return (
    <div className={classes.container}>
      <div onClick={() => onClose(false)} className={classes.backdrop} />
      <div className={classes.sidebar}>{children}</div>
    </div>
  );
}

export default Sidebar;
