import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RiMotorbikeLine, RiCarLine } from "react-icons/ri";

function ParkingSwitch({
  selectedParkingType,
  disabled,
  setParkingType,
  handleParking,
}) {
  const classes = useStyles();

  const handleChange = (parkingOption) => {
    if (selectedParkingType === parkingOption) {
      setParkingType("NO_PARKING");
      handleParking("NO_PARKING");
    } else {
      setParkingType(parkingOption);
      handleParking(parkingOption);
    }
  };
  return (
    <div
      className={disabled ? classes.disabledContainer : classes.switchContainer}
    >
      <div
        className={`${
          selectedParkingType === "Bike" ? classes.checkedWrapper : ""
        } ${classes.iconWrapper} ${classes.leftOption} ${classes.firstOption}`}
        onClick={() => handleChange("Bike")}
      >
        <RiMotorbikeLine />
      </div>
      <div
        onClick={() => handleChange("Car")}
        className={`${
          selectedParkingType === "Car" ? classes.checkedWrapper : ""
        } ${classes.iconWrapper} ${classes.rightOption}`}
      >
        <RiCarLine />
      </div>
    </div>
  );
}

export default ParkingSwitch;

const useStyles = makeStyles((theme) => ({
  disabledContainer: {
    pointerEvents: "none",
    opacity: "0.5",
    background: "#CCC",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    border: "0.5px solid #e3e3e3",
    borderRadius: "5px",
  },
  switchContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    border: "0.5px solid #e3e3e3",
    borderRadius: "5px",
  },
  iconWrapper: {
    minWidth: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.5em",
    justifyContent: "center",
    "& svg": {
      color: "#5D6D7E",
      fontWeight: "bolder",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("sm")]: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0000FF",
        borderRadius: "5px",
        "& svg": {
          color: "#fff",
        },
      },
    },
  },
  checkedWrapper: {
    backgroundColor: "#0000FF",
    borderRadius: "5px",
    "& svg": {
      color: "#fff",
    },
  },
  leftOption: {
    borderRadius: "5px 0 0 5px",
    "&:hover": {
      borderRadius: "5px 0 0 5px",
    }
  },
  rightOption: {
    borderRadius: "0 5px 5px 0",
    "&:hover": {
      borderRadius: "0 5px 5px 0",
    }
  },
  firstOption: {
    borderRight: "0.5px solid #e3e3e3",
  },
  defaultContainer: {
    [theme.breakpoints.down("xs")]: {
      borderBottom: "1px solid #E3E3E3",
    },
  },
}));
