import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { CgClose } from "react-icons/cg";
import axios from "src/axios";

function FormSection({
  toggleEditState,
  memberInfo,
  index,
  updateExistingMember,
  deleteMember,
}) {
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: memberInfo,
  });
  const onSubmit = (data) => {
    memberInfo.id ? updateMember(data) : addNewMember(data);
  };
  const addNewMember = async (data) => {
    const body = { ...data };
    console.log(body);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(`/customers/create-member`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      updateExistingMember(
        { name: data.name, email: data.email, id: response.data.responseId },
        index
      );
      toggleEditState(false);
      // reloadMembers();
    } catch (error) {
      console.error(error);
    }
  };
  const updateMember = async (data) => {
    const body = {
      ...data,
      id: memberInfo.id,
    };
    const token = await getAccessTokenSilently();
    try {
      await axios.post(`/customers/update-member`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toggleEditState(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancel = () => {
    toggleEditState(false);
    if (memberInfo && !memberInfo.id) {
      deleteMember(memberInfo);
    }
  };
  return (
    <Grid item xs={12}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.memberFormContainer}
      >
        <div className={`ray-text-field ${classes.inputFieldName}`}>
          <input
            className="ray-text-field__input"
            id={`member-name-${index}`}
            defaultValue={memberInfo && memberInfo.name ? memberInfo.name : ""}
            type="text"
            name="name"
            ref={register({ required: "Name Required" })}
            placeholder="Enter Name"
          />
          <label
            className="ray-text-field__label"
            htmlFor={`member-name-${index}`}
          >
            Member Name
          </label>
          {errors.name && (
            <small className={`ray-text--body-x-small ${classes.fieldError}`}>
              {errors.name.message}
            </small>
          )}
        </div>
        <div className={`ray-text-field ${classes.inputFieldEmail}`}>
          <input
            className="ray-text-field__input"
            id={`member-email-${index}`}
            type="text"
            name="email"
            ref={register({
              required: "Email required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Enter a valid email address",
              },
            })}
            placeholder="Enter Email"
          />
          <label
            className="ray-text-field__label"
            htmlFor={`member-email-${index}`}
          >
            Member Email
          </label>
          {errors.email && (
            <small className={`ray-text--body-x-small ${classes.fieldError}`}>
              {errors.email.message}
            </small>
          )}
        </div>
        <div className={classes.formBtnRow}>
          <button
            style={{ marginRight: 0 }}
            type="submit"
            className="ray-button ray-button--tertiary ray-button--compact"
          >
            {memberInfo && memberInfo.id ? "Update" : "Save"}
          </button>
          <button
            onClick={() => handleCancel()}
            className="ray-button ray-button--tertiary ray-button--compact"
          >
            <CgClose />
          </button>
        </div>
      </form>
    </Grid>
  );
}

export default FormSection;

const useStyles = makeStyles((theme) => ({
  memberFormContainer: {
    width: "100%",
    padding: "5px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1em",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "inherit",
      justifyContent: "flex-start",
      border: "1px solid #c7c7c7",
      marginBottom: "1em",
      padding: "1em",
      borderRadius: "5px",
    },
  },
  formBtnRow: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row-reverse",
    },
  },
  inputFieldName: {
    minWidth: "35%",
    marginRight: "2.5%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      marginRight: 0,
      marginBottom: "3%",
    },
  },
  inputFieldEmail: {
    minWidth: "40%",
    marginRight: "2.5%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      marginRight: 0,
      marginBottom: "2%",
    },
  },
  fieldError: {
    color: "#CC0000",
    marginTop: "0.5em",
  },
}));
