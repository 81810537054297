import React from "react";
import styled from "styled-components/macro";

const Input = ({
  id,
  name,
  type = "number",
  placeholder,
  onChange,
  onClick,
  label,
  style,
  ref,
  className,
  value,
  fullWidth = false,
  autoMargin = true,
  disabled = false,
  increment,
  decrement,
}) => {
  return (
    <div className={`ray-text-field ${className}`}>
      <input
        id={id}
        onChange={onChange}
        name={name}
        disabled={disabled}
        onClick={onClick}
        className="ray-text-field__input"
        ref={ref}
        type={type}
        placeholder={placeholder}
        style={style}
        value={value}
      />
      <label className="ray-text-field__label" htmlFor={id}>
        {label}
      </label>
      <button type="button" onClick={decrement} className="ray-button">
        -
      </button>
      <button
        type="button"
        onClick={increment}
        className="ray-button margin-right"
      >
        +
      </button>
    </div>
  );
};

const InputWrapper = styled(Input)`
  margin: ${(props) => (props.autoMargin ? "0.5em" : "0")};
  width: ${(props) => (props.fullWidth ? "100%" : "20%")};
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .ray-text-field {
    background-color: transparent !important;
  }
  button {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e3e3e3 !important;
    color: #000 !important;
    padding: 0.5em !important;
    height: 70% !important;
    border: none !important;
    align-self: center !important;
  }
  button:hover {
    background-color: #b3b3b3 !important;
  }
  .margin-right {
    margin-right: 0.5em;
  }
`;

export default InputWrapper;
