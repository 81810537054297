import React from "react";
import { Link } from 'react-router-dom';


const NoRouteMatch = () => {
  return (
    <div className="not-found">
    <div className="ray-page-container">
        <h2 className="ray-text--h2">Sorry, we couldn't find what you were looking for.</h2>
          <Link to="/">
            <button className="ray-button ray-button--primary go-back-btn">
              BACK TO HOME
            </button>
          </Link>
          </div>
          </div>
  );
};

export default NoRouteMatch;
