import React, {useEffect} from "react";
import "./About.scss";
import ReactPixel from "react-facebook-pixel";


const About = () => {

  useEffect( () => {
    ReactPixel.pageView();
  })

  return (
    <div className="about">
      <div className="ray-page-container">
        <h2 className="ray-text--h2 about-headings">Our Story</h2>
        <br />
        <p className="ray-text--body-large">
          When we started WeWork in 2010, we wanted to build more than
          beautiful, shared office spaces. We wanted to build a community. A
          place you join as an individual, 'me', but where you become part of a
          greater 'we'. A place where we’re redefining success measured by
          personal fulfillment, not just the bottom line. Community is our
          catalyst.
        </p>
        <h2 className="ray-text--h2 about-headings">Our Values</h2>
        <ul>
          <li className="ray-text--h4">Do The Right Thing</li>
          <p className="ray-text--body">
            We know the “right thing” is a deliberate action we must always
            take, and that it is based on integrity and builds trust with those
            who we care about, including our people, members, and our community.
          </p>
        </ul>
        <ul>
          <li className="ray-text--h4">Strive To Be Better, Together</li>
          <p className="ray-text--body">
            We’ve always believed that we are better together. We must operate
            with a shared purpose to constantly improve and grow and to become
            better as individuals, as teams, and as a company.
          </p>
        </ul>
        <ul>
          <li className="ray-text--h4">Be Entrepreneurial</li>
          <p className="ray-text--body">
            To reshape the way the world works, we must be bold, act with
            courage, and demonstrate the resiliency to push ahead no matter the
            odds or the circumstance.
          </p>
        </ul>
        <ul>
          <li className="ray-text--h4">Give Gratitude</li>
          <p className="ray-text--body">
            We will not take anyone or anything for granted. We are grateful for
            our people, members, and our greater community as well as for the
            opportunities we have in front of us.
          </p>
        </ul>
        <ul>
          <li className="ray-text--h4">Be Human, Be Kind</li>
          <p className="ray-text--body">
            Collaboration, kindness, and authenticity are essential to our
            humanity. We must cherish each other and build a community that
            celebrates each person’s unique talents, passions, and backgrounds.
          </p>
        </ul>
        <h2 className="ray-text--h2 about-headings">Our Team</h2>
        <p className="ray-text--body-large">
          Our mission doesn’t end with the small businesses and entrepreneurs
          that call us home. We believe in empowering our team to create their
          own life's work. We move fast and we challenge each other, but we look
          after each other and care about our culture, which makes working here
          extremely rewarding. There's a lot of work left for us to do, and we
          couldn't do it without a single member of our team.
        </p>
      </div>
    </div>
  );
};

export default About;
