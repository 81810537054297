import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RiBuildingFill } from "react-icons/ri";

function MicroMarketChip({ info, removeItem, addItem, length, selectedArea }) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const handleClickChange = () => {
    if (!checked === false) removeItem(info);
    else addItem(info);
    setChecked(!checked);
  };
  useEffect(()=>{
    if(selectedArea && selectedArea.length > 0 && selectedArea[0].location === 'My Location')
    setChecked(false);
  },[selectedArea])
  return (
    <div
      onClick={handleClickChange}
      className={`${classes.gridJustify} ${
        checked ? classes.checkedClass : ""
      }`}
    >
      <span>{info}</span>{" "}
      <div className={classes.buildingCount}>
        <RiBuildingFill /> <span>{length}</span>
      </div>
    </div>
  );
}

export default MicroMarketChip;

const useStyles = makeStyles((theme) => ({
  gridJustify: {
    padding: "0.5em 1em",
    margin: "0.5em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #E3E3E3",
    borderRadius: '4px',
    minWidth: "10%",
    "&:hover": {
      cursor: "pointer",
      border: "1px solid #0000FF",
      "& div": {
        backgroundColor: "#0000FF",
        color: "#fff",
      },
    },
  },
  buildingCount: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.3em",
    marginLeft: "0.5em",
    backgroundColor: "#f1f1f1",
    borderRadius: "0.5em",
  },
  logoClass: {
    width: "0.6em",
  },
  checkedClass: {
    border: "1px solid #0000FF",
    "& div": {
      backgroundColor: "#0000FF",
      color: "#fff",
    },
  },
}));
