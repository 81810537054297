import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { recordAnalyticsEvent, clearIdentity } from "src/utils/Analytics";
import { CITIES_LIST } from "src/constants";
import GiftCardSnippetSidebar from "src/reuseComponents/GiftCards/GiftCardSnippetMobile";
import logo from "src/assets/images/logo.png";
import { storeRedirectUrl } from 'src/utils/temp-storage/redirectUrl';
import "./Sidebar.scss";

const StyledWrapper = styled.div`
  .sidebar-giftcard {
    width: 100%;
  }

  .city-list-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 0.5em 1em;
  }
  .city-list-item {
    display: flex;
    width: 50%;
    padding: 5px 10px;
    color: #000;
  }

  .city-list-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 0.5em 1em;
  }
  .city-list-item {
    display: flex;
    width: 50%;
    padding: 5px 10px;
    color: #000;
  }
`;

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const {
    isAuthenticated,
    logout: authZeroLogout,
    loginWithRedirect,
  } = useAuth0();

  const eventRecorder = (properties) => {
    recordAnalyticsEvent("Clicked Menu", properties);
  };

  const loginUser = () => {
    recordAnalyticsEvent("Login Clicked");
    storeRedirectUrl(window.location.pathname);
    loginWithRedirect();
    closeSidebar();
  };

  const logOutUser = () => {
    clearIdentity();
    closeSidebar();
    authZeroLogout({ returnTo: process.env.REACT_APP_URL });
  };

  return (
    <>
      <StyledWrapper>
        <div className={sidebarOpen ? "active" : ""} id="sidebar">
          <div className="ray-grid">
            <div className="ray-grid__cell--span-8-desktop ray-grid__cell--span-6-tablet ray-grid__cell--span-3-phone">
              <Link to="/" onClick={() => closeSidebar()}>
                <img
                  className="wework-logo-sidenav"
                  src={logo}
                  alt="wework logo"
                />
              </Link>
            </div>
            <div className="ray-grid__cell--span-2-desktop ray-grid__cell--span-2-tablet ray-grid__cell--span-1-phone">
              <div
                onClick={() => closeSidebar()}
                className={sidebarOpen ? "open" : ""}
                id="menu-toggle"
              >
                <button
                  className={
                    sidebarOpen
                      ? "hamburger hamburger--squeeze is-active sidebar-cross"
                      : "hamburger hamburger--squeeze sidebar-cross"
                  }
                  id="hamburgur"
                  type="button"
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {isAuthenticated ? (
            <nav className="account__functions">
              <ul className="ray-page-container">
                <li>
                  <Link
                    style={{ marginBottom: 0 }}
                    to="/account"
                    onClick={() => {
                      closeSidebar();
                      eventRecorder({
                        "My Account Link": `${window.location.href}/account`,
                      });
                    }}
                  >
                    <span>My Account</span>
                  </Link>
                </li>
                <li>
                  <span
                    onClick={() => {
                      logOutUser();
                      recordAnalyticsEvent("Logout Clicked");
                    }}
                    className="sidebar--logout__btn"
                  >
                    Logout
                  </span>
                </li>
              </ul>
            </nav>
          ) : (
            <nav className="account__functions">
              <ul className="ray-page-container">
                <li>
                  <span onClick={loginUser} className="sidebar--logout__btn">
                    Login
                  </span>
                </li>
              </ul>
            </nav>
          )}
          <nav>
            <ul className="ray-page-container menu-items-desktop">
              <p>Get a Day Pass at:</p>
              {React.Children.toArray(
                CITIES_LIST.map((city, index) => (
                  <Link
                    onClick={() => {
                      closeSidebar();
                      eventRecorder({ "City Name": city.name });
                    }}
                    to={`/${city.handle}`}
                    index={index}
                    className="building-link"
                  >
                    <li>{city.name}</li>
                  </Link>
                ))
              )}
            </ul>
          </nav>
          <div className="secondary-container">
            <div className="sidebar-giftcard">
              <GiftCardSnippetSidebar />
            </div>
            <div className="sidebar-footer">
              <a
                href="https://wework.co.in/india/legal/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => eventRecorder({ "Footer Link": "Terms" })}
              >
                <span>Terms</span>
              </a>
              <span className="bullets">&#8226;</span>
              <a
                href="https://wework.co.in/india/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => eventRecorder({ "Footer Link": "Privacy" })}
              >
                <span>Privacy</span>
              </a>
              <span className="bullets">&#8226;</span>
              <Link
                to="/faq"
                onClick={() => {
                  closeSidebar();
                  eventRecorder({ "Footer Link": "FAQ" });
                }}
              >
                <span>FAQ</span>
              </Link>
              <span className="bullets">&#8226;</span>
              <Link
                to="/about"
                onClick={() => {
                  closeSidebar();
                  eventRecorder({ "Footer Link": "About Us" });
                }}
              >
                <span>About us</span>
              </Link>
            </div>
          </div>
        </div>
      </StyledWrapper>
    </>
  );
};

export default Sidebar;
